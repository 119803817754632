import React, { memo } from 'react';
import { Router, Redirect } from '@reach/router';

function PageContent({ routes }) {
  return (
    <Router>
      {routes.map(({ path, Component }) => (
        <Component.type {...Component.props} path={path} key={path} />
      ))}
      <DefaultRoute default />
    </Router>
  );
}

function DefaultRoute() {
  return <Redirect to="pending" noThrow />;
}

export default memo(PageContent);
