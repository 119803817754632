import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { gLogin } from 'services/userService';
import { useAPI } from 'utils/customHooks';
import LinearProgress from '@material-ui/core/LinearProgress';
import GoogleLogin from 'react-google-login';
import { useGDispatch, ga } from 'state/store';

const useStyles = makeStyles(theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(8),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  paper: {
    marginTop: theme.spacing(8),
    padding: 0,
  },
  avatar: {
    margin: theme.spacing(),
    backgroundColor: theme.palette.secondary.main,
  },
  loader: {
    width: '100%',
  },
  gAuthButton: {
    textAlign: 'center',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [fetch, , gAuthLoading] = useAPI();
  const gDispach = useGDispatch();

  const onGLoginSuccess = gToken => {
    fetch(gLogin(gToken.tokenId), serverResp => {
      // gDispach([ga.USER, gToken.profileObj]);
      gDispach([ga.AUTH_TOKEN, serverResp.token]);
      gDispach([ga.USER, { ...serverResp.user, ...serverResp.payload }]);
    });
  };

  return (
    <main className={classes.main}>
      <Paper className={classes.paper}>
        {gAuthLoading ? (
          <LinearProgress className={classes.loader} />
        ) : (
          <div style={{ height: '4px' }} />
        )}
        <div className={classes.paperContainer}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <div className={classes.gAuthButton}>
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
              buttonText="SIGN IN WITH GOOGLE"
              theme="dark"
              onSuccess={onGLoginSuccess}
              onFailure={failure => console.log({ failure })}
              cookiePolicy={'single_host_origin'}
            />
          </div>
        </div>
      </Paper>
    </main>
  );
}
