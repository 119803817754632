export const planStates = {
  CREATED: 'created',
  QUEUED: 'queued',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
  FROZEN: 'frozen',
};

export const planEdits = {
  ADD_PICKLIST: 'ADD_PICKLIST',
  REMOVE_PICKLISTS: 'REMOVE_PICKLISTS',
  CHANGE_PICKLIST: 'CHANGE_PICKLIST',
  REARRANGE_ROUTE: 'REARRANGE_ROUTE',
  ASSIGN_RETAILER: 'ASSIGN_RETAILER',
  UNASSIGN_RETAILER: 'UNASSIGN_RETAILER',
  REARRANGE_ROUTE_FULL: 'REARRANGE_ROUTE_FULL',
};

export const vehicleParamsLabel = {
  avgSpeed: 'Avg Speed(km/hr)',
  fixedCost: 'Fixed Cost',
  id: 'Id',
  maxDropPoints: 'Max Drop Points',
  maxTripTime: 'Max Trip Time(hrs)',
  maxVolume: 'Max Volume(cc)',
  maxWeight: 'Max Weight(kg)',
  name: 'Name',
  vehicleCount: 'Count',
  volumeLimitRatio: 'Vehicle Limit Ratio(%)',
  maxDistance: 'Max Distance(KM)',
  loadingTime: 'Loading+Unloading Time(mins)',
  serviceTime: 'Service Time per customer(mins)',
  isSplitCompatible :'Split Compatible',
};
