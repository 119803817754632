import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import CancelIcon from '@material-ui/icons/CloseRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayArrowIcon from '@material-ui/icons/PlayArrowRounded';
import RefreshIcon from '@material-ui/icons/RefreshRounded';
import { makeStyles } from '@material-ui/styles';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import Link from 'components/Link';
import { addMinutes, differenceInSeconds } from 'date-fns';
import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { downloadOrderUpload } from 'services/planService';
import { planStates as ps } from 'utils/constants';
import { useAPI, useInterval, usePrevious, useSnackbar } from 'utils/customHooks';
import { format1, formatCurrencyNum, formatTimeMinSec } from 'utils/helperFunctions';
import ConfirmationDialog from './ConfirmationDialog';
import Uploader, { DefaultUploaderRender } from './Uploader';

const useStyles = makeStyles(theme => ({
  card: {},
  planPageCard: {
    border: `1px solid ${grey[300]}`,
  },
  content: {
    padding: `${theme.spacing(1)}px !important`,
    paddingLeft: `${theme.spacing(2)}px !important`,
    paddingRight: `${theme.spacing(2)}px !important`,
  },
  flex: {
    display: 'flex',
  },
  startButton: {
    color: green[500],
    padding: 2,
  },
  cancelButton: {
    color: red[500],
    padding: 2,
  },
  reRunButton: {
    color: orange[500],
    padding: 2,
  },
  branchName: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.palette.text.secondary,
  },
  statsValue: {
    fontSize: theme.typography.pxToRem(18),
    // color: theme.palette.text.secondary,
  },
  progressBar: {
    flexShrink: 1,
    display: 'flex',
  },
  stats: {
    flexShrink: 1,
    display: 'flex',
  },
  statusContainer: {
    flex: '0 0 100px',
  },
  timerContainer: {},
  actionButtonContainer: {
    textAlign: 'center',
  },
  status: {
    ...theme.typography.button,
    // color: theme.palette.text.secondary,
  },
  actionIcon: {
    height: 38,
    width: 38,
  },
  menu: {
    flex: '0 0 48px',
  },
}));

export default function Plan({
  plan,
  refetch,
  holdStatusCall,
  planPage = false,
  stats,
  fetchDetails,
  showId,
}) {
  const classes = useStyles();
  const dialog = useRef();
  const cancelDialog = useRef();
  const [notif] = useSnackbar();

  // const setLoading = useGlobalSetState('loading');
  // const [takeAction, actionResp, actionLoading] = useFetchOnAction(
  // postPlanActionv1(plan.id),
  //   [],
  //   false,
  //   true
  // );

  const [fetch, , actionLoading] = useAPI();

  const takeAction = useCallback(
    action => {
      if (holdStatusCall) holdStatusCall(true);
      if (['start', 'cancel'].includes(action)) {
        fetch(
          {
            method: 'post',
            url: `/admin/delivery/plan/${plan.id}/${action}?reference${plan.updatedAt}`,
          },
          () => {
            if (holdStatusCall) holdStatusCall(false);
            refetch();
          },
          () => {
            if (holdStatusCall) holdStatusCall(false);
          }
        );
      }
      if (['reRun'].includes(action)) {
        fetch(
          { method: 'post', url: `/admin/delivery/plan/${plan.id}` },
          resp => {
            refetch(resp);
            if (holdStatusCall) holdStatusCall(false);
          },
          () => {
            if (holdStatusCall) holdStatusCall(false);
          }
        );
      }
    },
    [fetch, plan.id, refetch, plan.updatedAt, holdStatusCall]
  );

  const prevStatus = usePrevious(plan.status);
  const renderActionButton = useCallback(
    status => {
      const onAction = action => () => {
        if (action === 'reRun') {
          dialog.current.openDialog();
        } else if (action === 'cancel') {
          cancelDialog.current.openDialog();
        } else takeAction(action);
      };

      let action = 'start';
      let cls = classes.startButton;
      let icon = <PlayArrowIcon fontSize="large" />;

      if ([ps.QUEUED, ps.PROCESSING].includes(status)) {
        action = 'cancel';
        cls = classes.cancelButton;
        icon = <CancelIcon />;
      } else if ([ps.CANCELLED, ps.FROZEN, ps.FAILED, ps.COMPLETED].includes(status)) {
        action = 'reRun';
        cls = classes.reRunButton;
        icon = <RefreshIcon fontSize="large" />;
      }

      return (
        <IconButton className={cls} disabled={actionLoading} onClick={onAction(action)}>
          {icon}
        </IconButton>
      );
    },
    [classes, takeAction, actionLoading]
  );

  const { id, status, branchName, clientName, progress, createdAt } = plan;
  const isCancelled = status === ps.CANCELLED;

  useEffect(() => {
    if (prevStatus === ps.PROCESSING && status === ps.COMPLETED && fetchDetails) fetchDetails();
  }, [status, fetchDetails, prevStatus]);

  if (!plan) return null;

  const name = plan.profileName || 'Default';

  // const { name: branchName, clientName } = getBranchDetails(branchId);

  const renderStat = (label, value, isCurrency) => (
    <Grid item>
      <Grid container direction="column" justify="space-evenly" alignItems="center">
        {isCurrency ? (
          <Grid container justify="center" alignItems="baseline">
            <Typography color="textSecondary">₹</Typography>
            <Typography className={classes.statsValue}>{value}</Typography>
          </Grid>
        ) : (
          <Typography className={classes.statsValue}>{value}</Typography>
        )}
        <Typography variant="caption" color="textSecondary">
          {label}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <ConfirmationDialog
        title="Replan?"
        desc={plan.status === ps.FROZEN && 'You will loose this frozen plan'}
        ref={dialog}
        onYes={() => takeAction('reRun')}
      />
      <ConfirmationDialog
        title="Cancel the plan?"
        desc={'You will loose all the progress'}
        ref={cancelDialog}
        onYes={() => takeAction('cancel')}
      />
      <Card
        elevation={planPage ? 0 : 2}
        className={classNames({ [classes.planPageCard]: planPage })}
      >
        <CardContent className={classes.content}>
          <Grid container justify="flex-start" alignItems="center">
            {showId === 'true' && (
              <Grid item xs={2} md={2}>
                <Link to={`/plan/${id}`} given={!planPage}>
                  <Typography noWrap variant="body1" color="primary" align="center">
                    {id}
                  </Typography>
                </Link>
              </Grid>
            )}
            <Grid item xs={3} md={2}>
              <Link to={`/plan/${id}`} given={!planPage}>
                <Typography
                  noWrap
                  className={classes.branchName}
                  color="textSecondary"
                  align="center"
                >
                  {branchName}
                </Typography>
              </Link>
            </Grid>
            {showId === 'true' ? (
              <>
                <Grid item xs={2}>
                  <Link to={`/plan/${id}`} given={!planPage}>
                    <Typography noWrap variant="body1" color="primary" align="center">
                      {name}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  <Link to={`/plan/${id}`} given={!planPage}>
                    <Typography noWrap variant="body1" align="center" color="textPrimary">
                      {clientName}
                    </Typography>
                  </Link>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={2}>
                  <Link to={`/plan/${id}`} given={!planPage}>
                    <Typography noWrap variant="body1" color="primary" align="center">
                      {name}
                    </Typography>
                    <Typography noWrap variant="body1" align="center" color="textPrimary">
                      {clientName}
                    </Typography>
                  </Link>
                </Grid>
              </>
            )}
            <Grid item className={classes.statusContainer}>
              <Typography noWrap className={classes.status} align="center">
                {status}
              </Typography>
            </Grid>
            {[ps.PROCESSING, ps.COMPLETED, ps.CREATED, ps.QUEUED, ps.FAILED].includes(status) && (
              <Grid item className={classes.timerContainer}>
                <CountDownTimer finalTime={addMinutes(new Date(createdAt), 20)} />
              </Grid>
            )}
            <Grid item style={{ flex: '0 0 120px' }} className={classes.actionButtonContainer}>
              <Grid container direction="row" justify="space-around" alignItems="center">
                {!isCancelled && renderActionButton(status)}
                {planPage && (
                  <Uploader
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    url={`/admin/delivery/plan/${id}/static`}
                    onSuccess={plan => navigate(`/plan/${plan.id}`, { replace: true })}
                    onError={() => notif('Oh Snap! Upload Failed', { variant: 'error' })}
                    disabled={isCancelled}
                    render={(progress, apiInProgress) => (
                      <DefaultUploaderRender
                        tooltip="Upload Static Plan"
                        progress={progress}
                        apiInProgress={apiInProgress}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>
            {stats && ![ps.PROCESSING, ps.CREATED, ps.QUEUED].includes(status) && (
              <Grid item xs className={classes.stats}>
                <Grid container direction="row" justify="space-around" alignItems="center">
                  {renderStat('vehicles', stats.vehicles)}
                  {renderStat('dses', stats.salesmanCount)}
                  {renderStat('sales', formatCurrencyNum(stats.sales), true)}
                  {renderStat('assigned', stats.assigned)}
                  {renderStat('unassigned', stats.unAssigned)}
                  {renderStat('no-gps', stats.noGPS)}
                  {renderStat(
                    'max overlap',
                    stats.maxOverlap > 1e-4 ? format1(stats.maxOverlap * 100) + '%' : '0%'
                  )}
                  {renderStat(
                    'total overlap',
                    stats.maxOverlap > 1e-4 ? format1(stats.totalOverlap * 100) + '%' : '0%'
                  )}
                  {renderStat('total cost', stats.totalCost)}
                </Grid>
              </Grid>
            )}
            {[ps.PROCESSING].includes(status) && (
              <Grid item xs className={classes.progressBar}>
                <Grid container justify="flex-start" alignItems="center">
                  <Grid item xs>
                    <LinearProgress variant="buffer" valueBuffer={progress} value={progress} />
                  </Grid>
                  <Grid item xs={4} md={5}>
                    <Typography
                      noWrap
                      className={classes.branchName}
                      color="textSecondary"
                      align="center"
                    >
                      {`${progress || 0}%`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs className={classes.menu}>
              <MenuItems planId={plan.id}></MenuItems>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const MenuItems = React.memo(function MenuItems({ planId }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [fetch] = useAPI();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const menuOptions = useMemo(
    () => [
      {
        name: 'Download Order upload',
        onClick: () => {
          fetch(downloadOrderUpload(planId), resp => {
            saveAs(resp, `PlanOrderUpload_${planId}.xlsx`);
          });
        },
      },
      // {
      //   name: 'Download Invoice upload',
      //   onClick: () => {
      //     fetch(downloadInvoiceUpload(planId), resp => {
      //       saveAs(resp, `PlanInvoiceUpload_${planId}.xlsx`);
      //     });
      //   },
      // },
    ],
    [planId, fetch]
  );

  return (
    <>
      <IconButton
        aria-label="more"
        ref={anchorRef}
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        keepMounted
        transition
        placement="left-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {menuOptions.map(option => (
                    <MenuItem
                      key={option.name}
                      onClick={() => {
                        option.onClick();
                        setOpen(false);
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
});
MenuItems.displayName = 'MenuItems';

function getRemainingTimeString(finalTime) {
  // finalTime = new Date(2019, 4, 28, 16, 0);
  const secRemaining = differenceInSeconds(finalTime, new Date());
  return secRemaining >= 0 ? formatTimeMinSec(secRemaining) : '--:--';
}

function CountDownTimer({ finalTime }) {
  const secRemaining = differenceInSeconds(finalTime, new Date());
  const [time, setTime] = useState(getRemainingTimeString(finalTime));

  useInterval(() => {
    setTime(getRemainingTimeString(finalTime));
  }, 1000);

  return (
    <Typography noWrap color={secRemaining < 300 ? 'secondary' : 'inherit'} align="center">
      {time}
    </Typography>
  );
}
