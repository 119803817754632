import grey from '@material-ui/core/colors/grey';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { navigate } from '@reach/router';
import PlanCard from 'components/PlanCard';
import PlanMenu from 'components/PlanMenu';
import { Box } from '@material-ui/core';
import { dismiss } from 'components/SnackbarActions';
import { isSameDay } from 'date-fns';
import { addDays, format, subDays } from 'date-fns/esm';
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import {
  fetchDistanceMatrices,
  fetchDistanceMatrix,
  fetchRoutes,
  fetchRoutesv1,
  getPlan,
  getPlanByDate,
  useGetPlanDetails,
} from 'services/planService';
import { ga, useGDispatch, useGState } from 'state/store';
import {
  useAPI,
  useComputePlanDetails,
  useDidUpdateEffect,
  useFetch,
  useFetchOnAction,
  useFetchv1,
  useInterval,
  useKeyPressed,
  usePrevious,
  useSnackbar,
  useUnobtrusiveLogin,
} from 'utils/customHooks';
import fetchPromise from 'utils/fetch';
import { formatTimeSec, isEmpty, isVal } from 'utils/helperFunctions';
import optimize from 'utils/pathOptimization';
import PlanningActionPanel from './PlanningActionPanel';
import PlanningFilters from './PlanningFilters';
import reducer, { actionTypes } from './planningReducer';
import PlanningTable from './PlanningTable';
import PlanPolishingMap from './PlanPolishingMap';
import { useComputePlansDetails } from 'utils/customHooks';
import { useGetPlans } from 'services/planService';
import { planStates as ps } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  actionPanel: {},
  divider: {
    height: 2,
    backgroundColor: grey[500],
  },
  table: {
    marginTop: theme.spacing(1 / 2),
  },
}));

export default function PlanningDashboard({ id }) {
  const classes = useStyles();
  const ignoreDateCall = useRef(false);
  const [planDetails, , , refetchPlanDetails] = useFetch(useGetPlanDetails(id));
  const [fetchRoutesAction, routes] = useFetchOnAction(fetchRoutesv1(id), [], false);
  const [fetch] = useAPI();
  const [fetchDM] = useAPI();
  const [state, dispatch] = useReducer(reducer, {});
  const prevState = usePrevious(state);
  const { date: selectedDate } = useGState(state => state.date);
  const gDispatch = useGDispatch();
  const [notif, closeSnackbar] = useSnackbar();
  const prevDateButton = useKeyPressed('[');
  const nextDateButton = useKeyPressed(']');

  //fetching the details of all freezed and pending plans
  const { filters, disabled, date } = useGState(state => ({
    filters: state.filters,
    disabled: state.filters.disabled,
    date: state.date.date,
  }));

  const params = useMemo(() => ({ ...(disabled ? {} : filters), date, status: [ps.FROZEN] }), [
    date,
    filters,
    disabled,
  ]);
  const [allFreezedPlansData, , error, refetchAllFreezedPlansData] = useFetch(useGetPlans(params));
  const allFreezedPlans = useComputePlansDetails(allFreezedPlansData);

  const params2 = useMemo(
    () => ({
      ...(disabled ? {} : filters),
      date,
      status: [ps.CREATED, ps.CANCELLED, ps.FAILED, ps.QUEUED, ps.PROCESSING, ps.COMPLETED],
    }),
    [date, filters, disabled]
  );
  const [allPendingPlansData, , error2, refetchAllPendingPlansData] = useFetch(
    useGetPlans(params2)
  );
  const allPendingPlans = useComputePlansDetails(allPendingPlansData);

  useEffect(() => {
    refetchAllFreezedPlansData();
    refetchAllPendingPlansData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetails]);

  useUnobtrusiveLogin();

  useEffect(() => {
    dispatch({ type: actionTypes.CLEAN_SLATE });
  }, [id]);

  useDidUpdateEffect(() => {
    gDispatch({ type: ga.DATE, date: subDays(state.date, 1) });
  }, [prevDateButton]);

  useDidUpdateEffect(() => {
    gDispatch({ type: ga.DATE, date: addDays(state.date, 1) });
  }, [nextDateButton]);

  useDidUpdateEffect(() => {
    if (ignoreDateCall.current) {
      ignoreDateCall.current = false;
      return;
    }

    fetch(
      getPlanByDate(id, selectedDate),
      resp => resp.id && navigate(`/plan/${resp.id}`),
      () => {
        notif(`No plan for ${state.branch?.name} on ${format(selectedDate, 'dd MMM yyyy')}`, {
          variant: 'error',
          persist: true,
          action: dismiss(key => closeSnackbar(key)),
        });
        gDispatch({ type: ga.DATE, date: state.date });
        ignoreDateCall.current = true;
      }
    );
  }, [selectedDate]);

  //Initial setup once the plan details has been fetched
  useEffect(() => {
    if (!planDetails) return;
    if (!isSameDay(selectedDate, planDetails.date)) {
      ignoreDateCall.current = true;
      gDispatch({ type: ga.DATE, date: planDetails.date });
    }

    dispatch({ type: actionTypes.INITIALIZE_PLAN, planDetails });

    if (planDetails.branch && planDetails.inputs && planDetails.inputs.length) {
      const latlngs = [
        { latitude: planDetails.branch.latitude, longitude: planDetails.branch.longitude },
        ...planDetails.inputs.map(({ latitude, longitude }) =>
          latitude && longitude
            ? { latitude, longitude }
            : { latitude: planDetails.branch.latitude, longitude: planDetails.branch.longitude }
        ),
      ];
      fetchDM(fetchDistanceMatrix(latlngs, planDetails.branch.id, id), distanceMatrix =>
        dispatch({ type: actionTypes.DISTANCE_MATRIX, distanceMatrix })
      );
    }
  }, [planDetails, gDispatch, fetchDM]); // eslint-disable-line react-hooks/exhaustive-deps

  //Fecthing new routes whenever the picklist retailers changes
  useEffect(() => {
    if (!prevState || !state.picklists) return;
    const { past, future, picklists } = state;

    //bailing redo
    if (
      past.length &&
      past[past.length - 1].picklists === prevState.picklists &&
      state.future.length &&
      prevState.future.length - state.future.length === 1
    )
      return;

    //bailing undo
    if (
      future.length &&
      future[0].picklists === prevState.picklists &&
      prevState.past.length - state.past.length === 1
    )
      return;

    const modifiedPicklists = Object.values(picklists).filter(
      pl =>
        !prevState.picklists ||
        !prevState.picklists[pl.index] ||
        pl.retailerIds !== prevState.picklists[pl.index].retailerIds
    );

    if (!modifiedPicklists.length) return;

    dispatch({ type: actionTypes.UPDATE_ETAS, modifiedPicklists });

    const meta = [];
    const routesPayload = modifiedPicklists.map(pl => {
      meta.push(pl.index);
      return {
        retailerIds: pl.retailerIds,
        vehicleTypeId: pl.vehicleTypeId,
      };
    });
    fetchRoutesAction({ meta, data: routesPayload });
  }, [state.picklists]); // eslint-disable-line react-hooks/exhaustive-deps

  //Post processing after figuring out original plan
  useEffect(() => {
    if (!state.originalRetailers || isEmpty(state.originalRetailers)) return;
    let originalPicklists = Object.values(JSON.parse(JSON.stringify(state.originalPicklists)));
    const pathOptimize = async () => {
      try {
        const distanceMatrix = await Promise.all(
          Object.values(state.originalPicklists).map(pl =>
            fetchPromise(fetchDistanceMatrices(id, pl.retailerIds))
          )
        );

        const optimizedPlanlists = optimize(
          state.originalRetailers,
          state.originalPicklists,
          state.branch,
          distanceMatrix,
          false
        );
        optimizedPlanlists.forEach(({ index, path: retailerIds }) => {
          originalPicklists[index].retailerIds = retailerIds;
          originalPicklists[index].pathOptimized = true;
        });

        const routesPayload = Object.values(originalPicklists).map(pl => ({
          retailerIds: pl.retailerIds,
          vehicleTypeId: pl.vehicleTypeId,
        }));
        fetch(fetchRoutes(id, routesPayload), routes => {
          routes.forEach((route, index) => {
            originalPicklists[index].route = route.path;
            originalPicklists[index].travelTime = route.travelTime;
            originalPicklists[index].tripDistance = route.tripDistance;
            originalPicklists[index].routesUpdates = true;
          });
          dispatch({ type: actionTypes.UPDATE_ORIGINAL_PICKLIST, originalPicklists });
        });
      } catch (err) {
        console.log(err);
      }
    };

    pathOptimize();
    //eslint-disable-next-line
  }, [state.originalRetailers, state.branch, fetch, id]);

  //Updating new routes in the state
  useEffect(() => {
    if (!routes || !routes.data) return;
    dispatch({
      type: actionTypes.UPDATE_ROUTES_AND_DISTANCE,
      routes: routes.meta.map((picklistIndex, index) => ({
        picklistIndex,
        route: routes.data[index].path,
        travelTime: routes.data[index].travelTime,
        tripDistance: routes.data[index].tripDistance,
      })),
    });
  }, [routes]);

  const getVehicleDetails = useCallback(
    vehicleId => planDetails.vehicleTypes.find(vehicle => vehicle.id === vehicleId),
    [planDetails]
  );

  const stats = useCalculateStats(state, [
    state.retailers,
    state.picklists,
    state.originalPlanView,
    state.originalRetailers,
    state.originalPicklists,
    state.salesmanCount,
    state.metrics,
  ]);

  if (error) return <div>Error</div>;
  if (error2) return <div>Error</div>;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PlanCardContainer id={id} fetchDetails={refetchPlanDetails} stats={stats} />
      </Grid>
      {planDetails && (
        <Grid item xs={12}>
          <Box style={{ maxWidth: '93vw' }}>
            <Paper
              style={{
                textAlign: 'center',
              }}
            >
              <PlanMenu
                id={id}
                plansList={planDetails.status === 'frozen' ? allFreezedPlans : allPendingPlans}
              />
            </Paper>
          </Box>
        </Grid>
      )}
      {planDetails && state.retailers && state.picklists && (
        <>
          <Grid item xs={12} md={7}>
            <PlanPolishingMap
              retailers={state.originalPlanView ? state.originalRetailers : state.retailers}
              picklists={state.originalPlanView ? state.originalPicklists : state.picklists}
              state={state}
              dispatch={dispatch}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <div className={classes.actionPanel}>
              <PlanningActionPanel
                dispatch={dispatch}
                retailers={state.originalPlanView ? state.originalRetailers : state.retailers}
                picklists={Object.values(
                  state.originalPlanView ? state.originalPicklists : state.picklists
                )}
                state={state}
                id={id}
                refetchPlanDetails={refetchPlanDetails}
                stats={stats}
              />
            </div>
            <Divider className={classes.divider} />
            <PlanningFilters {...{ state, dispatch }} />
            <div className={classes.table}>
              <PlanningTable
                retailers={state.originalPlanView ? state.originalRetailers : state.retailers}
                picklists={Object.values(
                  state.originalPlanView ? state.originalPicklists : state.picklists
                ).sort((p1, p2) => p1.index - p2.index)}
                selectedPicklists={state.selectedPicklists}
                dispatch={dispatch}
                getVehicleDetails={getVehicleDetails}
              />
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}

function PlanCardContainer({ id, stats, fetchDetails }) {
  const [planData, , error, refetchPlan] = useFetchv1(getPlan(id), [id], false);
  const plan = useComputePlanDetails(planData);
  const holdStatusCallRef = useRef(false);

  useInterval(() => {
    if (!holdStatusCallRef.current) refetchPlan();
  }, 1000 * 3);

  const holdStatusCall = useCallback(val => {
    holdStatusCallRef.current = val;
  }, []);

  return (
    <>
      {plan && (
        <PlanCard
          plan={plan}
          stats={stats}
          fetchDetails={fetchDetails}
          holdStatusCall={holdStatusCall}
          planPage
          refetch={plan => (plan ? navigate(`/plan/${plan.id}`, { replace: true }) : refetchPlan())}
        />
      )}
      {error && <Typography>Plan Not Found</Typography>}
    </>
  );
}

const useCalculateStats = (state, deps) => {
  return useMemo(() => {
    if (!state.picklists)
      return {
        vehicles: '-',
        assigned: '-',
        unAssigned: '-',
        noGPS: '-',
        salesmanCount: '-',
        maxOverlap: '-',
        totalOverlap: '-',
      };
    const retailersArr = Object.values(
      state.originalPlanView ? state.originalRetailers : state.retailers
    );
    const picklistsArr = Object.values(
      state.originalPlanView ? state.originalPicklists : state.picklists
    );
    return {
      vehicles: picklistsArr.length,
      assigned: retailersArr.filter(r => isVal(r.index)).length,
      unAssigned: retailersArr.filter(
        ({ latitude, longitude, index }) => latitude && longitude && !isVal(index)
      ).length,
      noGPS: retailersArr.filter(({ latitude, longitude }) => !latitude || !longitude).length,
      sales: retailersArr.reduce((acc, { sales }) => acc + sales, 0),
      salesmanCount: state.salesmanCount,
      maxOverlap: state.metrics.maxOverlap,
      totalOverlap: state.metrics.totalOverlap,
      totalCost: formatTimeSec(
        Object.values(state.picklists)?.reduce((acc, pl) => acc + calculateCost(pl, state), 0)
      ),
    };
    //eslint-disable-next-line
  }, deps);
};

function calculateCost(picklist, { retailers, distanceMatrix, vehicleTypes }) {
  const { retailerIds, vehicleTypeId, travelTime, serviceTime = 0 } = picklist;
  if(!retailerIds.length) return 0;
  const retailerId = retailerIds.at(-1);
  const dmIndex = retailers[retailerId]?.dmIndex;
  const distance = distanceMatrix?.[dmIndex][0];
  const vehicle = vehicleTypes?.find((vt) => vt.id === vehicleTypeId);
  const time = (distance / vehicle.avgSpeed) * (5 / 18);
  return travelTime + serviceTime - time + vehicle.fixedCost;
}
