import { ApolloProvider } from '@apollo/react-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import Dialog from '@material-ui/core/Dialog';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { globalHistory, Redirect, Router } from '@reach/router';
import ApolloClient from 'apollo-boost';
import Login from 'auth/Login';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createStore, StoreProvider, useGState } from 'state/store';
import { QueryParamProvider } from 'use-query-params';
import { api } from 'utils/fetch';
import { GlobalContextProvider } from '../state/globalContextv1';
import InitialSetup from './InitialSetup';
import PageLayout from './PageLayout';
import SnackbarProvider from './Snackbar';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GQL_API,
  resolvers: {},
  credentials: 'include',
});

const theme = createMuiTheme({
  primary: {
    light: '#757ce8',
    main: '#388e3c',
    dark: '#002884',
    contrastText: '#fff',
  },
  secondary: {
    light: '#ff7961',
    main: '#f44336',
    dark: '#ba000d',
    contrastText: '#000',
  },
  typography: { useNextVariants: true },
});

const store = createStore();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: ({ queryKey }) => api(queryKey[0]),
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <StoreProvider store={store}>
          <SnackbarProvider>
            <QueryClientProvider client={queryClient}>
              <GlobalContextProvider>
                <QueryParamProvider reachHistory={globalHistory}>
                  <Router>
                    <LoginRoute path="login" />
                    <PrivateRoute
                      as={
                        <>
                          <InitialSetup />
                          <PageLayout />
                        </>
                      }
                      path="/*"
                    />
                  </Router>
                </QueryParamProvider>
              </GlobalContextProvider>
            </QueryClientProvider>
          </SnackbarProvider>
        </StoreProvider>
      </ApolloProvider>
    </MuiThemeProvider>
  );
}

function PrivateRoute(props) {
  const { user, unobtrusiveLogin } = useGState(({ user, unobtrusiveLogin }) => ({
    user,
    unobtrusiveLogin,
  }));
  let { as: Comp } = props;
  return (
    <>
      {unobtrusiveLogin && !user && (
        <Dialog fullScreen open={!user}>
          <Login />
        </Dialog>
      )}
      {!unobtrusiveLogin && !user && <Login />}
      {(unobtrusiveLogin || user) && <Comp.type {...Comp.props} />}
    </>
  );
}

function LoginRoute() {
  const user = useGState(state => state.user);

  return user ? <Redirect to="/" noThrow /> : <Login />;
}

export default App;
