import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';
import Cancel from '@material-ui/icons/Cancel';
import { useSnackbar } from 'utils/customHooks';
import { Box, Button, Typography } from '@material-ui/core';
import fetch from 'utils/fetch';
import { Upload } from './Icons';

export const withSpan = Component => <Component.type component="span" {...Component.props} />;

export default function Uploader({
  render,
  url,
  onSuccess,
  onError = err => console.log(err),
  method = 'post',
  disabled,
  ...rest
}) {
  const [progress, setProgress] = useState(0);
  const [apiInProgress, setApiInProgress] = useState(false);

  const upload = async e => {
    const data = new FormData();
    data.append('file', e.target.files[0]);
    try {
      const result = await fetch({
        method,
        url,
        data,
        onUploadProgress: progressEvent => {
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          if (progressEvent.loaded === progressEvent.total) setApiInProgress(true);
        },
      });
      onSuccess(result.data);
    } catch (error) {
      onError(error);
    }
    setProgress(0);
    setApiInProgress(false);
  };

  return (
    <>
      <input
        type="file"
        id={`text-button-file${url}`}
        style={{
          display: 'none',
        }}
        onChange={upload}
        disabled={disabled}
        {...rest}
      />
      <label htmlFor={`text-button-file${url}`}>{withSpan(render(progress, apiInProgress))}</label>
    </>
  );
}

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  iconButton: {
    padding: 2,
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export const DefaultUploaderRender = ({
  tooltip = 'Upload',
  disabled,
  progress = 0,
  apiInProgress = false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CircularProgress
        size={39}
        className={classes.fabProgress}
        variant={apiInProgress ? 'indeterminate' : 'static'}
        value={progress}
      />
      <Tooltip title={tooltip} placement="top">
        <IconButton
          disabled={disabled}
          component="span"
          color="secondary"
          className={classes.iconButton}
        >
          <Upload fontSize="large" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const useUploadStyles = makeStyles(() => ({
  fabProgress: {
    color: green[500],
    top: 'calc(100%-16px)',
    left: 'calc(100%-10px)',
  },
}));

export const UploadFile = ({ title, url, params }) => {
  const [notif] = useSnackbar();
  const [progress, setProgress] = useState(0);
  const [apiInProgress, setApiInProgress] = useState(false);
  const [file, setFile] = useState();
  const upload = async () => {
    const data = new FormData();
    data.append('file', file);
    try {
      await fetch({
        method: 'post',
        url: url,
        params: params,
        data,
        onUploadProgress: progressEvent => {
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          if (progressEvent.loaded === progressEvent.total) setApiInProgress(true);
        },
      });
      notif('Successfully uploaded', { variant: 'success' });
    } catch {}
    setFile(undefined);
    setProgress(0);
    setApiInProgress(false);
  };

  const classes = useUploadStyles();

  return (
    <Box pb={2}>
      {title ? (
        <Box>
          <Typography variant="h6" align="left">
            {title}
          </Typography>
        </Box>
      ) : null}
      <Box align="center" mt={2} border={1} borderRadius="6px">
        {apiInProgress ? (
          <CircularProgress
            size={32}
            className={classes.fabProgress}
            variant={apiInProgress ? 'indeterminate' : 'static'}
            value={progress || 0}
          />
        ) : (
          <>
            <input
              type="file"
              id={`text-button-file${url}`}
              style={{
                display: 'none',
              }}
              onChange={e => setFile(e.target.files[0])}
            />
            <label htmlFor={`text-button-file${url}`}>
              {withSpan(<DefaultUploaderRender tooltip={title} />)}
            </label>
          </>
        )}
        <Typography variant="subtitle2" align="center" width="fit-content">
          {file ? (
            <Typography>
              {file.name}
              <Button
                startIcon={<Cancel style={{ marginLeft: '8px' }} />}
                onClick={() => setFile(undefined)}
              ></Button>
            </Typography>
          ) : (
            'Select a file to Upload'
          )}
        </Typography>
      </Box>
      <Box pt={2}>
        <Button
          variant="contained"
          onClick={upload}
          style={{ background: file ? '#2C3741' : '#BAC3C8', color: '#FFFFFF', width: '100%' }}
          disabled={!file || apiInProgress}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};
