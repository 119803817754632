import solveTsp from './tsp/solveTsp';

const R = 6371e3; // Radius of earth in metres

function convToRad(degrees) {
  return degrees * (Math.PI / 180);
}

function haversine(lat1, lon1, lat2, lon2) {
  // in degrees
  // convert to radians
  lat1 = convToRad(lat1);
  lon1 = convToRad(lon1);
  lat2 = convToRad(lat2);
  lon2 = convToRad(lon2);

  const dLat = lat2 - lat1;
  const dLon = lon2 - lon1;

  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dLon / 2), 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
}

function filterClosest(retailers, closest) {
  return retailers.filter(item => item !== closest);
}

function nextNearBy(medoids, distanceFunction, last) {
  let minimumDistance = Number.MAX_VALUE;
  let closest = 0;
  medoids.forEach(id => {
    let distance = distanceFunction(last, id);
    if (distance < minimumDistance) {
      minimumDistance = distance;
      closest = id;
    }
  });
  return closest;
}

function nearestNeighbour(points, distanceFunction, last) {
  let path = [];
  let pointsCopy = points;
  let closest = last;
  for (let i = 0; i < points.length; i++) {
    closest = nextNearBy(pointsCopy, distanceFunction, closest);
    pointsCopy = filterClosest(pointsCopy, closest);
    path.push(closest);
  }
  return path;
}

function choosePoints(path) {
  let first = Math.floor(Math.random() * path.length);
  let second = first;
  while (second === first) {
    second = Math.floor(Math.random() * path.length);
  }
  return { First: first, Second: second };
}

function cost(path, distanceFunction) {
  let cost = 0;
  path.forEach((point, i) => {
    cost += distanceFunction(point, path[(i + 1) % path.length]);
  });
  return cost;
}

function twoOpt(path, distanceFunction, count) {
  if (path.length <= 1) {
    return path;
  }
  let newpath = path;
  for (let i = 0; i < count; i++) {
    newpath = twoOptUtility(newpath, distanceFunction);
  }
  return newpath;
}

function reverseSubPath(path, a, b) {
  let newpath = JSON.parse(JSON.stringify(path));
  let i = a;
  let j = b;
  while (j > i) {
    let temp = newpath[i];
    newpath[i] = newpath[j];
    newpath[j] = temp;
    i++;
    j--;
  }
  return newpath;
}

function swapPoints(path, replacementPoints) {
  let A = replacementPoints.First;
  let B = replacementPoints.Second;
  let C = (B + 1) % path.length;
  let D = (A - 1) % path.length;
  if (D < 0) {
    D += path.length;
  }
  if (A <= B) {
    return reverseSubPath(path, A, B);
  } else if (C <= D) {
    return reverseSubPath(path, C, D);
  } else {
    return path;
  }
}

function twoOptUtility(path, distanceFunction) {
  let newpath = path;
  let replacementPoints = choosePoints(newpath);
  newpath = swapPoints(newpath, replacementPoints);
  if (cost(path, distanceFunction) < cost(newpath, distanceFunction)) {
    return path;
  }
  return newpath;
}

function getMaximumDistance(setA, setB, distanceFunction) {
  let maximumDistance = 0;
  setA.forEach(idA => {
    setB.forEach(idB => {
      let distance = distanceFunction(idA, idB);
      if (distance > maximumDistance) {
        maximumDistance = distance;
      }
    });
  });
  return maximumDistance;
}

function getMedoid(set, distanceFunction) {
  let minimumDistanceSum = 6400000000;
  let medoid;
  set.forEach(id => {
    let distanceSum = 0;
    set.forEach(idB => {
      let distance = distanceFunction(id, idB);
      distanceSum += distance;
    });
    if (distanceSum < minimumDistanceSum) {
      medoid = id;
      minimumDistanceSum = distanceSum;
    }
  });
  return medoid;
}

function getMedoidDict(clusters, distanceFunction) {
  let result = {};
  for (let [, set] of Object.entries(clusters)) {
    let medoid = getMedoid(set, distanceFunction);
    result[medoid] = set;
  }
  return result;
}

function createClusters(n, distanceFunction) {
  let clusters = {};
  for (let index = 1; index < n; index++) {
    clusters[index] = new Set([index]);
  }
  let nochange = false;
  while (!nochange) {
    let minimumMaxDistance = 6400000;
    let a = 0,
      b = 0;
    for (let [i, setA] of Object.entries(clusters)) {
      for (let [j, setB] of Object.entries(clusters)) {
        if (i !== j) {
          let maximumDistance = getMaximumDistance(setA, setB, distanceFunction);
          if (maximumDistance < 500 && maximumDistance < minimumMaxDistance) {
            a = i;
            b = j;
            minimumMaxDistance = maximumDistance;
          }
        }
      }
    }
    if (a !== 0 || b !== 0) {
      clusters[a] = new Set([...clusters[a], ...clusters[b]]);
      delete clusters[b];
    } else {
      nochange = true;
    }
  }
  return getMedoidDict(clusters, distanceFunction);
}

function localNearestNeighbour(path, medoidClusterDict, distanceFunction) {
  let newPath = [];
  let last = 0;
  path.forEach(id => {
    let subpath =
      id === 0
        ? [0]
        : id === -1
        ? [-1]
        : nearestNeighbour([...medoidClusterDict[id]], distanceFunction, last);
    newPath.push(...subpath);
    last = subpath[subpath.length - 1];
  });
  return newPath;
}

// This function takes a distance function as input and returns a path.
// Assumption is that the zeroeth index is the branch
// eslint-disable-next-line no-unused-vars
function optimizeV1(n, distanceFunction) {
  let medoidClusterDict = createClusters(n, distanceFunction);
  let path = [0, -1];
  path.push(...Object.keys(medoidClusterDict).map(a => parseInt(a)));
  path = nearestNeighbour(path, distanceFunction, 0);
  path = twoOpt(path, distanceFunction, 100000);
  path = localNearestNeighbour(path, medoidClusterDict, distanceFunction);
  path = rotateToZero(path);
  path = checkAndReverse(path);
  return path;
}

const optimizeV2 = (N, distanceFunction) => {
  return solveTsp(N, distanceFunction);
};

function createDistanceMatrix(path, retailers, branchLatLong) {
  let distances = [];
  let branchDistance = [0];
  path.forEach(retailer => {
    branchDistance.push(
      haversine(
        branchLatLong.latitude,
        branchLatLong.longitude,
        retailers[retailer].latitude,
        retailers[retailer].longitude
      )
    );
  });
  distances.push(branchDistance);
  path.forEach(retailer => {
    let retailerDistance = [];
    retailerDistance.push(
      haversine(
        retailers[retailer].latitude,
        retailers[retailer].longitude,
        branchLatLong.latitude,
        branchLatLong.longitude
      )
    );
    path.forEach(retailerb => {
      retailerDistance.push(
        haversine(
          retailers[retailer].latitude,
          retailers[retailer].longitude,
          retailers[retailerb].latitude,
          retailers[retailerb].longitude
        )
      );
    });
    distances.push(retailerDistance);
  });
  return distances;
}

function distanceFunctionFactory(path, retailers, branchLatLong, distanceMatrix, takeRoadDistance) {
  let distances = createDistanceMatrix(path, retailers, branchLatLong);
  return function(a, b) {
    if (a === -1 || b === -1) {
      return 0;
    }
    return Math.max(distanceMatrix[a][b], distances[a][b]);
  };
}

function rotateToZero(path) {
  let index = path.findIndex(a => a === 0);
  let newpath = [];
  for (let i = index; i < index + path.length; i++) {
    newpath.push(path[i % path.length]);
  }
  return newpath;
}

function mapPath(path, picklist) {
  let newPath = [];
  path.forEach(id => {
    if (id === 0 || id === -1) {
      return;
    }
    newPath.push(picklist[id - 1]);
  });
  return newPath;
}

function checkAndReverse(path) {
  if (path[1] === -1) {
    return reverseSubPath(path, 1, path.length - 1);
  }
  return path;
}

const optimize = (retailers, picklists, branch, distanceMatrices, takeRoadDistance) => {
  let paths = [];
  Object.values(picklists).forEach((picklist, i) => {
    let latlong = {
      latitude: branch.latitude,
      longitude: branch.longitude,
    };
    const distanceFunction = distanceFunctionFactory(
      picklist.retailerIds,
      retailers,
      latlong,
      distanceMatrices[i].data,
      takeRoadDistance
    );
    let path = optimizeV2(picklist.retailerIds.length + 1, distanceFunction);
    path = mapPath(path, picklist.retailerIds);
    paths.push({ index: picklist.index, path: path });
  });
  return paths;
};

export default optimize;
