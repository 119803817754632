import React from 'react';

import PlanFilter from '../shared/PlanFilter';
import PlanFeed from '../shared/PlanFeed';
import PlanningSidebar from 'planning/PlanningSidebar';
import PlanningDashboard from 'planning/PlanningDashboard';
import PlanActivityBarIcon from 'shared/PlanActivityBarIcon';
import FilterNone from '@material-ui/icons/FilterNoneRounded';
// import LayersClear from '@material-ui/icons/LayersClearRounded';
import Work from '@material-ui/icons/WorkRounded';
import SettingsApplications from '@material-ui/icons/FormatListBulletedRounded';
import PlanProfile from 'planProfile/PlanProfile';
import PlanFeedDone from 'shared/PlanFeedDone';
import GenericSidebar from 'components/GenericSidebar';
import ClientConfig from 'clientConfig/ClientConfig';
import PlSupport from 'plLoginSupport/LoginSupport';
import Operations from 'operations/Operations';
import PermManagement from 'permissionManagement/UserPermission';
import FileOperations from 'coverageExpansion/fileOperations';
// import Dedup from 'dedup/Dedup';
import HeadsetMicRounded from '@material-ui/icons/HeadsetMicRounded';
import PermIdentityRounded from '@material-ui/icons/PermIdentityRounded';
import ZoomOutMapSharp from '@material-ui/icons/ZoomOutMapSharp';

const routes = [
  {
    path: '/pending',
    title: 'Pending plans',
    Icon: <PlanActivityBarIcon done={false} />,
    SideBar: <PlanFilter done={false} />,
    Component: <PlanFeed done={false} />,
  },
  {
    path: '/done',
    title: 'Freezed plans',
    Icon: <PlanActivityBarIcon done />,
    SideBar: <PlanFilter done />,
    Component: <PlanFeedDone />,
  },
  {
    path: '/plan/:id',
    SideBar: <PlanningSidebar />,
    Component: <PlanningDashboard />,
  },
  {
    path: '/planProfile/:branchId',
    title: 'Plan Profiles',
    Icon: <FilterNone />,
    SideBar: <GenericSidebar text="Plan Profiles" />,
    Component: <PlanProfile />,
  },
  // {
  //   path: '/dedup',
  //   title: 'Duplicate Retailers Filtering',
  //   Icon: <LayersClear />,
  //   SideBar: <GenericSidebar text="Deduplication" />,
  //   Component: <Dedup />,
  // },
  {
    path: '/clientConfig/:clientId',
    title: 'Branch Settings',
    Icon: <SettingsApplications />,
    SideBar: <GenericSidebar text="Client Config" />,
    Component: <ClientConfig />,
  },
  {
    path: '/operations',
    title: 'Operations',
    Icon: <Work />,
    SideBar: <GenericSidebar text="Operations" />,
    Component: <Operations />,
  },
  {
    path: '/passwordlessSupport/:nodeId',
    title: 'Passwordless Support',
    Icon: <HeadsetMicRounded />,
    SideBar: <GenericSidebar text="Passwordless Support" />,
    Component: <PlSupport />,
  },
  {
    path: '/permissionsManagement',
    title: 'Permissions Management',
    Icon: <PermIdentityRounded />,
    SideBar: <GenericSidebar text="Permissions Management" />,
    Component: <PermManagement />,
  },
  {
    path: '/coverage-expansion',
    title: 'Coverage Expansion',
    Icon: <ZoomOutMapSharp />,
    SideBar: <GenericSidebar text="Coverage Expansion" />,
    Component: <FileOperations />,
  },
];

export default routes;
