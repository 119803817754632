import { makeStyles } from '@material-ui/styles';
import Select from '@material-ui/core/Select';
import { Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PlanCard from 'components/PlanCard';
import PlanCardLiteGrouped from 'planning/PlanCardLiteGrouped';
import PlanCardHeader from 'planning/PlanCardHeader';
import React, { memo, useMemo, useState, useEffect } from 'react';
import { useGetPlans } from 'services/planService';
import { useGlobalSetStatev1 } from 'state/globalContextv1';
import { useGState } from 'state/store';
import { planStates as ps } from 'utils/constants';
import { useComputePlansDetails, useFetch, useInterval } from 'utils/customHooks';

export default function PlanFeedContainer({ done }) {
  const { filter, disabled } = useGState(state => ({
    filter: state.filters,
    disabled: state.filters.disabled,
  }));
  const { date } = useGState(state => state.date);
  const setLoading = useGlobalSetStatev1('loading');
  const [groupBy, setGroupBy] = useState(localStorage.getItem('group') === 'true');

  useEffect(() => {
    localStorage.setItem('group', groupBy);
  }, [groupBy]);

  const params = useMemo(
    () => ({
      ...(disabled ? {} : filter),
      date,
      status: done
        ? [ps.FROZEN]
        : [ps.CREATED, ps.CANCELLED, ps.FAILED, ps.QUEUED, ps.PROCESSING, ps.COMPLETED],
    }),
    [filter, date, done, disabled]
  );

  const [plansData, loading, error, refetchPlans] = useFetch(useGetPlans(params));
  useInterval(() => refetchPlans(), 1000 * 10);
  setLoading(loading);

  const plans = useComputePlansDetails(plansData);
  let plansLength = 0;
  if (plans) {
    plansLength = plans.length;
  }
  const makeGroup = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const groupedPlansByBranch = [];

  if (plans) {
    const groupedPlans = makeGroup(plans, 'branchId');
    Object.keys(groupedPlans).forEach(function(category) {
      const length = groupedPlans[category].length;
      const planIds = [];
      const profileNames = [];
      let object = {
        planCount: undefined,
        planId: undefined,
        branchName: ' ',
        branchId: undefined,
        clientName: ' ',
        profileName: ' ',
        status: ' ',
      };
      groupedPlans[category].forEach(function(item, i) {
        planIds.push(item.planId);
        if (!item.profileName) {
          item.profileName = 'Default';
        }
        profileNames.push(item.profileName);
        object = {
          planCount: length,
          branchName: item.branchName,
          branchId: item.branchId,
          clientName: item.clientName,
          profileName: profileNames,
          planId: planIds,
          status: item.status,
        };
      });
      groupedPlansByBranch.push(object);
    });
  }

  const classes = useStyles();
  return (
    <div>
      <div className={classes.select}>
        <Box display="flex">
          <Box flexGrow={1} mr={1} mb={2}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <InputLabel id="demo-simple-select-label">Group by</InputLabel>
              </Grid>
              <Grid item>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={groupBy}
                  placeholder="Group by"
                  disableUnderline
                  onChange={e => setGroupBy(e.target.value)}
                >
                  <MenuItem value={false}>Plan</MenuItem>
                  <MenuItem value={true}>Branch</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
      {(groupedPlansByBranch.length > 0 || plansLength > 0) && (
        <PlanCardHeader groupBy={groupBy} status="pending" />
      )}
      {groupBy === false ? (
        <PlanFeed plans={plans} error={error} refetchPlans={refetchPlans} />
      ) : (
        groupedPlansByBranch.map(
          ({ planCount, planId, branchName, clientName, status, profileName }) => (
            <PlanCardLiteGrouped
              key={planId}
              planCount={planCount}
              id={planId}
              branchName={branchName}
              clientName={clientName}
              status={status}
              profileName={profileName}
            />
          )
        )
      )}
    </div>
  );
}

const PlanFeed = memo(({ plans, error, refetchPlans }) => {
  return (
    <div>
      {error && 'Error'}
      {plans &&
        plans
          .sort((p1, p2) => new Date(p1.createdAt) - new Date(p2.createdAt))
          .map(
            ({
              branchName,
              clientName,
              planId,
              profileName,
              progress,
              status,
              updatedAt,
              createdAt,
            }) => (
              <PlanCardContainer
                key={planId}
                refetchPlans={refetchPlans}
                branchName={branchName}
                clientName={clientName}
                planId={planId}
                profileName={profileName}
                progress={progress}
                status={status}
                updatedAt={updatedAt}
                createdAt={createdAt}
              />
            )
          )}
    </div>
  );
});
PlanFeed.displayName = 'PlanFeed';

const useStyles = makeStyles(theme => ({
  planCardContainer: {
    marginTop: theme.spacing(1),
  },
  select: {
    top: 0,
    right: 220,
    marginLeft: theme.spacing(1),
    width: 'auto',
    zIndex: 10,
  },
}));

const PlanCardContainer = memo(
  ({
    branchName,
    clientName,
    planId,
    profileName,
    progress,
    status,
    updatedAt,
    refetchPlans,
    createdAt,
  }) => {
    const classes = useStyles();
    return (
      <div className={classes.planCardContainer} key={planId}>
        <PlanCard
          plan={{
            branchName,
            clientName,
            profileName,
            progress,
            status,
            updatedAt,
            id: planId,
            createdAt,
          }}
          showId={'true'}
          refetch={() => refetchPlans()}
        />
      </div>
    );
  }
);
PlanCardContainer.displayName = 'PlanCardContainer';