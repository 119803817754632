// export function useGetConfigByClient(id) {
//   return useMemo(
//     () => ({
//       method: 'get',
//       url: id.startsWith('p')
//         ? `/admin/salesman/settings?principalId=${id.slice(1)}`
//         : `/admin/salesman/settings?clientId=${id}`,
//     }),
//     [id]
//   );
// }

export const saveConfig = (nodeId, data, secure = false) => ({
  method: 'put',
  url: `/v2/admin/settings?nodeId=${nodeId}${secure ? '&secure=true' : ''}`,
  data,
});
