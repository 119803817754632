import grey from '@material-ui/core/colors/grey';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import { Grid, Box } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fuse from 'fuse.js';
import PlanCardLite from 'planning/PlanCardLite';
import PlanCardLiteGrouped from 'planning/PlanCardLiteGrouped';
import PlanCardHeader from 'planning/PlanCardHeader';
import React, { useMemo, useState, useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { useGetPlans } from 'services/planService';
import { useGState } from 'state/store';
import { planStates as ps } from 'utils/constants';
import { useComputePlansDetails, useFetch } from 'utils/customHooks';
import { gql, useQuery } from 'utils/graphql';
import { arrayToObject } from 'utils/helperFunctions';

// import { debounce } from 'throttle-debounce';

const useStyles = makeStyles(theme => ({
  root: {
    background: grey[200],
    padding: theme.spacing(2),
    margin: theme.spacing(-2),
    minHeight: '100vh',
  },
  search: {
    position: 'fixed',
    top: 0,
    right: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.35),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.black, 0.65),
    },
    marginLeft: theme.spacing(1),
    width: 'auto',
    zIndex: 10,
  },
  select: {
    top: 0,
    right: 220,
    marginLeft: theme.spacing(1),
    width: 'auto',
    zIndex: 10,
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'white',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: 0,
    '&:focus': {
      width: 150,
    },
  },

}));

const PLAN_DETAILS = gql`
  query plans($ids: [Int]) {
    plans(ids: $ids) {
      id
      frozenBy {
        name
      }
    }
  }
`;

export default function PlanFeedDone() {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [groupBy, setGroupBy] = useState(localStorage.getItem('group') === 'true');

  useEffect(() => {
    localStorage.setItem('group', groupBy);
  }, [groupBy]);

  const { filters, disabled, date } = useGState(state => ({
    filters: state.filters,
    disabled: state.filters.disabled,
    date: state.date.date,
  }));
  const params = useMemo(() => ({ ...(disabled ? {} : filters), date, status: [ps.FROZEN] }), [
    date,
    filters,
    disabled,
  ]);

  const [plansData, , error] = useFetch(useGetPlans(params));
  const plans = useComputePlansDetails(plansData);

  const { data: freezers } = useQuery(PLAN_DETAILS, {
    skip: !(plans && plans.length),
    variables: { ids: plans ? plans.map(p => p.planId) : [] },
  });

  const frozenBys = useMemo(() => {
    if (!(freezers && freezers.plans)) return;
    return arrayToObject(freezers.plans);
  }, [freezers]);

  const filteredPlans = useMemo(() => {
    if (!plans) return [];

    let fPlans = plans.map(({ planId, profileName, status, branchName, clientName, branchId }) => ({
      planId,
      branchName,
      clientName,
      profileName,
      status,
      branchId,
    }));

    if (frozenBys) {
      fPlans.forEach(plan => {
        plan.frozenBy =
          frozenBys[plan.planId] && frozenBys[plan.planId].frozenBy
            ? frozenBys[plan.planId].frozenBy.name
            : null;
      });
    }
    return fPlans;
  }, [plans, frozenBys]);

  const fuse = useMemo(() => {
    const options = {
      shouldSort: true,
      tokenize: true,
      threshold: 0.3,
      location: 0,
      distance: 10,
      maxPatternLength: 15,
      minMatchCharLength: 1,
      keys: ['name', 'clientName', 'branchName', 'frozenBy'],
    };
    return new Fuse(filteredPlans, options);
  }, [filteredPlans]);

  const searchedPlans = useMemo(() => {
    return searchText ? fuse.search(searchText) : filteredPlans;
  }, [filteredPlans, fuse, searchText]);

  const makeGroup = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const groupedPlans = makeGroup(searchedPlans, 'branchId');
  const groupedPlansByBranch = [];
  Object.keys(groupedPlans).forEach(function(category) {
    const length = groupedPlans[category].length;
    const planIds = [];
    const profileNames = [];
    let object = {
      planCount: undefined,
      planId: undefined,
      branchName: ' ',
      branchId: undefined,
      clientName: ' ',
      profileName: ' ',
      status: ' ',
    };
    groupedPlans[category].forEach(function(item, i) {
      planIds.push(item.planId);
      if (!item.profileName) {
        item.profileName = 'Default';
      }
      profileNames.push(item.profileName);
      object = {
        planCount: length,
        branchName: item.branchName,
        branchId: item.branchId,
        clientName: item.clientName,
        profileName: profileNames,
        planId: planIds,
        status: item.status,
      };
    });
    groupedPlansByBranch.push(object);
  });

  if (error) return <div>Error</div>;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder=""
            onChange={e => {
              setSearchText(e.target.value);
            }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
        <div className={classes.select}>
          <Box display="flex">
            <Box flexGrow={1} mr={1} mb={2}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <InputLabel id="demo-simple-select-label">Group by</InputLabel>
                </Grid>
                <Grid item>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={groupBy}
                    placeholder="Group by"
                    disableUnderline
                    onChange={e => setGroupBy(e.target.value)}
                  >
                    <MenuItem value={false}>Plan</MenuItem>
                    <MenuItem value={true}>Branch</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
        {(searchedPlans.length > 0 || groupedPlansByBranch.length > 0) && (
          <PlanCardHeader groupBy={groupBy} status="done" />
        )}
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="List"
              height={height}
              itemCount={groupBy === false ? searchedPlans.length : groupedPlansByBranch.length}
              itemSize={48}
              width={width}
            >
              {({ index, style }) => (
                <div style={style}>
                  {groupBy === false ? (
                    <PlanCardLite
                      id={searchedPlans[index].planId}
                      branchName={searchedPlans[index].branchName}
                      clientName={searchedPlans[index].clientName}
                      status={searchedPlans[index].status}
                      profileName={searchedPlans[index].profileName}
                    />
                  ) : (
                    <PlanCardLiteGrouped
                      planCount={groupedPlansByBranch[index].planCount}
                      id={groupedPlansByBranch[index].planId}
                      branchName={groupedPlansByBranch[index].branchName}
                      clientName={groupedPlansByBranch[index].clientName}
                      status={groupedPlansByBranch[index].status}
                      profileName={groupedPlansByBranch[index].profileName}
                    />
                  )}
                </div>
              )}
            </List>
          )}
        </AutoSizer>
      </div>
    </>
  );
}
