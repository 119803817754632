import React, { useState, memo } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { api } from 'utils/fetch';
import { saveAs } from 'file-saver';
import { useAPI } from 'utils/customHooks';
import { ga, useGState } from 'state/store';
import { useNodeOptions } from 'components/NodeSelector';
import { UploadFile } from 'components/Uploader';
import CloudDownloadRounded from '@material-ui/icons/CloudDownloadRounded';
import { Box, Button, Grid, Typography } from '@material-ui/core';

export default function BaseOutlets() {
  const [type, setType] = useState();
  const principals = useGState(s => s[ga.PRINCIPALS]);
  const [principal, setPrincipal] = useState();
  const [client, setClient] = useState();
  const typeOptions = [
    { label: 'Delete', value: 'delete' },
    { label: 'Download', value: 'download' },
    { label: 'Upload', value: 'upload' },
  ];
  const rootOptions = useNodeOptions(principals);

  const apiUrl = {
    delete: '/universe/baseOutlet/delete',
    upload: '/universe/baseOutlet/upload',
  };
  const [fetch] = useAPI();
  const downloadAPI = {
    method: 'get',
    responseType: 'blob',
    url: `/universe/baseOutlet/download?nodeId=${client}`,
  };
  return (
    <div>
      <Typography
        variant="h5"
        style={{ fontWeight: '600', marginBottom: '24px', marginTop: '32px' }}
      >
        Base Outlets
      </Typography>
      <Grid container align="left" spacing={4} display="flex">
        <Grid item xs={3}>
          <Box style={{ background: 'white' }} borderRadius="12px" p={3}>
            <Select
              options={typeOptions}
              value={typeOptions.filter(({ value }) => value === type)}
              onChange={({ value }) => {
                setType(value);
              }}
              menuPlacement="auto"
              placeholder="Select Type ..."
            />
            <Box marginTop={3}>
              <Select
                options={rootOptions}
                value={rootOptions.filter(({ value }) => value === principal)}
                onChange={({ value }) => {
                  setPrincipal(value);
                }}
                menuPlacement="auto"
                placeholder="Select Principal ..."
              />
            </Box>
            <ClientNodeSelector
              parentId={principal}
              client={client}
              handleChange={val => setClient(val.value)}
            />
            {['delete', 'upload'].includes(type) && (
              <UploadFile url={apiUrl[type]} params={{ nodeId: client }} />
            )}
            {type === 'download' && (
              <Button
                px={3}
                startIcon={<CloudDownloadRounded />}
                variant="contained"
                onClick={() => {
                  fetch(downloadAPI, resp => saveAs(resp, `Base_${client}.xlsx`));
                }}
                style={{
                  background: !client ? '#BAC3C8' : '#2C3741',
                  color: '#FFFFFF',
                  width: '100%',
                  marginTop: '24px',
                }}
                disabled={!client}
              >
                Download
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

const ClientNodeSelector = memo(function ClientNodeSelector({ parentId, client, handleChange }) {
  const { data: nodes } = useQuery(
    ['childNodes', parentId],
    () =>
      api({
        method: 'POST',
        url: '/core/node/query',
        data: {
          ids: [Number(parentId)],
          children: true,
          maxDistance: 1,
        },
      }),
    { enabled: parentId !== undefined }
  );
  const nodesOptions = useNodeOptions(nodes, parentId);
  return (
    <Box marginTop={3}>
      <Select
        options={nodesOptions}
        value={nodesOptions.filter(({ value }) => value === client)}
        placeholder="Select Client ..."
        onChange={handleChange}
        menuPlacement="auto"
      />
    </Box>
  );
});
