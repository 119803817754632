import produce from 'immer';
import { planEdits as pe, planStates as ps } from 'utils/constants';
import { isEmpty, isVal, remove, removeByIndex } from 'utils/helperFunctions';
import {
  findClosestRetailer,
  getPicklistsFromPlan,
  getRetailersFromPlan,
  updateRetailersETA,
} from 'utils/mapsUtils';
import optimize from 'utils/pathOptimization';
import { memoizedGenerateOriginalPlan } from './planningUtils';

const generateOriginalPlan = memoizedGenerateOriginalPlan();

const getInitialState = (plan, state) => {
  if (!plan) return {};
  //No maps if the plan is not in this states
  if (![ps.COMPLETED, ps.FROZEN, ps.CANCELLED].includes(plan.status)) return {};

  const retailers = getRetailersFromPlan(plan);

  //If plan has incorrect retailers data
  if (retailers === null) {
    alert('Error in plan data.');
    return {};
  }
  let picklists = getPicklistsFromPlan(plan.picklists, retailers, true);
  Object.values(picklists).forEach(
    pl =>
      (picklists[pl.index] = {
        ...(state.picklists ? state.picklists[pl.index] : {}),
        ...pl,
      })
  );

  const channelsSet = new Set();
  plan.inputs.forEach(retailer => {
    channelsSet.add(retailer.channel);
  });
  const channels = [...channelsSet];

  return {
    //for undo redo feature
    past: [],
    future: [],

    //operational data
    retailers,
    picklists,
    distanceMatrix: null,
    edits: [],
    editInfo: [],
    editInfoOverwrite: '',

    //display and static data
    date: plan.date,
    branch: plan.branch,
    status: plan.status,
    profileName: plan.name,
    updatedAt: plan.updatedAt,
    vehicleTypes: plan.vehicleTypes,
    metrics: plan.metrics,
    frozenBy: null,
    retailerInfoWindow: null,
    routesView: state.routesView || false,
    planId: plan.id,
    originalPlanView: state.originalPlanView || false,
    originalRetailers: {},
    originalPicklists: {},
    allEdits: plan.edits || [],
    salesView: state.salesView || true,
    selectedRetailers: [],
    salesmanCount: plan.salesmanCount,
    exclusiveZones: plan.exclusiveZones,
    salesmen: plan.salesmen,
    deliveryStartTime: plan.deliveryStartTime,
    visibilityFilters: ['unassigned', 'retry'],
    channels,
    highlightFilters: { 'Other Attribute': [], Channels: [], DSEs: [] },
    selectedPicklists: state.selectedPicklists || plan.picklists.map(pl => pl.index),
  };
};
const reducer = (state, action) => {
  action = preProcess(state, action);
  return produce(state, draft => {
    let { picklists, retailers } = draft;
    switch (action.type) {
      case actionTypes.INITIALIZE_PLAN:
        return getInitialState(action.planDetails, state);

      case actionTypes.CLEAN_SLATE:
        return {};

      case actionTypes.DISTANCE_MATRIX:
        draft.distanceMatrix = action.distanceMatrix;
        if (state.picklists) {
          draft.retailers = updateRetailersETA(
            Object.values(state.picklists),
            draft.retailers,
            state.vehicleTypes,
            action.distanceMatrix,
            state.deliveryStartTime
          );
          draft.picklists = getPicklistsFromPlan(draft.picklists, draft.retailers);
        }
        break;

      case actionTypes.UPDATE_ETAS:
        // console.log(action.modifiedPicklists);
        if (state.distanceMatrix) {
          draft.retailers = updateRetailersETA(
            action.modifiedPicklists,
            draft.retailers,
            state.vehicleTypes,
            state.distanceMatrix,
            state.deliveryStartTime
          );
          draft.picklists = getPicklistsFromPlan(draft.picklists, draft.retailers);
        }
        break;

      case actionTypes.RESTORE_RETAILER:
        draft.retailers[action.retailer.id] = { ...action.retailer };
        break;

      case actionTypes.FROZEN_BY:
        draft.frozenBy = action.user;
        break;

      case actionTypes.HIGHLIGHTS:
        draft.highlightFilters = action.data;
        break;

      case actionTypes.ADD_PICKLIST:
        {
          const {
            retailer: { id, serviceTime, waitingTime, sales, volume, weight },
            vehicleTypeId,
          } = action;

          const index = Object.keys(state.picklists).length
            ? Object.keys(state.picklists).reduce((a, b) => Math.max(a, b), 0) + 1
            : 0;

          //Add a picklist
          draft.picklists[index] = {
            index,
            name: `V${index + 1}`,
            retailerIds: [id],
            vehicleTypeId,
            serviceTime,
            waitingTime,
            sales,
            volume,
            weight,
            tripDistance: 0,
            travelTime: 0,
          };

          //Add the new piclist to selected picklisst
          draft.selectedPicklists.push(index);

          //Update the picklist index in the retailer
          draft.retailers[id].index = index;

          //adding edits
          action.edits = [
            { type: pe.ADD_PICKLIST, details: { vehicleTypeId } },
            {
              type: pe.ASSIGN_RETAILER,
              details: { picklistIndex: index, retailerId: id, positionToInsert: 0 },
            },
          ];

          draft.editInfo = 'ADDED PICKLIST';
        }
        break;

      case actionTypes.REMOVE_PICKLIST:
        delete draft.picklists[action.picklistIndex];
        action.edits = [
          { type: pe.REMOVE_PICKLISTS, details: { picklistIndexes: [action.picklistIndex] } },
        ];
        draft.editInfo = 'REMOVED PICKLIST';
        break;

      case actionTypes.RETAILERS_SELECTED:
        draft.selectedRetailers = action.retailerIds;
        draft.editInfoOverwrite = `${draft.selectedRetailers.length} Selected`;
        break;

      // case actionTypes.SET_ORIGINAL_PLAN:
      //   draft.originalRetailers = action.retailers;
      //   draft.originalPicklists = action.piclists;
      //   break;

      case actionTypes.CLEAR_SELECTED_RETAILERS:
        draft.selectedRetailers = [];
        draft.editInfoOverwrite = '';
        break;

      case actionTypes.RETAILER_RIGHTCLICKED:
        {
          if (!draft.selectedRetailers) return state;
          const {
            retailer: { id: toId, index: picklistIndex },
          } = action;
          action.edits = [];

          draft.selectedRetailers.forEach(({ id, index }) => {
            //Bulk unassign
            if (!isVal(picklistIndex)) {
              if (isVal(state.retailers[id].index)) {
                const tmpAction = {
                  type: pe.UNASSIGN_RETAILER,
                  details: {
                    retailerId: id,
                    picklistIndex: index,
                  },
                };

                draft.picklists = getModifiedPicklists(tmpAction, picklists, retailers);
                draft.retailers[id].index = null;
                action.edits.push(tmpAction);
              }
            }

            //belongs to same picklist
            else if (index !== picklistIndex) {
              if (isVal(index)) {
                const tmpAction = {
                  type: pe.CHANGE_PICKLIST,
                  details: {
                    retailerId: id,
                    fromIndex: index,
                    toIndex: picklistIndex,
                    positionToInsert: picklists[picklistIndex].retailerIds.findIndex(
                      retId => retId === toId
                    ),
                  },
                };

                draft.picklists = getModifiedPicklists(tmpAction, picklists, retailers);
                draft.retailers[id].index = draft.picklists[picklistIndex].index;
                action.edits.push(tmpAction);
              } else {
                const tmpAction = {
                  type: pe.ASSIGN_RETAILER,
                  details: {
                    picklistIndex,
                    retailerId: id,
                    positionToInsert: picklists[picklistIndex].retailerIds.findIndex(
                      retId => retId === toId
                    ),
                  },
                };

                draft.picklists = getModifiedPicklists(tmpAction, picklists, retailers);
                draft.retailers[id].index = picklistIndex;
                action.edits.push(tmpAction);
              }
            }
          });

          if (!action.edits.length) return;

          draft.editInfo = isVal(picklistIndex)
            ? `BULKASSIGN to ${picklists[picklistIndex].name}`
            : 'BULK UNASSIGN';
        }

        break;

      case pe.ASSIGN_RETAILER:
        {
          const {
            details: { picklistIndex, retailerId },
          } = action;

          draft.picklists = getModifiedPicklists(action, picklists, retailers);

          draft.retailers[retailerId].index = picklistIndex;
          draft.editInfo = [retailerId, null, picklistIndex];
        }
        break;

      case pe.UNASSIGN_RETAILER:
        {
          const {
            details: { picklistIndex, retailerId },
          } = action;

          draft.picklists = getModifiedPicklists(action, picklists, retailers);

          draft.retailers[retailerId].index = null;
          draft.editInfo = [retailerId, picklistIndex, null];
        }
        break;
      case pe.CHANGE_PICKLIST:
        {
          const {
            details: { fromIndex, retailerId, toIndex },
          } = action;

          draft.picklists = getModifiedPicklists(action, picklists, retailers);

          draft.retailers[retailerId].index = draft.picklists[toIndex].index;
          draft.editInfo = [retailerId, fromIndex, toIndex];
        }
        break;

      case pe.REARRANGE_ROUTE:
        {
          const {
            details: { picklistIndex, retailerId },
          } = action;

          draft.picklists = getModifiedPicklists(action, picklists, retailers);
          draft.editInfo = [retailerId, picklistIndex, picklistIndex];
        }
        break;

      case pe.REARRANGE_ROUTE_FULL:
        {
          const edits = [];
          const optimizedPlanlists = optimize(
            state.retailers,
            state.picklists,
            state.branch,
            action.distanceMatrix,
            state.routesView
          );

          optimizedPlanlists.forEach(({ index, path: retailerIds }) => {
            edits.push({
              type: pe.REARRANGE_ROUTE_FULL,
              details: {
                picklistIndex: index,
                newRouteOrder: retailerIds.map(rid =>
                  state.picklists[index].retailerIds.indexOf(rid)
                ),
              },
            });
            draft.picklists[index].retailerIds = retailerIds;
          });

          action.edits = edits;
          draft.editInfo = 'PATH OPTIMIZATION';
        }
        break;
      case actionTypes.TOGGLE_RETAILER_INFO_WINDOW:
        {
          const {
            retailer: { id, index },
            // state: stateOverride,
          } = action;
          let openInfo = !(
            state.retailerInfoWindow &&
            state.retailerInfoWindow.id === id &&
            !state.retailerInfoWindow.hover
          );
          // if (stateOverride) openInfo = stateOverride;

          draft.retailerInfoWindow = openInfo
            ? {
                id,
                picklist: (index || index === 0) && index >= 0 ? state.picklists[index] : null,
              }
            : null;
        }
        break;
      case actionTypes.OPEN_RETAILER_INFO_WINDOW:
        {
          if (action.hover && draft.retailerInfoWindow && !draft.retailerInfoWindow.hover)
            return state;

          const index = action.retailer ? action.retailer.index : null;

          draft.retailerInfoWindow = {
            id: action.retailer ? action.retailer.id : action.retailerId,
            picklist:
              index !== null
                ? isVal(index)
                  ? state.picklists[index]
                  : null
                : state.retailers[action.retailerId] &&
                  state.picklists[state.retailers[action.retailerId].index],
            ...(action.hover ? { hover: action.hover } : {}),
          };
        }
        break;
      case actionTypes.CLOSE_RETAILER_INFO_WINDOW:
        if (action.hover && draft.retailerInfoWindow && !draft.retailerInfoWindow.hover)
          return state;

        draft.retailerInfoWindow = null;
        break;

      case actionTypes.UPDATE_VIEW_TYPE:
        draft.routesView = action.views.includes('routes');
        draft.salesView = action.views.includes('sales');
        if (action.views.includes('originalPlanView')) {
          if (isEmpty(state.originalRetailers)) {
            const { retailers, picklists } = generateOriginalPlan(
              {
                retailers: JSON.parse(JSON.stringify(state.retailers)),
                picklists: JSON.parse(JSON.stringify(state.picklists)),
                edits: state.allEdits,
              },
              state.planId
            );

            draft.originalRetailers = retailers;
            // draft.originalPicklists = picklists;
            draft.originalPicklists = getPicklistsFromPlan(picklists, retailers);
          }

          draft.originalPlanView = action.views.includes('originalPlanView');
        } else {
          draft.originalPlanView = false;
        }
        break;

      case actionTypes.UPDATE_VISIBILITY_FILTERS:
        draft.visibilityFilters = action.newVisibiltyFilters;
        break;

      case actionTypes.UPDATE_SELECTED_PICKLISTS:
        draft.selectedPicklists = action.selectedPicklists;
        break;

      case actionTypes.UPDATE_ORIGINAL_PICKLIST:
        draft.originalPicklists = action.originalPicklists;
        break;

      case actionTypes.UPDATE_ROUTES_AND_DISTANCE:
        action.routes.forEach(({ picklistIndex, route, travelTime, tripDistance }) => {
          draft.picklists[picklistIndex].route = route;
          draft.picklists[picklistIndex].travelTime = travelTime;
          draft.picklists[picklistIndex].tripDistance = tripDistance;
        });
        break;

      case actionTypes.UNDO: {
        const { retailers, picklists, edits, editInfo, past, future } = state;
        return {
          ...state,
          ...past[past.length - 1],
          past: past.slice(0, past.length - 1),
          future: [{ retailers, picklists, edits, editInfo }, ...future],
          editInfoOverwrite: '',
        };
      }

      case actionTypes.REDO: {
        const { retailers, picklists, edits, editInfo, past, future } = state;
        return {
          ...state,
          ...future[0],
          past: [...past, { retailers, picklists, edits, editInfo }],
          future: future.slice(1),
          editInfoOverwrite: '',
        };
      }
      default:
        console.log('coming in default', action);
        return state;
    }

    //if it's an edit
    if (
      [
        pe.UNASSIGN_RETAILER,
        pe.ASSIGN_RETAILER,
        pe.CHANGE_PICKLIST,
        pe.REARRANGE_ROUTE,
        pe.REARRANGE_ROUTE_FULL,
        actionTypes.ADD_PICKLIST,
        actionTypes.REMOVE_PICKLIST,
        actionTypes.RETAILER_RIGHTCLICKED,
      ].includes(action.type)
    ) {
      //Rearrage route full
      if (
        [
          pe.REARRANGE_ROUTE_FULL,
          actionTypes.REMOVE_PICKLIST,
          actionTypes.ADD_PICKLIST,
          actionTypes.RETAILER_RIGHTCLICKED,
        ].includes(action.type)
      ) {
        draft.edits.push(...action.edits);
      } else {
        draft.edits.push(action);
      }
      draft.picklists = getPicklistsFromPlan(draft.picklists, draft.retailers);

      // clear selected retailers
      draft.selectedRetailers = [];
      draft.editInfoOverwrite = '';

      // past array for undo
      draft.past.push({
        retailers: state.retailers,
        picklists: state.picklists,
        edits: state.edits,
        editInfo: state.editInfo,
      });
      draft.future = [];
    }
  });
};

export const getModifiedPicklists = (action, picklists) => {
  switch (action.type) {
    case pe.ASSIGN_RETAILER: {
      const {
        details: { picklistIndex, retailerId, positionToInsert },
      } = action;
      //insert to picklist
      picklists[picklistIndex].retailerIds.splice(positionToInsert, 0, retailerId);
      return picklists;
    }

    case pe.UNASSIGN_RETAILER: {
      const {
        details: { picklistIndex, retailerId },
      } = action;
      picklists[picklistIndex].retailerIds = remove(
        picklists[picklistIndex].retailerIds,
        retailerId
      );
      return picklists;
    }

    case pe.CHANGE_PICKLIST: {
      const {
        details: { fromIndex, positionToInsert, retailerId, toIndex },
      } = action;
      picklists[fromIndex].retailerIds = remove(picklists[fromIndex].retailerIds, retailerId);
      picklists[toIndex].retailerIds.splice(positionToInsert, 0, retailerId);
      return picklists;
    }

    case pe.REARRANGE_ROUTE: {
      const {
        details: { picklistIndex, retailerId, positionToInsert },
      } = action;
      let plr = picklists[picklistIndex].retailerIds;
      const fromIndex = plr.indexOf(retailerId);

      plr = removeByIndex(plr, fromIndex);

      picklists[picklistIndex].retailerIds = [
        ...plr.slice(0, positionToInsert),
        retailerId,
        ...plr.slice(positionToInsert),
      ];

      // //Swap
      // [
      //   pl.retailerIds[fromIndex],
      //   pl.retailerIds[positionToInsert],
      // ] = [
      //   pl.retailerIds[positionToInsert],
      //   pl.retailerIds[fromIndex],
      // ];
      return picklists;
    }
    default:
      console.log('coming in default of getModifiedPicklists');
      return picklists;
  }
};

const preProcess = (state, action) => {
  if (action.type === actionTypes.RETAILER_DRAGGED) {
    const { retailer, latLng } = action;
    const { selectedPicklists, picklists, retailers } = state;
    const { closestRetailer, shortestDist } = findClosestRetailer(latLng, retailers);

    //return if there both the retailers are unassigned
    if (
      shortestDist > 2e-5 ||
      (!isVal(retailer.index) && !isVal(closestRetailer.index)) ||
      !selectedPicklists.includes(closestRetailer.index)
    ) {
      return { type: actionTypes.RESTORE_RETAILER, retailer };
    }

    let newAction = { type: null, details: {} };

    // the dragged retailer is unassigned
    if (!isVal(retailer.index)) {
      newAction = {
        type: pe.ASSIGN_RETAILER,
        details: {
          picklistIndex: closestRetailer.index,
        },
      };
    }

    //same picklist drags
    else if (retailer.index === closestRetailer.index) {
      newAction = {
        type: pe.REARRANGE_ROUTE,
        details: {
          picklistIndex: retailer.index,
        },
      };
    }

    //else scenario
    else {
      newAction = {
        type: pe.CHANGE_PICKLIST,
        details: {
          fromIndex: retailer.index,
          toIndex: closestRetailer.index,
        },
      };
    }

    newAction.details.retailerId = retailer.id;
    newAction.details.positionToInsert = picklists[closestRetailer.index].retailerIds.findIndex(
      retId => retId === closestRetailer.id
    );

    return newAction;
  }
  return action;
};

export const actionTypes = {
  CLEAN_SLATE: 'CLEAN_SLATE',
  RETAILERS_SELECTED: 'RETAILERS_SELECTED',
  CLEAR_SELECTED_RETAILERS: 'CLEAR_SELECTED_RETAILERS',
  RETAILER_RIGHTCLICKED: 'RETAILER_RIGHTCLICKED',
  INITIALIZE_PLAN: 'INITIALIZE_PLAN',
  RETAILER_DRAGGED: 'RETAILER_DRAGGED',
  ADD_PICKLIST: 'ADD_PICKLIST',
  REMOVE_PICKLIST: 'REMOVE_PICKLIST',
  RESTORE_RETAILER: 'RESTORE_RETAILER',
  HIGHLIGHTS: 'HIGHLIGHTS',
  TOGGLE_RETAILER_INFO_WINDOW: 'TOGGLE_RETAILER_INFO_WINDOW',
  OPEN_RETAILER_INFO_WINDOW: 'OPEN_RETAILER_INFO_WINDOW',
  CLOSE_RETAILER_INFO_WINDOW: 'CLOSE_RETAILER_INFO_WINDOW',
  UPDATE_VIEW_TYPE: 'UPDATE_VIEW_TYPE',
  UPDATE_SELECTED_PICKLISTS: 'UPDATE_SELECTED_PICKLISTS',
  UPDATE_ROUTES_AND_DISTANCE: 'UPDATE_ROUTES_AND_DISTANCE',
  UPDATE_ORIGINAL_PICKLIST: 'UPDATE_ORIGINAL_PICKLIST',
  UPDATE_VISIBILITY_FILTERS: 'UPDATE_VISIBILITY_FILTERS',
  UNDO: 'UNDO',
  REDO: 'REDO',
  SET_ORIGINAL_PLAN: 'SET_ORIGINAL_PLAN',
  FROZEN_BY: 'FROZEN_BY',
  DISTANCE_MATRIX: 'DISTANCE_MATRIX',
  UPDATE_ETAS: 'UPDATE_ETAS',
};

export default reducer;
