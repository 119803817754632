import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Link from 'components/Link';
import React, { memo } from 'react';
import { planStates as ps } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  paper: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
  dropdown: {
    fontSize: '13px',
    color: 'blue',
    margin: '-25px',
  },
}));

function PlanCardLiteGrouped({ planCount, id, profileName, branchName, clientName, status }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container justify="space-around" alignItems="center" wrap="nowrap">
        <Grid item xs>
          <Link to={`/plan/${Math.min(...id)}`}>
            <div
              style={{
                display: 'flex',
                width: '25px',
                height: '25px',
                backgroundColor: 'gainsboro',
                borderRadius: '50%',
                margin: 'auto',
              }}
            >
              <text is="count" style={{ margin: 'auto', color: 'black' }}>
                {planCount}
              </text>
            </div>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to={`/plan/${Math.min(...id)}`}>
            <Typography color="textPrimary" noWrap>
              {branchName}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to={`/plan/${Math.min(...id)}`}>
            <Typography color="textSecondary" variant="body2" noWrap>
              {clientName}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Typography color="primary" component={'div'} variant="body2" noWrap>
            <Select className={classes.dropdown} disableUnderline defaultValue={profileName[0]}>
              {profileName.map((item, pos) => (
                <MenuItem key={pos} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography color="textSecondary" variant="body2">
            {status === ps.FROZEN ? 'FROZEN' : status}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(PlanCardLiteGrouped);
