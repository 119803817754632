import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { actionTypes } from './planningReducer';
import { planStates as ps } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  frozenByChip: {
    margin: theme.spacing(1),
    float: 'right',
  },
}));

const StyledToggleButtonGroup = withStyles(theme => ({
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export default function PlanningFilters({
  state: { visibilityFilters, frozenBy, status },
  dispatch,
}) {
  const classes = useStyles();

  return (
    <Box display="flex" justify="space-between" alignItems="center" marginTop={1 / 2}>
      <Box flexGrow={1}>
        <StyledToggleButtonGroup
          size="small"
          value={visibilityFilters}
          onChange={(_, newVisibiltyFilters) =>
            dispatch({ type: actionTypes.UPDATE_VISIBILITY_FILTERS, newVisibiltyFilters })
          }
        >
          <ToggleButton value="unassigned">UNASSIGNED</ToggleButton>
          <ToggleButton disabled value="retry">
            RETRY
          </ToggleButton>
          <ToggleButton value="exclusiveZones">EXCLUSIVE ZONES</ToggleButton>
        </StyledToggleButtonGroup>
      </Box>
      <Box>
        {status === ps.FROZEN && (
          <Chip label={frozenBy ? frozenBy.name : '--'} className={classes.frozenByChip} />
        )}
      </Box>
    </Box>
  );
}
