import React from 'react';
import { SnackbarProvider as SnackbarProviderNotistack } from 'notistack';
import Slide from '@material-ui/core/Slide';

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function SnackbarProvider({ children }) {
  return (
    <SnackbarProviderNotistack
      maxSnack={3}
      TransitionComponent={TransitionUp}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    >
      {children}
    </SnackbarProviderNotistack>
  );
}
