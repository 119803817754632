import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ActivityBar from './ActivityBar';
import PageContent from './PageContent';
import SideBar from './SideBar';
import routes from './routes';
import { useGlobalStatev1 } from 'state/globalContextv1';
import LinearProgress from '@material-ui/core/LinearProgress';
import green from '@material-ui/core/colors/green';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  linearColorPrimary: {
    backgroundColor: green['100'],
  },
  linearBarColorPrimary: {
    backgroundColor: green['500'],
  },
  content: {
    flexGrow: 1,
  },
  loading: {
    height: theme.spacing(2),
  },
  page: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
}));

export const defaults = {
  SIDEBAR_WIDTH: 320,
  ACTIVITYBAR_WIDTH: 67,
};

export default function PageLayout() {
  const c = useStyles();
  const [open, setOpen] = useState(false);
  const loadingv1 = useGlobalStatev1('loading');
  // const

  const props = {
    routes,
    open,
    setOpen,
    defaults,
  };

  return (
    <div className={c.root}>
      <SideBar {...props} />
      <ActivityBar {...props} />
      <main className={c.content}>
        <div className={c.loading}>
          {loadingv1 && (
            <LinearProgress
              classes={{
                colorPrimary: c.linearColorPrimary,
                barColorPrimary: c.linearBarColorPrimary,
              }}
            />
          )}
        </div>
        <div className={c.page}>
          <PageContent routes={props.routes} />
        </div>
      </main>
    </div>
  );
}
