import { addDays, format as formatDateFns, getHours, isSaturday } from 'date-fns';
import numeral from 'numeral';
import { get, set } from './persistentStore';

let branches = {};
let clients = {};

export function setClientCache(clientsDetails = []) {
  let branchesTmp;
  if (!(clientsDetails instanceof Array && clientsDetails.length)) return;

  branchesTmp = clientsDetails.flatMap(({ id, name, branches }) =>
    branches.map(branch => ({ ...branch, clientId: id, clientName: name }))
  );
  branches = branchesTmp.reduce((acc, branch) => {
    acc[branch.id] = branch;
    return acc;
  }, {});
  clients = arrayToObject(clientsDetails);
  set('clients', branches);
}

export function getBranchDetails(branchId) {
  const defaultDetails = { id: branchId, name: 'NotFound', clientName: 'NotFound' };
  const branchDetails =
    branches[branchId] || get('clients') ? get('clients')[branchId] : defaultDetails;

  return branchDetails ? branchDetails : defaultDetails;
}

export function getClientDetails(clientId) {
  return clients[clientId]
    ? clients[clientId]
    : { id: clientId, name: 'NotFound', clientName: 'NotFound' };
}

export function branchSelect(clients) {
  return Object.values(clients).map(({ name, branches }) => ({
    label: name,
    options: branches.map(({ id, name }) => ({ label: name, value: id })),
  }));
}

export function clientSelect(clients) {
  return Object.values(clients).map(({ name, id }) => ({
    label: name,
    value: id,
  }));
}

export function principalSelect(principals) {
  return principals.map(({ name, id }) => ({
    label: name,
    value: id,
  }));
}

export function getWorkingDate() {
  if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_DEFAULT_DATE)
    return Date.parse(process.env.REACT_APP_DEFAULT_DATE);

  const curDate = new Date();
  return getHours(curDate) < 12 ? curDate : addDays(curDate, isSaturday(curDate) ? 2 : 1);
}

export const remove = (array, value, key) => {
  const index = array.findIndex(obj => (key ? obj[key] === value : obj === value));
  return removeByIndex(array, index);
};

export const removeByIndex = (array, index) =>
  index >= 0 ? [...array.slice(0, index), ...array.slice(index + 1)] : array;

export const arrayToObject = (arr, id = 'id') =>
  Object.assign({}, ...arr.map(item => ({ [item[id]]: item })));

export const isVal = val => !(val === undefined || val === null);
export const isEmpty = obj =>
  obj ? Object.keys(obj).length === 0 && obj.constructor === Object : false;

export const formatVolume = volume => numeral(volume / 1000).format('0.00');
export const formatWeight = weight => numeral(weight).format('0.00');
export const formatCurrency = value => `₹${numeral(value).format('0,0')}`;
export const formatCurrencyNum = value => `${numeral(value).format('0,0')}`;
export const format = value => numeral(value).format('0.00');
export const format0 = value => Math.trunc(value);
export const format1 = value => numeral(value).format('0.0');
export const formatDistance = dist => numeral(dist / 1000).format('0.0');
export const formatDate = date => formatDateFns(date, 'yyyy-MM-dd');

export const formatTime = sec =>
  sec
    ? `${Math.trunc(sec / (60 * 60))}h ${numeral(Math.trunc((sec % (60 * 60)) / 60)).format('00')}m`
    : '--';

export const formatTimeMilitary = sec =>
  sec
    ? `${numeral(Math.trunc(sec / (60 * 60))).format('00')}:${numeral(
        Math.trunc((sec % (60 * 60)) / 60)
      ).format('00')}`
    : '--';

export const formatTimeMinSec = sec =>
  sec ? `${Math.trunc(sec / 60)}:${numeral(Math.trunc(sec % 60)).format('00')}` : '--';

export const debugPropChange = (prevProp, nextProp) => {
  Object.entries(nextProp).forEach(([key, value]) => {
    if (prevProp[key] !== value) {
      console.log(`${key}: ${prevProp[key]} -> ${value}`);
      return false;
    }
  });
  return false;
};

export const byId = (arr, id, key = 'id') => arr?.find(e => e && e[key] === id);

export const formatTimeSec = sec =>
  isVal(sec)
    ? `${Math.trunc(sec / (60 * 60))}h ${numeral(Math.trunc((sec % (60 * 60)) / 60)).format(
        '00'
      )}m ${Math.trunc(sec % 60)}s`
    : '--';
