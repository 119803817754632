import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import { navigate } from '@reach/router';
import ConfirmationDialog from 'components/ConfirmationDialog';
import produce from 'immer';
import React, { useEffect, useRef, useState } from 'react';
import { getPlan, postPlan } from 'services/planService';
import { useGlobalSetStatev1 } from 'state/globalContextv1';
import { planStates as ps } from 'utils/constants';
import { useComputePlanDetails, useFetchOnAction, useFetchv1 } from 'utils/customHooks';
import { vehicleParamsLabel as vpl } from 'utils/constants';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  updateButton: {
    marginTop: theme.spacing(2),
  },
  vehicleSummary: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  // headingContainer: {
  //   maxWidth: 105,
  // },
  heading: {
    // fontSize: theme.typography.pxToRem(15),
    maxWidth: 105,
    // flexShrink: 0,
    paddingLeft: theme.spacing(1),
  },
  deleteIconButton: {
    marginLeft: -theme.spacing(2),
    padding: theme.spacing(1),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
  },
  vehicle: {
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  vehicleDetails: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
  },
}));

export default function PlanningSidebar({ id }) {
  const c = useStyles();
  const setSideBarTitle = useGlobalSetStatev1('sideBarTitle');
  const [planData, loading, error] = useFetchv1(getPlan(id), [id], null);
  const plan = useComputePlanDetails(planData);
  const setLoading = useGlobalSetStatev1('loading');
  const [params, setParams] = useState([]);
  const [expanded, setExpanded] = useState(-1);
  const [enableUpdate, setEnableUpdate] = useState(false);
  const [updatePlanParams, updateResp, updateLoading] = useFetchOnAction(postPlan(id), [], null);
  const dialog = useRef();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!updateResp) return;
    setEnableUpdate(false);
    setExpanded(-1);
    navigate(`/plan/${updateResp.id}`, { replace: true });
  }, [updateResp]);

  useEffect(() => {
    if (!plan) return;

    // const { name: branchName, clientName } = getBranchDetails(plan.branchId);

    setSideBarTitle({
      type: 'plan',
      name: plan.name || 'Default',
      branchName: plan.branchName,
      clientName: 'Not_Found',
    });

    if (plan.status === ps.FROZEN) setDisabled(true);
    else setDisabled(false);

    setParams(
      plan.vehicleTypes.map(vehicleType => {
        delete vehicleType.id;
        return vehicleType;
      })
    );
    // eslint-disable-next-line
  }, [plan]);

  setLoading(loading || updateLoading);

  const processVehicleTypes = vehicleTypes => {
    const newVehicleTypes = vehicleTypes.map(vehicleType => {
      return Object.entries(vehicleType).reduce((acc, [key, value]) => {
        if (['name', 'isSplitCompatible'].includes(key)) {
          acc[key] = value;
          return acc;
        }
        acc[key] = parseFloat(value);
        return acc;
      }, {});
    });
    return newVehicleTypes;
  };

  function addVehicle() {
    setParams(
      produce(param => {
        param.push({
          name: 'Name',
          fixedCost: '',
          maxDropPoints: '',
          maxTripTime: '',
          volumeLimitRatio: '',
          maxVolume: '',
          maxWeight: '',
          vehicleCount: 1,
          avgSpeed: '',
        });
      })
    );
    setEnableUpdate(true);
  }

  const handleChange = (index, key) => e => {
    const val =
      key === 'isSplitCompatible' ? e.target.checked : reverseUnitConversion(key, e.target.value);
    setParams(
      produce(param => {
        param[index][key] = val;
      })
    );
    setEnableUpdate(true);
  };

  const handleDelete = index => e => {
    e.stopPropagation();
    dialog.current.openDialog(index);
  };

  const deleteVehicle = index => {
    setEnableUpdate(true);
    if (expanded === index) setExpanded(-1);
    else if (expanded > index) setExpanded(expanded - 1);
    setParams([...params.slice(0, index), ...params.slice(index + 1)]);
  };

  //To convert speed and time unit(km/h and hours)
  const unitConversion = (key, value) =>
    key === 'maxTripTime'
      ? Math.round((value / 3600) * 100) / 100
      : key === 'avgSpeed'
      ? Math.round((value / 5) * 18 * 10) / 10
      : key === 'volumeLimitRatio'
      ? Math.round(value * 100 * 10) / 10
      : key === 'loadingTime' || key === 'serviceTime'
      ? value / 60
      : key === 'maxDistance'
      ? value / 1000
      : value;

  //To re-convert speed and time unit(from km/h and hours to m/s and s)
  const reverseUnitConversion = (key, value) =>
    key === 'maxTripTime'
      ? value * 3600
      : key === 'avgSpeed'
      ? (value / 18) * 5
      : key === 'volumeLimitRatio'
      ? value / 100
      : key === 'loadingTime' || key === 'serviceTime'
      ? value * 60
      : key === 'maxDistance'
      ? value * 1000
      : value;

  const renderVehicleParameter = (vehicle, index) => {
    const { name, vehicleCount } = vehicle;

    return (
      <ExpansionPanel
        expanded={expanded === index}
        onChange={() => setExpanded(expanded === index ? -1 : index)}
        className={c.vehicle}
      >
        <ExpansionPanelSummary
          classes={{ content: c.vehicleSummary }}
          expandIcon={<ExpandMoreIcon />}
        >
          <IconButton
            className={c.deleteIconButton}
            disabled={disabled}
            onClick={handleDelete(index)}
          >
            <DeleteIcon />
          </IconButton>
          <Grid container wrap="nowrap" justify="space-between" alignItems="center">
            <Grid item zeroMinWidth className={c.headingContainer}>
              <Typography noWrap variant="caption" className={c.heading}>
                {name}
              </Typography>
            </Grid>
            <Grid item zeroMinWidth>
              <Typography className={c.secondaryHeading}>{vehicleCount}</Typography>
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={c.vehicleDetails}>
          {Object.entries(vehicle)
            .filter(([key]) => !['id'].includes(key))
            .map(([key, value]) =>
              key === 'isSplitCompatible' ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(value)}
                      onChange={handleChange(index, key)}
                      color="primary"
                    />
                  }
                  label={vpl[key]}
                  key={key}
                />
              ) : (
                <TextField
                  key={key}
                  label={vpl[key]}
                  value={unitConversion(key, value)}
                  type={key === 'name' ? 'text' : 'number'}
                  disabled={disabled}
                  onChange={handleChange(index, key)}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                />
              )
            )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  return (
    <div className={c.root}>
      {plan && (
        <>
          <ConfirmationDialog title="Delete Vehicle?" ref={dialog} onYes={deleteVehicle} />
          {params &&
            params.map((vehicle, index) => (
              <div key={index}>{renderVehicleParameter(vehicle, index)}</div>
            ))}
          <Button
            fullWidth
            color="primary"
            disabled={disabled}
            onClick={addVehicle}
            className={c.addButton}
          >
            Add Vehicle
          </Button>
          <br />
          <Button
            variant="outlined"
            fullWidth
            color="primary"
            onClick={() => updatePlanParams({ vehicleTypes: processVehicleTypes(params) })}
            disabled={disabled || !enableUpdate}
            className={c.updateButton}
          >
            Update
          </Button>
        </>
      )}
      {error && <Typography>Plan Not Found</Typography>}
    </div>
  );
}
