import { Box, Button, Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import produce from 'immer';
import React, { memo, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import Select from 'react-select';
import { ga, useGState } from 'state/store';
import { api } from 'utils/fetch';
import { byId } from 'utils/helperFunctions';

export default function NodeSelector({ initialValue, onChange, autoSubmit }) {
  const principals = useGState(s => s[ga.PRINCIPALS]);
  const [nodes, setNodes] = useState([]);
  const [selectedNode, setSelectedNode] = useState(initialValue);
  const [isNodeSelected, setIsNodeSelected] = useState(false);
  const rootOptions = useNodeOptions(principals);
  const didMountRef = useRef(false);

  const getParentNodes = useMutation(
    ['parent nodes', selectedNode],
    () =>
      api({
        method: 'POST',
        url: '/core/node/query',
        data: {
          ids: [selectedNode],
          parents: true,
        },
      }),
    {
      onSuccess: data => {
        const nodeArray = [...nodes];
        for (let k in data) {
          if (data[k].id !== 0 && data[k].parentId === 0 && !nodeArray.includes(data[k].id)) {
            nodeArray.push(data[k].id);
            setNodes(nodeArray.filter(item => item !== 0));
          } else {
            for (let l in data) {
              if (data[l].parentId === data[k].id && !nodeArray.includes(data[l].id)) {
                nodeArray.push(data[l].id);
                setNodes(nodeArray.filter(item => item !== 0));
              }
            }
          }
        }
      },
    }
  );

  useEffect(() => {
    if (!didMountRef.current) {
      getParentNodes.mutate({ ids: [selectedNode], parents: true });
    }
    didMountRef.current = true;
  }, [getParentNodes, selectedNode]);

  const onNodeSubmit = useCallback(
    nodeId => {
      onChange(nodeId);
      setSelectedNode(nodeId);
      setIsNodeSelected(true);
    },
    [onChange]
  );
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1} mr={1} mb={2}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={3}>
              <Select
                options={rootOptions}
                value={rootOptions.filter(({ value }) => value === nodes[0])[0]}
                onChange={({ value }) => {
                  setNodes([value]);
                  setIsNodeSelected(false);
                }}
                placeholder="Select Principal ..."
                menuPortalTarget={document.getElementById('portal-target')}
              />
            </Grid>
            {nodes.map((nodeId, index, nodes) => (
              <ChildNodeSelector
                key={nodeId + index}
                parentId={nodeId}
                nodesList={nodes}
                onChange={({ value }) => {
                  setNodes(
                    produce(nodes, draft => {
                      draft[index + 1] = value;
                      draft.splice(index + 2);
                    })
                  );
                  setIsNodeSelected(false);
                  if (autoSubmit === true && nodes.length >= 1) {
                    onChange(value);
                    setSelectedNode(value);
                    setIsNodeSelected(true);
                  }
                }}
                onSelectFinal={onNodeSubmit}
              />
            ))}
          </Grid>
        </Box>
        <Box w={90}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => onNodeSubmit(nodes[nodes.length - 1])}
            disabled={!nodes.length || isNodeSelected}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Box mb={2}>{selectedNode && <NodeHierarchy nodeId={Number(selectedNode)} />}</Box>
    </>
  );
}
export function NodeHierarchy({ nodeId }) {
  const { data } = useQuery(['nodeHierarchy', nodeId], () =>
    api({
      method: 'POST',
      url: '/core/node/query',
      data: {
        ids: [nodeId],
        parents: true,
      },
    })
  );
  const nodes = useComputeNodeHierarchy(data, nodeId);
  if (!nodes || nodes.length === 0 || data.length === 0 || Number.isNaN(nodeId)) return null;

  return (
    <Box
      p={1}
      px={2}
      bgcolor="background.paper"
      display="inline-block"
      borderRadius={8}
      borderColor="grey.500"
      border={1}
    >
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {nodes.map(n => (
          <Typography key={n.id} color={n.id === nodeId ? 'textPrimary' : 'inherit'}>
            {n.name}
          </Typography>
        ))}
      </Breadcrumbs>
    </Box>
  );
}
function useComputeNodeHierarchy(nodes, nodeId) {
  return useMemo(() => {
    if (!nodes) return [];
    const sNodes = nodes.filter(n => n.id);
    let node = byId(sNodes, nodeId);
    let hierarchy = [node];
    while (node?.id) {
      node = byId(sNodes, node.parentId);
      if (node) {
        hierarchy = [node, ...hierarchy];
      }
    }
    return hierarchy;
  }, [nodes, nodeId]);
}
const ChildNodeSelector = memo(function ChildNodeSelector({
  parentId,
  nodesList,
  onChange,
  onSelectFinal,
}) {
  const { data: nodes } = useQuery(
    ['childNodes', parentId],
    () =>
      api({
        method: 'POST',
        url: '/core/node/query',
        data: {
          ids: [parentId],
          children: true,
          maxDistance: 1,
        },
      }),
    {
      onSuccess: resp => {
        if (resp.length === 1) {
          onSelectFinal(parentId);
        }
      },
    }
  );
  const nodesOptions = useNodeOptions(nodes, parentId);

  function findCommonElements(arr1, arr2) {
    for (let i = 0; i < arr1.length; i++) {
      let item = arr1[i].value;
      for (let j = 0; j < arr2.length; j++) {
        if (arr2[j] === item) {
          return item;
        }
      }
    }
  }

  return (
    nodesOptions.length > 0 && (
      <Grid item xs={3}>
        <Select
          options={nodesOptions}
          value={nodesOptions.filter(
            ({ value }) => value === findCommonElements(nodesOptions, nodesList)
          )}
          placeholder="Select Node ..."
          onChange={onChange}
          menuPortalTarget={document.getElementById('portal-target')}
        />
      </Grid>
    )
  );
});
export function useNodeOptions(nodes, parentId) {
  return useMemo(
    () =>
      nodes ? nodes.filter(n => n.id !== parentId).map(n => ({ value: n.id, label: n.name })) : [],
    [nodes, parentId]
  );
}
