import React, { useReducer, useState, memo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { navigate } from '@reach/router';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/CloseRounded';
import MenuIcon from '@material-ui/icons/MenuRounded';
import SettingsIcon from '@material-ui/icons/SettingsRounded';
import ProfileIcon from '@material-ui/icons/PermIdentityRounded';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { logout } from 'utils/persistentStore';
import { defaults } from './PageLayout';
import { useGDispatch, ga, useGState } from 'state/store';
import deepOrange from '@material-ui/core/colors/deepOrange';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
  },
  typography: {
    useNextVariants: true,
  },
});

const useStyles = makeStyles(theme => ({
  drawer: {
    position: 'absolute',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerClose: {
    border: 'none',
    // backgroundColor: '#424242',
    // transition: theme.transitions.create('width', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // backgroundColor: theme.palette.secondary.main,
    overflowX: 'hidden',
    width: defaults.ACTIVITYBAR_WIDTH,
  },
  bottomMenu: {
    position: 'absolute',
    bottom: 0,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: process.env.REACT_APP_IS_STAGING ? deepOrange[500] : '#212121',
    padding: 8,
    ...theme.mixins.toolbar,
  },
  sidebarToggleButton: {
    padding: 8,
    color: 'white',
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: theme.spacing(2),
  },
  icons: {
    margin: 5.5,
    color: '#c5cae9',
  },
  avatar: {
    padding: 13.5,
  },
  selectedIcon: {
    color: '#3f51b5',
  },
  selected: {
    backgroundColor: '#eeeeee',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  dateListItem: {
    // padding: theme.spacing(1),
    padding: 4,
  },
  datePickerContainer: {
    width: 59,
    height: 51,
  },
  datePicker: {
    width: 59,
    height: 51,
  },
}));

function ActivityBar(props) {
  const classes = useStyles(props.defaults);
  const [anchorElProfile, setAnchorElProfile] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const {
    date: { date },
    user,
  } = useGState(state => ({ date: state.date, user: state.user }));
  const filtersDisabled = useGState(state => state.filters.disabled);
  const { routes, open, setOpen } = props;
  const gDispatch = useGDispatch();

  function handleProfileClick(event) {
    setAnchorElProfile(event.currentTarget);
  }

  function handleSettingsClick(event) {
    setAnchorElSettings(event.currentTarget);
  }

  function handleProfileMenuClose() {
    setAnchorElProfile(null);
  }

  function handleSettingsMenuClose() {
    setAnchorElSettings(null);
  }

  function logoutUser() {
    setAnchorElProfile(null);
    gDispatch([ga.LOGOUT]);
    logout();
  }

  function handleDrawerToggle() {
    setOpen(!open);
  }

  const changeRoute = path => () => {
    forceUpdate();
    navigate(path);
  };

  const isSelected = path => {
    if (window.location.pathname === '/' && path === '/pending') return true;
    const n = path.indexOf(':');
    return window.location.pathname.startsWith(path.substring(0, n !== -1 ? n : path.length));
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Drawer
        variant="permanent"
        className={classNames(classes.drawer, classes.drawerClose)}
        classes={{
          paper: classNames(classes.drawerClose),
        }}
        open={false}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerToggle} className={classes.sidebarToggleButton}>
            {open ? <Close fontSize="large" /> : <MenuIcon fontSize="large" />}
          </IconButton>
        </div>
        {/* <Divider /> */}
        <List className={classes.list}>
          {routes
            .filter(({ Icon }) => Icon)
            .map(({ path, Icon, title }) => (
              <Tooltip key={path} title={title} enterDelay={1000} placement="right">
                <ListItem
                  className={classNames(classes.listItem, { [classes.selected]: isSelected(path) })}
                  onClick={changeRoute(path)}
                  button
                >
                  <ListItemIcon>
                    <Icon.type
                      {...Icon.props}
                      className={classNames(classes.icons, {
                        [classes.selectedIcon]: isSelected(path),
                      })}
                    />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            ))}
        </List>
        <List className={classes.bottomMenu}>
          <ListItem
            classes={{
              root: classes.dateListItem,
            }}
            button
            key={'date'}
          >
            <div className={classes.datePickerContainer}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                marginDense
                classes={{ root: classes.datePicker }}
              >
                <DatePicker
                  variant="inline"
                  inputVariant="outlined"
                  default={new Date()}
                  format={'do'}
                  autoOk
                  value={date}
                  onChange={newDate => {
                    gDispatch({ type: ga.DATE, date: newDate });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </ListItem>

          <ListItem
            className={classes.listItem}
            onClick={handleSettingsClick}
            button
            key={'settings'}
          >
            <ListItemIcon>
              <SettingsIcon className={classes.icons} />
            </ListItemIcon>
          </ListItem>
          <ListItem
            className={user && user.picture ? classes.avatar : classes.listItem}
            button
            onClick={handleProfileClick}
            key={'profile'}
          >
            {user && user.picture ? (
              <ListItemAvatar>
                <Avatar alt={user.name} src={user.picture} />
              </ListItemAvatar>
            ) : (
              <ListItemIcon>
                <ProfileIcon className={classes.icons} />
              </ListItemIcon>
            )}
          </ListItem>
        </List>
      </Drawer>
      <Menu
        id="simple-menu"
        anchorEl={anchorElSettings}
        open={Boolean(anchorElSettings)}
        onClose={handleSettingsMenuClose}
      >
        <MenuItem>
          <FormControlLabel
            control={
              <Checkbox
                checked={!filtersDisabled}
                onChange={() =>
                  gDispatch({
                    type: ga.FILTERS,
                    value: { disabled: !filtersDisabled },
                  })
                }
              />
            }
            label="Filters"
          />
        </MenuItem>
      </Menu>
      <Menu
        id="simple-menu"
        anchorEl={anchorElProfile}
        open={Boolean(anchorElProfile)}
        onClose={handleProfileMenuClose}
      >
        <MenuItem onClick={logoutUser}>Logout</MenuItem>
      </Menu>
    </MuiThemeProvider>
  );
}

export default memo(ActivityBar);
