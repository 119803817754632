import React from 'react';
import Typography from '@material-ui/core/Typography';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const dataLabel = {
  maxWidth: '85%',
  color: '#999',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const GroupSelectLabel = data => (
  <div style={groupStyles}>
    {/* <span style={dataLabel}>{data.label}</span> */}
    <Typography noWrap style={dataLabel}>
      {data.label}
    </Typography>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export default GroupSelectLabel;
