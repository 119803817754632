import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useGlobalSetStatev1 } from 'state/globalContextv1';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import GroupSelectLabel from 'components/GroupSelectLabel';
import { useGState, useGDispatch, ga } from 'state/store';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const animatedComponent = makeAnimated();

const useStyles = makeStyles(theme => ({
  heading: {
    paddingTop: theme.spacing(2),
  },
  filterHeading: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  datePicker: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}));

const UPDATE_FILTER = gql`
  mutation updateUserFilters($id: ID!, $filters: UserBranchFilterInput) {
    updateUserFilters(id: $id, filters: $filters) {
      filters {
        ci
        bi
      }
    }
  }
`;

export default function PlanFilter({ done }) {
  const c = useStyles();
  const setSideBarTitle = useGlobalSetStatev1('sideBarTitle');

  const [updateFilter] = useMutation(UPDATE_FILTER, {
    update: (_, { data }) => {
      gDispatch({ type: ga.FILTERS, value: data.updateUserFilters });
    },
  });

  const gDispatch = useGDispatch();

  const { clients, filter, disabled, user } = useGState(state => ({
    clients: state.clients,
    filter: { ci: state.filters.ci, bi: state.filters.bi },
    disabled: state.filters.disabled,
    user: state.user,
  }));

  const [state, setState] = useState({
    options: {
      ci: [],
      bi: [],
    },
    disables: { be: false },
  });

  //Filtering logic
  useEffect(() => {
    if (!clients) return;
    const convertToSelectFormat = ({ id: value, name: label }) => ({ value, label });

    const clientOptions = Object.values(clients).map(convertToSelectFormat);
    const branchOptions = Object.values(clients)
      .filter(({ id }) => (filter.ci.length ? filter.ci.includes(id) : true))
      .map(({ name, branches }) => ({
        label: name,
        options: branches.map(convertToSelectFormat),
      }));

    setState({
      options: {
        ci: clientOptions,
        bi: branchOptions,
      },
    });
  }, [clients, filter]);

  setSideBarTitle(done ? 'Frozen Plans' : 'Pending Plans');

  const handleChange = type => values => {
    const tmp = { ...filter, [type]: values.map(val => val.value) };
    updateFilter({ variables: { id: user.pUser.id, filters: tmp } });
    gDispatch({ type: ga.FILTERS, value: tmp });
  };

  const renderSelect = (label, type) => {
    let value =
      type[0] === 'c'
        ? state.options[type].filter(({ value }) => filter[type].includes(value))
        : state.options[type]
            .flatMap(({ options }) => options)
            .filter(({ value }) => filter[type].includes(value));

    return (
      <>
        <Typography variant="body1" align="left" className={c.filterHeading}>
          {label}
        </Typography>
        <Select
          closeMenuOnSelect={false}
          components={animatedComponent}
          formatGroupLabel={GroupSelectLabel}
          placeholder=""
          isMulti
          value={value}
          isDisabled={disabled}
          onChange={handleChange(type)}
          options={state.options[type]}
        />
      </>
    );
  };

  return (
    <div>
      <Typography variant="h5" align="center" color="textSecondary">
        Filters
      </Typography>
      <Divider />
      <Typography variant="h6" color="textSecondary" className={c.heading}>
        Client
      </Typography>
      {renderSelect('Include', 'ci')}
      <Typography variant="h6" color="textSecondary" className={c.heading}>
        Branch
      </Typography>
      {renderSelect('Include', 'bi')}
      {/* {renderSelect('Exclude', 'be')} */}
    </div>
  );
}
