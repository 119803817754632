import firebase from 'firebase/app';
import 'firebase/messaging';

let firebaseInitialized = false;

export const initializeFirebase = () => {
  if (firebaseInitialized) return;
  firebase.initializeApp({
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  });
  firebaseInitialized = true;
};

export const provideSW = registration => {
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_ID);
  messaging.useServiceWorker(registration);
};

// const enableNotifications = messaging => {
//   messaging.onMessage(function(payload) {
//     console.log('New message recieved ', payload);
//   });
// };

export const askForPermissionToReceiveNotifications = async registerToken => {
  let messaging;
  initializeFirebase();
  try {
    messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    registerToken(token);
    messaging.onTokenRefresh(async () => {
      const token = await messaging.getToken();
      registerToken(token);
    });
  } catch (error) {
    console.error(error);
  }
};
