import React, { useState, useEffect } from 'react';
import ScrollMenu from 'react-horizontal-scroll-menu';
import { navigate } from '@reach/router';

export default function PlanMenu({ id, plansList }) {
  const selectedPlan = plansList ? plansList.filter(x => x.planId === Number(id)) : [];
  const selectedBranchId = selectedPlan ? selectedPlan.map(x => x.branchId)[0] : [];
  const plans = plansList ? plansList.filter(x => x.branchId === Number(selectedBranchId)) : [];

  let planObject = {
    planId: undefined,
    profileName: ' ',
  };

  const plansInfoTemp = [];

  plans.forEach(item => {
    let profileName = !item.profileName ? 'Default' : item.profileName;
    planObject = {
      planId: item.planId,
      profileName: profileName,
    };
    plansInfoTemp.push(planObject);
  });

  const plansInfo = plansInfoTemp.sort((a, b) => Number(a.planId) - Number(b.planId));

  const MenuItem = ({ text, selected }) => {
    return <div className={`menu-item-plan-polishing ${selected ? 'active' : ''}`}>{text}</div>;
  };

  const Menu = (plansInfo, selected) =>
    plansInfo.map(({ planId, profileName }) => (
      <MenuItem text={`${planId}/ ${profileName}`} key={planId} selected={selected} />
    ));

  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>;
  };

  const ArrowLeft = Arrow({ text: '<' });
  const ArrowRight = Arrow({ text: '>' });

  const [selected, setSelected] = useState(id);
  
  useEffect(()=>{
    navigate(`/plan/${selected}`);
  },[selected]);

  const onSelect = key => {
    setSelected(key);
  };

  return (
    <ScrollMenu
      data={Menu(plansInfo, selected)}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      selected={selected}
      onSelect={onSelect}
    />
  );
}
