import React, { memo, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { api } from 'utils/fetch';
import { useSnackbar } from 'utils/customHooks';

export const AccountSelector = memo(function AccountSelector({
  nodeId,
  roleId,
  value,
  onChange,
  setValue,
}) {
  const [notif] = useSnackbar();
  const { data: accounts = [] } = useQuery(
    ['accounts', nodeId, roleId],
    () =>
      api({
        method: 'POST',
        url: '/core/account/query',
        data: {
          roleId: roleId,
          nodeId: nodeId,
        },
      }),
    {
      onSuccess: resp => {
        if (!resp.length) {
          notif(`Account not found`, { variant: 'info' });
        }
      },
    }
  );
  const accountOptions = useAccountOptions(accounts);
  const storedAccount = accountOptions.find(({ value: sValue }) => sValue === value);

  useEffect(() => {
    if (value && accountOptions.find(({ value: sValue }) => sValue === value)) {
      return;
    }
    if (accountOptions.length === 0) {
      onChange({});
    }
    if (accountOptions.length) {
      onChange(accountOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountOptions, value]);

  setValue(storedAccount ? storedAccount.loginId : undefined);

  return (
    accountOptions.length > 0 && (
      <Select
        options={accountOptions}
        value={accountOptions.find(({ value: sValue }) => sValue === value)}
        placeholder="Select Account ..."
        onChange={onChange}
        menuPortalTarget={document.getElementById('portal-target')}
      />
    )
  );
});

function useAccountOptions(accounts) {
  return useMemo(
    () =>
      accounts
        ? accounts.map(a => ({
            value: a.id.toString(),
            label: a.name || a.loginId,
            loginId: a.loginId,
          }))
        : [],
    [accounts]
  );
}
