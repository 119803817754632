import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import JsonEditor from 'components/JsonEditor';
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { sortBy } from 'lodash';
import { useMutation } from 'react-query';
import { saveConfig } from 'services/configService';
import { useGState } from 'state/store';
import { useDerivedState } from 'utils/customHooks';
import { api } from 'utils/fetch';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  name: {
    margin: theme.spacing(1),
  },
}));

// const superAdminUsers = ['abhishek', 'saby', 'vikas', 'chaitanya', 'sourabh', 'prakhar'];

export default function Config({ nodeId, details: { data, settings }, refetch }) {
  const classes = useStyles();
  const [diff, setDiff] = useDerivedState(data);
  const [isDirty, setIsDirty] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const user = useGState(s => s.user);

  const { mutate: save } = useMutation(() => api(saveConfig(nodeId, diff, secure)), {
    onSuccess: () => {
      setIsDirty(false);
      refetch();
    },
  });

  function onChange(val) {
    setIsDirty(true);
    setDiff(val);
  }

  // const secure =
  //   user &&
  //   (superAdminUsers.map(id => `${id}@stackbox.xyz`).includes(user.email) ||
  //     superAdminUsers.map(id => `${id}@stackbox.co.in`).includes(user.email));

  const secure = user && user.permissions.includes('Admin');

  const fullSettingsDialog = (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      scroll="body"
      fullWidth
      maxWidth="md"
      aria-labelledby="scroll-dialog-title"
    >
      {/* <DialogTitle id="scroll-dialog-title">{entityDetail && entityDetail.name}</DialogTitle> */}
      <DialogContent>
        <ReactJson src={settings} displayDataTypes={false} />
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      {settings && fullSettingsDialog}
      <Grid container direction="row" justify="center" alignItems="center" className={classes.root}>
        <Grid item xs={3}>
          {/* <Typography className={classes.name}>{entityDetail && entityDetail.name}</Typography> */}
          <Button
            variant="outlined"
            className={classes.button}
            disabled={!settings}
            onClick={() => setDialogOpen(true)}
          >
            View Full Settings
          </Button>

          <Button
            variant="outlined"
            color="primary"
            disabled={!isDirty}
            className={classes.button}
            onClick={save}
          >
            SAVE
          </Button>
        </Grid>
        <Grid item xs={9}>
          <JsonEditor initialValue={data} onChange={onChange} height={600}></JsonEditor>
        </Grid>
      </Grid>
    </>
  );
}
