// eslint-disable-next-line no-unused-vars
export const debug = (...msg) => {
  // console.log(...msg);
};

export const twoOpt = (path, pathPool) => {
  let a = getRandomIndex(1, path.length - 1);
  let b = getRandomIndex(1, path.length - 1);
  const newpath = pathPool.get();
  if (a === b) {
    for (let i = 0; i < path.length; i++) {
      newpath[i] = path[i];
    }
    return newpath;
  }
  if (a > b) {
    const t = a;
    a = b;
    b = t;
  }

  let i = 0,
    j = 0;
  for (; i < a; i++, j++) {
    newpath[j] = path[i];
  }
  for (i = b; i >= a; i--, j++) {
    newpath[j] = path[i];
  }
  for (i = b + 1; i < path.length; i++, j++) {
    newpath[j] = path[i];
  }

  return newpath;
};

export const translation = (path, pathPool) => {
  let a = getRandomIndex(1, path.length - 1);
  let b = getRandomIndex(1, path.length - 1);
  const newpath = pathPool.get();
  if (a === b) {
    for (let i = 0; i < path.length; i++) {
      newpath[i] = path[i];
    }
    return newpath;
  }
  if (a > b) {
    const t = a;
    a = b;
    b = t;
  }

  let i = 0,
    j = 0;
  for (; i < a; i++, j++) {
    newpath[j] = path[i];
  }
  newpath[j++] = path[b];
  for (i = a; i < b; i++, j++) {
    newpath[j] = path[i];
  }
  for (i = b + 1; i < path.length; i++, j++) {
    newpath[j] = path[i];
  }

  return newpath;
};

export const switching = (path, pathPool) => {
  const a = getRandomIndex(1, path.length - 1);
  const b = getRandomIndex(1, path.length - 1);

  const newpath = pathPool.get();
  for (let i = 0; i < path.length; i++) {
    newpath[i] = path[i];
  }
  newpath[b] = path[a];
  newpath[a] = path[b];

  return newpath;
};

export const getRandomIndex = (start, end) => {
  return start + Math.floor(Math.random() * (end - start));
};

export const createNewPath = (path, pathPool) => {
  const roll = Math.random();

  const probTwoOpt = 0.33;
  const probTranslation = 0.33;

  if (roll < probTwoOpt) {
    return twoOpt(path, pathPool);
  } else if (roll < probTwoOpt + probTranslation) {
    return translation(path, pathPool);
  } else {
    return switching(path, pathPool);
  }
};

export class FixedLengthArrayPool {
  constructor(length) {
    this.arrayLength = length;
    this.pool = [];
    this.allocCount = 0;
  }

  create() {
    const arr = [];
    arr.length = this.arrayLength;
    return arr;
  }

  init(poolSize) {
    for (let i = 0; i < poolSize; i++) {
      this.pool.push(this.create());
    }
  }

  get() {
    if (this.pool.length === 0) {
      debug('Allocating new array!', ++this.allocCount);
      return this.create();
    } else {
      return this.pool.pop();
    }
  }

  reclaim(arr) {
    if (arr.length !== this.arrayLength) {
      throw new Error(`This pool only expects arrays of length ${this.arrayLength}`);
    }
    if (this.pool.indexOf(arr) === -1) {
      this.pool.push(arr);
    }
  }
}

export const isStaging = () => process.env.REACT_APP_IS_STAGING === 'true';
