const getNearest = (N, src, toVisit, calcCost) => {
  let nearest = null,
    minCost;
  for (let i = 0; i < N; i++) {
    if (toVisit[i]) {
      const cost = calcCost(src, i);
      if (nearest === null || cost < minCost) {
        nearest = i;
        minCost = cost;
      }
    }
  }
  return nearest;
};

const nearestN = (N, calcCost, pool) => {
  const path = pool.get();

  const toVisit = [];
  for (let i = 0; i < N; i++) {
    toVisit[i] = true;
  }
  let toVisitCount = N;

  let pi = 0;

  path[pi++] = 0;
  toVisit[0] = false;
  toVisitCount--;

  let cur = 0;
  while (toVisitCount > 0) {
    const nearest = getNearest(N, cur, toVisit, calcCost);

    path[pi++] = nearest;
    toVisit[nearest] = false;
    toVisitCount--;

    cur = nearest;
  }
  path[pi++] = 0;
  return path;
};

export default nearestN;
