import React, { Component } from 'react';

class Spinner extends Component {
  //other logic
  render() {
    if (this.props.isLoading) {
      return (
        <div
          style={{
            zIndex: 10,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <img
            src="https://www.stackbox.xyz/wp-content/uploads/2020/02/loader.gif"
            height="50px"
            width="175px"
            alt="StackBox Logo"
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default Spinner;
