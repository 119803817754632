import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import UpdateRounded from '@material-ui/icons/UpdateRounded';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const UPDATE_PERMISSIONS = gql`
  mutation updatePermissions($userId: ID!, $updatedPermissions: [String!]!) {
    updatePermissions(id: $userId, permissions: $updatedPermissions) {
      id
      name
      email
      permissions
    }
  }
`;
function getModalStyle() {
  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    alignItems: 'center',
  };
}
const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    paddingBottom: '10px',
  },
}));

export function PUModal({ user, onCloseFunction }) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(true);
  const [updatedPermission, setUpdatedPermission] = useState();
  const [updateButton, setUpdateButton] = useState(false);

  const onModalClose = useCallback(() => {
    setOpen(false);
    onCloseFunction();
  }, [onCloseFunction]);

  const showUpdateButton = useCallback(() => {
    setUpdateButton(true);
  }, []);

  const hideUpdateButton = useCallback(() => {
    setUpdateButton(false);
  }, []);

  const animatedComponents = makeAnimated();
  const PermissionOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Coverage Expansion', label: 'Coverage Expansion' },
    { value: 'Operations', label: 'Operations' },
    { value: 'PL Access', label: 'PL Access' },
    { value: 'Settings', label: 'Settings' },
  ];
  const [updateUserPermission] = useMutation(UPDATE_PERMISSIONS, {
    variables: {
      userId: user.id,
      updatedPermissions: updatedPermission && updatedPermission.map(p => p.value).sort(),
    },
  });

  useEffect(() => {
    if (
      updatedPermission &&
      JSON.stringify(updatedPermission.map(p => p.value).sort()) ===
        JSON.stringify(user.permissions)
    ) {
      hideUpdateButton();
    }
  }, [hideUpdateButton, updatedPermission, user.permissions]);

  return (
    <Modal
      labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onModalClose}
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="modal_header">
          <h2>User Permissions</h2>
        </div>
        <Box className="modal_body">
          <div className="content_box">
            <h3 style={{ display: 'inline-flex' }}>
              <div style={{ color: '#f50057' }}>Name: &ensp;</div>
              <div>{user.name}</div>
            </h3>
          </div>
          <div className="content_box">
            <h3 style={{ display: 'inline-flex' }}>
              <div style={{ color: '#f50057' }}>Email: &ensp;</div>
              <div>{user.email}</div>
            </h3>
          </div>
          {/* <div className='content_box'>
                    <h3 style={{display:'inline-flex'}}><div style={{color:'#f50057'}}>User Id: &ensp;</div><div>{user.id}</div></h3>
                </div> */}

          <div className="permission_options_container">
            <Box className="permission_options">
              <Select
                isMulti
                options={PermissionOptions}
                defaultValue={user.permissions.map(permission => {
                  return { value: permission, label: permission };
                })}
                className="basic-multi-select"
                classNamePrefix="Select"
                name="permissions"
                closeMenuOnSelect={false}
                components={animatedComponents}
                onChange={value => {
                  showUpdateButton();
                  setUpdatedPermission(value);
                }}
              />
            </Box>
          </div>
        </Box>
        <div className="content_box">
          <Button
            variant="contained"
            color="secondary"
            disabled={!updateButton}
            onClick={() => {
              updateUserPermission();
              onModalClose();
            }}
            className="inline"
          >
            <UpdateRounded />
            &nbsp;&nbsp;UPDATE
          </Button>
        </div>
      </div>
    </Modal>
  );
}
