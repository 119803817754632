import React, { useState } from 'react';
import { LoadScript } from '@react-google-maps/api';

const libraries = ['geometry', 'drawing', 'places'];
function Maps({ render }) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  return (
    <div style={{ height: `600px` }}>
      <LoadScript
        id="script-loader"
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        loadingElement={<div style={{ height: `100%` }} />}
        libraries={libraries}
        onLoad={() => {
          setScriptLoaded(true);
        }}
      >
        {scriptLoaded && render()}
      </LoadScript>
    </div>
  );
}

export default Maps;
