import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import ServiceWorkerManager from './ServiceWorkerManager';
import { initializeFirebase as initializePushNotifications } from './push-notification';

ReactDOM.render(
  <>
    {process.env.NODE_ENV === 'production' && <ServiceWorkerManager />}
    <App />
  </>,
  document.getElementById('root')
);

if (process.env.NODE_ENV === 'production') {
  initializePushNotifications();
}
