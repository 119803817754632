import axios from 'axios';

const instance = axios.create({
  baseURL: 
    process.env.NODE_ENV !== 'production'
      ? process.env.REACT_APP_API
      : process.env.REACT_APP_IS_STAGING === 'true'
      ? process.env.REACT_APP_API_STAGING
      : process.env.REACT_APP_API,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 30000,
});

export const initialSetup = (onAuthFailure, notif) => {
  instance.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response && error.response.status === 401) {
        console.log('UN-AUTHENTICATED');
        onAuthFailure();
      } else {
        console.log(error.response);
        if (error.response && error.response.data && error.response.data.message) {
          notif(`Backend: ${error.response.data.message}`, { variant: 'error' });
        }
      }
      return Promise.reject(error);
    }
  );
};

const fetch = details => instance({ ...details });

export const api = details => instance(details).then(r => r.data);

export default fetch;
