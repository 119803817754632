import produce from 'immer';
import { useCallback } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createStore as createStoreRedux } from 'redux';
import { getWorkingDate } from 'utils/helperFunctions';
import {
  clearUserData,
  get,
  getAuthToken,
  getUser,
  logout,
  set,
  setAuthToken,
  setUser,
} from 'utils/persistentStore';

export { Provider as StoreProvider, useSelector as useGState };

export function useGDispatch() {
  const dispatch = useDispatch();
  const gDispatch = useCallback(
    action => {
      if (!action) return;
      if (Array.isArray(action)) {
        dispatch({
          type: action[0],
          ...(action[1] ? { data: action[1] } : {}),
        });
      } else {
        dispatch(action);
      }
    },
    [dispatch]
  );

  return gDispatch;
}

export const ga = {
  DATE: 'DATE',
  // CLIENTS: 'CLIENTS',
  PRINCIPALS: 'principals',
  COUNTS: 'COUNTS',
  FILTERS: 'FILTERS',
  LOGOUT: 'LOGOUT',
  AUTH_TOKEN: 'AUTH_TOKEN',
  USER: 'USER',
  PUSER: 'PUSER',
  UNOBTRUSIVE_LOGIN: 'UNOBTRUSIVE_LOGIN',
  AUTH_FAILURE: 'AUTH_FAILURE',
  PLAN_FREEZED: 'PLAN_FREEZED',
};

const initialState = {
  authToken: getAuthToken(),
  user: getUser(),
  date: { date: getWorkingDate() },
  // clients: {},
  // branches: {},
  principals: [],
  planCounts: { pending: 0, done: 0 },
  filters: {
    ci: [],
    bi: [],
    disabled: get('fitlerState') || false,
  },
  unobtrusiveLogin: false,
};

const reducer = (state, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case ga.DATE:
        draft.date = { date: action.date };
        break;
      case ga.COUNTS:
        draft.planCounts = action.planCounts;
        break;
      case ga.USER:
        draft.user = action.data;
        setUser(action.data);
        break;
      case ga.PUSER:
        {
          const user = { ...state.user, pUser: action.data };
          draft.user = user;
          draft.filters.ci = action.data.filters.ci;
          draft.filters.bi = action.data.filters.bi;
          setUser(user);
        }
        break;
      case ga.AUTH_TOKEN:
        draft.authToken = action.data;
        setAuthToken(action.data);
        break;
      case ga.AUTH_FAILURE:
        // draft.authToken = null;
        draft.user = null;
        clearUserData();
        break;
      case ga.LOGOUT:
        // draft.authToken = null;
        draft.user = null;
        logout();
        break;
      case ga.PLAN_FREEZED:
        draft.planCounts = {
          pending: --state.planCounts.pending,
          done: ++state.planCounts.done,
        };
        break;
      // case ga.CLIENTS:
      //   draft.clients = arrayToObject(action.clients);
      //   draft.branches = arrayToObject(
      //     action.clients.flatMap(c =>
      //       c.branches.map(b => ({ ...b, clientId: c.id, clientName: c.name }))
      //     )
      //   );
      //   break;
      case ga.PRINCIPALS:
        draft.principals = action.data;
        break;
      case ga.FILTERS:
        draft.filters = { ...state.filters, ...action.value };
        if (Object.keys(action.value).includes('disabled')) {
          set('fitlerState', action.value.disabled);
        }
        break;
      case ga.UNOBTRUSIVE_LOGIN:
        draft.unobtrusiveLogin = action.data;
        break;
      default:
        return state;
    }
  });
};

// const uiReducer = (state, action) => {
//   return produce(state, draft => {
//     switch (action.type) {
//     }
//   });
// };

/* eslint-disable no-underscore-dangle */
export function createStore() {
  return createStoreRedux(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}
/* eslint-enable */
