import React from 'react';
import { Link as LinkRouter } from '@reach/router';

export default function Link({ children, given = true, ...others }) {
  return given ? (
    <LinkRouter {...others} style={{ textDecoration: 'none' }}>
      {children}
    </LinkRouter>
  ) : (
    children
  );
}
