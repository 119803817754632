import { useMemo } from 'react';

export const useGetProfilesByBranch = branchId =>
  useMemo(
    () => ({
      method: 'get',
      url: '/admin/delivery/profile',
      params: {
        branchId,
      },
    }),
    [branchId]
  );

export const useGetSalesmanByBranch = branchId =>
  useMemo(
    () => ({
      method: 'get',
      url: '/admin/delivery/salesman',
      params: {
        branchId,
      },
    }),
    [branchId]
  );

export const updateProfile = (planId, branchId, data) => {
  return {
    method: 'put',
    url: `/admin/delivery/profile/${planId}`,
    params: {
      branchId,
    },
    data: preProcessProfile(data),
  };
};

export const deleteProfile = (planId, branchId) => {
  return {
    method: 'delete',
    url: `/admin/delivery/profile/${planId}`,
    params: {
      branchId,
    },
  };
};

export const addProfile = (branchId, data) => {
  return {
    method: 'post',
    url: `/admin/delivery/profile`,
    params: {
      branchId,
    },
    data: preProcessProfile(data),
  };
};

const preProcessProfile = profile => {
  const tmp = { ...profile };
  tmp.vehicleTypes = profile.vehicleTypes.map(v => {
    const tmpV = { ...v };
    if (tmpV.id) delete tmpV.id;

    Object.entries(tmpV).forEach(([key, value]) => {
      if (key === 'name' || key === 'isSplitCompatible') return;
      tmpV[key] = parseFloat(value);
    });

    return tmpV;
  });
  delete tmp.id;
  return tmp;
};
