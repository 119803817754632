import React from 'react';
import HourglassEmpty from '@material-ui/icons/HourglassEmptyRounded';
import DoneOutline from '@material-ui/icons/DoneOutlineRounded';
import Badge from '@material-ui/core/Badge';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { useGState } from 'state/store';

const useStyles = makeStyles(theme => ({
  icon: {
    margin: 5.5,
    color: '#c5cae9',
  },
  selected: {
    color: '#3f51b5',
  },
  badge: {
    top: '90%',
    right: 2,
    // The border color match the background color.
    border: `2px solid ${theme.palette.grey[800]}`,
  },
  selectedBadge: {
    border: `2px solid ${theme.palette.grey[200]}`,
  },
  doneBadge: {
    backgroundColor: green[500],
  },
  pendingBadge: {
    backgroundColor: red[500],
  },
}));

export default function PlanActivityBarIcon({ done }) {
  const c = useStyles();
  const planCounts = useGState(state => state.planCounts);

  function isSelected() {
    const path = done ? '/done' : '/pending';
    if (window.location.pathname === '/' && path === '/pending') return true;
    return window.location.pathname.startsWith(path);
  }
  const selected = isSelected();

  return !done ? (
    <Badge
      badgeContent={planCounts.pending}
      max={999}
      classes={{ badge: classNames(c.badge, c.pendingBadge, { [c.selectedBadge]: selected }) }}
    >
      <HourglassEmpty className={classNames(c.icon, { [c.selected]: selected })} />
    </Badge>
  ) : (
    <Badge
      badgeContent={planCounts.done}
      max={999}
      classes={{ badge: classNames(c.badge, c.doneBadge, { [c.selectedBadge]: selected }) }}
    >
      <DoneOutline className={classNames(c.icon, { [c.selected]: selected })} />
    </Badge>
  );
}
