import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogTransition } from './transitions';

export default class FormDialog extends React.Component {
  state = {
    open: false,
    params: null,
  };

  openDialog = params => {
    this.setState({ open: true, params });
  };

  closeDialog = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      title = '',
      contentText,
      content,
      submit = 'SUBMIT',
      cancel = 'CANCEL',
      onSubmit,
      closeOnSubmit = true,
      ...others
    } = this.props;
    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={DialogTransition}
        keepMounted
        disableBackdropClick
        disableEscapeKeyDown
        onClose={this.closeDialog}
        {...others}
      >
        <DialogTitle>{title || 'Are you Sure'}</DialogTitle>
        <DialogContent>
          {contentText && <DialogContentText>{contentText}</DialogContentText>}
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            {cancel}
          </Button>
          <Button
            onClick={() => {
              if (closeOnSubmit) this.closeDialog();
              onSubmit(this.state.params);
            }}
            color="primary"
            variant="outlined"
          >
            {submit}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
