import { debug } from './utils';

const runSa = (initSol, calcCost, perturb, statePool, options) => {
  const opts = Object.assign(
    {
      N: 10000,
      T: 100,
      round: 100,
      resetTempN: 5000,
    },
    Object(options)
  );

  const sol = {
    cur: initSol,
    best: initSol,
  };

  const initCost = calcCost(initSol);
  const cost = {
    cur: initCost,
    best: initCost,
  };

  const answer = {
    initial: { sol: initSol, cost: initCost },
  };

  debug('Config', JSON.stringify(opts));
  debug('Initial cost', initCost);
  debug('Initial state', initSol.length, initSol);

  debug('Starting SAN...');
  const start = Date.now();

  hotLoop(sol, cost, opts, calcCost, perturb, statePool);

  const end = Date.now();
  const timeTaken = end - start;
  debug(`Finished SAN in ${timeTaken} ms`);
  answer.final = { sol: sol.best, cost: cost.best };

  debug('Final cost', cost.best);
  debug('Final state', sol.best.length, sol.best);
  return answer;
};

const hotLoop = (sol, cost, opts, calcCost, perturb, statePool) => {
  for (let i = 0, T; i < opts.N; i++) {
    if (i % opts.reheatInterval === 0) {
      debug('Reheat', 'Iter count:', i);
      T = opts.T;
    }

    doMove(sol, cost, T, calcCost, perturb, statePool, i);

    if (i % opts.round === 0) {
      T = anneal(T, opts.lambda);
    }
  }
};

const doMove = (sol, cost, T, calcCost, perturb, statePool) => {
  const newSol = perturb(sol.cur, statePool);
  const newCost = calcCost(newSol);

  if (newCost < cost.cur) {
    statePool.reclaim(sol.cur);
    sol.cur = newSol;
    cost.cur = newCost;
  } else if (metropolis(newCost, cost.cur, T)) {
    if (sol.best !== sol.cur) {
      statePool.reclaim(sol.cur);
    }
    sol.cur = newSol;
    cost.cur = newCost;
  } else {
    statePool.reclaim(newSol);
  }

  if (cost.cur < cost.best) {
    statePool.reclaim(sol.best);
    sol.best = sol.cur;
    cost.best = cost.cur;
  }
};

const metropolis = (newCost, oldCost, T) => {
  return Math.random() <= Math.exp(-(newCost - oldCost) / T);
};

const anneal = (T, lambda) => {
  return T * lambda;
};

export default runSa;
