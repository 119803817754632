import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { Box, Button, Grid } from '@material-ui/core';
import { api } from 'utils/fetch';
import { useGState } from 'state/store';
import { RolesSelector } from './RolesSelector';
import { AccountSelector } from './AccountSelector';
import { OpenModal } from './OtpModal';
import { useQueryParam, StringParam } from 'use-query-params';
import { NodeHierarchy } from 'components/NodeSelector';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/styles';
import { FixedSizeList as List } from 'react-window';

export function PlSupport({ nodeId }) {
  const [selectedRole, setSelectedRole] = useQueryParam('roleId', StringParam);
  const [selectedAccount, setSelectedAccount] = useQueryParam('accountId', StringParam);
  const user = useGState(state => state.user);
  const [refetch, setRefetch] = useState(false);
  const [showModal, setShowModal] = useState([false, false, false, false, false]);
  const [loginId, setLoginId] = useState();
  const [roleLabel, setRoleLabel] = useState();
  const userDetailsList = JSON.parse(localStorage.getItem('userDetailsList')) || [];

  function check(value) {
    return value === true;
  }

  const modalIndex = showModal.findIndex(check);

  const userDetails = {
    node: nodeId,
    role: roleLabel,
    account: loginId,
    accountId: selectedAccount,
  };

  function checkDuplicates(arr, item) {
    let exists = false;
    arr.forEach(function(e) {
      if (JSON.stringify(item) === JSON.stringify(e)) {
        exists = true;
      }
    });
    if (exists) return true;
    else return false;
  }

  useEffect(() => {
    if (
      selectedAccount !== undefined &&
      selectedAccount !== 'undefined' &&
      !refetch &&
      !checkDuplicates(userDetailsList, userDetails)
    ) {
      if (roleLabel && loginId) {
        userDetailsList.push(userDetails);
      }
    }
    if (userDetailsList.length > 5) {
      userDetailsList.splice(0, 1);
    }
    localStorage.setItem('userDetailsList', JSON.stringify(userDetailsList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const useStyles = makeStyles(theme => ({
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      background: 'GainsBoro',
    },
  }));

  const classes = useStyles();

  const generateOTP = useMutation(['otp', selectedAccount, user], accountId =>
    api({
      method: 'POST',
      url: '/core/auth/generate_support_otp',
      data: {
        accountId: Number(accountId),
        supportAccount: user.email,
      },
    })
  );

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1} mr={1} mb={2}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={3} key={nodeId}>
              <RolesSelector
                value={selectedRole}
                parentId={nodeId}
                onChange={({ value, label }) => {
                  setSelectedRole(value);
                  setRoleLabel(label);
                }}
                setValue={label => {
                  setRoleLabel(label);
                }}
              />
            </Grid>
            {selectedRole ? (
              <Grid item xs={3} key={nodeId + selectedRole}>
                <AccountSelector
                  nodeId={nodeId}
                  roleId={selectedRole}
                  value={selectedAccount}
                  onChange={({ value, loginId }) => {
                    setSelectedAccount(value);
                    setLoginId(loginId);
                    setRefetch(true);
                  }}
                  setValue={loginId => {
                    setLoginId(loginId);
                  }}
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>
        {nodeId ? (
          <Box w={90}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              onClick={() => {
                generateOTP.mutate(selectedAccount);
                setRefetch(false);
                setShowModal([false, false, false, false, false]);
              }}
              disabled={!selectedAccount}
            >
              Generate OTP
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box display="flex">
        <Grid container justify="center" style={{ paddingTop: 130 }}>
          <List
            className="List"
            height={400}
            itemCount={userDetailsList.length}
            itemSize={70}
            width={1050}
          >
            {({ index, style }) => (
              <div style={style}>
                <Paper className={classes.paper} elevation={0}>
                  <Grid container justify="space-around" alignItems="center" wrap="nowrap">
                    <Grid item xs={5}>
                      <Typography component={'div'} variant="body2" color="textPrimary" noWrap>
                        {<NodeHierarchy nodeId={JSON.parse(userDetailsList[index].node)} />}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography color="textPrimary" variant="body2" noWrap>
                        {JSON.parse(JSON.stringify(userDetailsList[index].role))}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography color="textPrimary" variant="body2" noWrap>
                        {JSON.parse(JSON.stringify(userDetailsList[index].account))}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        color="primary"
                        disableElevation
                        onClick={() => {
                          generateOTP.mutate(userDetailsList[index].accountId);
                          let arr = [false, false, false, false, false];
                          arr[index] = true;
                          setShowModal(arr);
                          console.log(generateOTP.data);
                        }}
                      >
                        Generate OTP
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </div>
            )}
          </List>
        </Grid>
      </Box>
      {generateOTP.data && !refetch && showModal.every(value => value === false) && (
        <OpenModal loginId={loginId} otp={generateOTP.data.otp}></OpenModal>
      )}
      {generateOTP.data && modalIndex >= 0 && (
        <OpenModal
          loginId={userDetailsList[modalIndex].account}
          otp={generateOTP.data.otp}
        ></OpenModal>
      )}
    </>
  );
}
