import { debug, createNewPath, FixedLengthArrayPool } from './utils';
import runSa from './runSa';
import nearestN from './nearestN';

export const pathCost = (path, calcCost) => {
  let sum = 0;
  for (let i = 0; i < path.length - 1; i++) {
    const cost = calcCost(path[i], path[i + 1]);
    sum += cost;
  }
  return sum;
};

const defaultOpts = {
  N: 200_000,
  reheatInterval: 100_000,
  T: null,
  lambda: 0.999,
  round: 1,
};

const solveTsp = (N, calcCost, opts = defaultOpts) => {
  const statePool = new FixedLengthArrayPool(N + 1);

  debug('Construction using NearestN');
  let path = nearestN(N, calcCost, statePool);

  const badSol = 0.1;
  const initialTemp = (-badSol * pathCost(path, calcCost)) / Math.log(0.5);
  opts.T = initialTemp;
  debug('Initial Temp', opts.T);

  if (opts.N === 0 || path.length < 4) {
    // already the best path
    return path;
  }

  debug('Local search using SA');
  const answer = runSa(path, path => pathCost(path, calcCost), createNewPath, statePool, opts);

  debug('Cost:', pathCost(answer.final.sol, calcCost));

  return answer.final.sol;
};

export default solveTsp;
