import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import EditRounded from '@material-ui/icons/EditRounded';
import { Button } from '@material-ui/core';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { PUModal } from './PermissionUpdateModal';
import Spinner from './Loader';

const GET_USERS = gql`
  query {
    users {
      id
      name
      email
      permissions
    }
  }
`;

export default function UserList() {
  const [targetUser, setTargetUser] = useState();

  const { loading, data } = useQuery(GET_USERS);

  const users = data?.users;

  return (
    <>
      <React.Fragment>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          Users
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Permissions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users.map(user => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setTargetUser(user);
                      }}
                    >
                      <EditRounded />
                    </Button>
                    {user.permissions &&
                      user.permissions.map((permission, index) => (
                        <span key={index}>{permission}&nbsp;&nbsp;</span>
                      ))}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </React.Fragment>
      <Spinner isLoading={loading} />
      {targetUser && <PUModal user={targetUser} onCloseFunction={() => setTargetUser(null)} />}
    </>
  );
}
