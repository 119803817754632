import { Box, Zoom } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles, useTheme } from '@material-ui/styles';
import { navigate } from '@reach/router';
import FormDialog from 'components/FormDialog';
import NodeSelector from 'components/NodeSelector';
import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import {
  addProfile,
  useGetProfilesByBranch,
  useGetSalesmanByBranch,
} from 'services/profileService';
import {
  useAPI,
  useFetch,
  useReducerInit,
  useRemember,
  useUnobtrusiveLogin,
} from 'utils/customHooks';
import { byId } from 'utils/helperFunctions';
import { a, initialState, reducer } from './planProfileReducer';
import Profile from './Profile';

const onDefault = storedValue => {
  navigate(`/planProfile/${storedValue}`);
};

export default function PlanProfile({ branchId }) {
  const storedBranchId = useRemember('profileBranch', branchId, [':branchId'], onDefault);

  return (
    <>
      <NodeSelector
        initialValue={storedBranchId}
        onChange={nodeId => navigate(`/planProfile/${nodeId}`)}
      />
      {storedBranchId && <BranchPlanProfiles branchId={storedBranchId} />}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

export function BranchPlanProfiles({ branchId }) {
  const classes = useStyles();
  const dialog = useRef();
  const theme = useTheme();
  const [profiles] = useFetch(useGetProfilesByBranch(branchId));
  const [salesman] = useFetch(useGetSalesmanByBranch(branchId));
  const [apiAction] = useAPI();
  const [state, dispatch] = useReducer(reducer, initialState);
  useUnobtrusiveLogin();

  const openDialog = useCallback(() => {
    dialog.current.openDialog();
  }, [dialog]);

  useReducerInit(profiles, dispatch);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const disableDefault = useMemo(() => state.profiles.some(s => s.default), [state.profiles]);

  return (
    <Box>
      <FormDialog
        className={classes.addDialog}
        title="Create Plan Profile"
        content={
          <Profile
            profile={byId(state.profiles, 'new')}
            isNew
            disableDefault={disableDefault}
            salesman={salesman}
            dispatch={dispatch}
            createDialog
            profilesName={state.profiles.filter(p => p.id !== 'new').map(p => p.name)}
          />
        }
        ref={dialog}
        submit="CREATE"
        fullWidth
        maxWidth="lg"
        onSubmit={() => {
          apiAction(addProfile(branchId, byId(state.profiles, 'new')), resp =>
            dispatch([a.ADD_PROFILE, resp])
          );
        }}
      />
      {state.profiles
        .filter(p => p.id !== 'new')
        .map(profile => (
          <Profile
            key={profile.id}
            profile={profile}
            ui={state.ui[profile.id]}
            disableDefault={disableDefault}
            dispatch={dispatch}
            salesman={salesman || []}
            openDialog={openDialog}
            apiAction={apiAction}
            profilesName={state.profiles.filter(p => p.id !== 'new').map(p => p.name)}
          />
        ))}
      <Zoom
        in={true}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${transitionDuration.exitÍ}ms`,
        }}
        unmountOnExit
      >
        <Fab
          onClick={() => {
            dialog.current.openDialog();
          }}
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </Box>
  );
}
