import React, { useState } from 'react';
import { Box, Button, Grid, Typography, TextField } from '@material-ui/core';
import { useGState } from 'state/store';
import CloudDownloadRounded from '@material-ui/icons/CloudDownloadRounded';
import { saveAs } from 'file-saver';
import { useAPI } from 'utils/customHooks';
import BaseOutlets from './baseOutlets';
import { UploadFile } from 'components/Uploader';

export default function FileOperations() {
  const [state, setState] = useState();
  const [district, setDistrict] = useState();
  document.body.style = 'background: #EDF2F7;';
  const features = [
    { title: 'Upload to Universe', url: '/universe/upload' },
    { title: 'Update in Universe', url: '/universe/update' },
    { title: 'Delete from Universe', url: '/universe/delete' },
  ];
  const [fetch] = useAPI();
  const downloadAPI = {
    method: 'get',
    responseType: 'blob',
    url: `/universe/download?state=${state}&district=${district}`,
  };

  const user = useGState(state => state.user);

  if (!user.permissions.includes('Coverage Expansion')) {
    return <Typography>Access Denied! Please Contact Abhishek(abhishek@stackbox.xyz).</Typography>;
  }

  return (
    <div>
      <Typography variant="h5" style={{ fontWeight: '600', marginBottom: '24px' }}>
        Coverage Expansion Universe
      </Typography>
      <Grid container align="left" spacing={4} display="flex">
        {features.map(feature => (
          <Grid key={feature.title} item xs={3} style={{ padding: '12px' }}>
            <Box style={{ background: 'white' }} borderRadius="12px" p={3}>
              <UploadFile title={feature.title} url={feature.url} key={feature.title} />
            </Box>
          </Grid>
        ))}
        <Grid item xs={3}>
          <Box style={{ background: 'white' }} borderRadius="12px" pb={2}>
            <Box px={2} pt={2}>
              <Typography variant="h6" align="left">
                Please provide the information
              </Typography>
            </Box>
            <Box px={2} pt={2}>
              <TextField
                id="outlined-basic"
                label="State"
                variant="outlined"
                size="small"
                placeholder="Please Enter State"
                value={state}
                onChange={e => setState(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>
            <Box px={2} pt={2}>
              <TextField
                id="outlined-basic"
                label="District"
                variant="outlined"
                size="small"
                placeholder="Please Enter District"
                value={district}
                onChange={e => setDistrict(e.target.value)}
                style={{ width: '100%' }}
              />
            </Box>
            <Box pt={2} px={2}>
              <Button
                px={3}
                startIcon={<CloudDownloadRounded />}
                variant="contained"
                onClick={() => {
                  fetch(downloadAPI, resp => saveAs(resp, `${state}-${district}.xlsx`));
                }}
                style={{
                  background: !state || !district ? '#BAC3C8' : '#2C3741',
                  color: '#FFFFFF',
                  width: '100%',
                }}
                disabled={!state || !district}
              >
                Download
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <BaseOutlets />
    </div>
  );
}
