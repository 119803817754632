import React, { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { Workbox } from 'workbox-window';
import { provideSW as provideSWtoFirebase } from './push-notification';

export default function ServiceWorkerManager() {
  const [open, setOpen] = useState(false);
  const wbRef = useRef(null);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/service-worker.js');
      wbRef.current = wb;

      wb.addEventListener('waiting', () => {
        console.log('New Service Worker found. Waiting to be activated');
        setOpen(true);
      });

      wb.register().then(registation => provideSWtoFirebase(registation));
    }
  }, []);

  return (
    <Snackbar
      open={open}
      message={<span id="update availabale">Update Available</span>}
      action={[
        <Button
          key="undo"
          color="secondary"
          size="small"
          onClick={() => {
            wbRef.current.addEventListener('controlling', () => {
              window.location.reload();
            });
            wbRef.current.messageSW({ type: 'SKIP_WAITING' });
            setOpen(false);
          }}
        >
          UPDATE
        </Button>,
      ]}
    />
  );
}
