import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core';

const styles = () => ({
  wrapper: {
    position: 'relative',
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: 8,
    left: 8,
    zIndex: 1,
  },
});

class CButton extends Component {
  render() {
    const { loading, children, classes, ...rest } = this.props;
    return (
      <div className={classes.wrapper} ref={rest.ref}>
        <IconButton disabled={loading} {...rest} onClick={rest.onClick ? rest.onClick : undefined}>
          {children}
        </IconButton>
        {loading && <CircularProgress size={32} className={classes.fabProgress} />}
      </div>
    );
  }
}

export default withStyles(styles)(CButton);
