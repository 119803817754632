import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import { JsonEditor as JsonEditorLib } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import React from 'react';

export default function JsonEditor({ initialValue, onChange, height = 250 }) {
  return (
    <JsonEditorLib
      value={initialValue}
      onChange={onChange}
      ace={ace}
      navigationBar={false}
      mode="code"
      theme="ace/theme/github"
      htmlElementProps={{ style: { height } }}
    />
  );
}
