import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  paper: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    background: 'transparent',
  },
}));

function PlanCardHeader({ groupBy, status }) {
  const classes = useStyles();
    return (
      <Paper className={classes.paper} elevation={0}>
        <Grid container justify="space-around" alignItems="center" wrap="nowrap">
          {groupBy === false ? (
            <Grid item xs>
              <Typography color="textPrimary" noWrap>
                PlanId
              </Typography>
            </Grid>
          ) : (
            <Grid item xs>
              <Typography color="textPrimary" noWrap>
                Plans
              </Typography>
            </Grid>
          )}
          <Grid item xs>
            <Typography color="textPrimary" noWrap>
              Node
            </Typography>
          </Grid>
          {(groupBy === false && status === 'pending') ? (
          <>
            <Grid item xs>
              <Typography color="textPrimary" noWrap>
                Profile Name
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography color="textPrimary" noWrap>
                Client Name
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography color="textPrimary" noWrap>
                Status
              </Typography>
            </Grid>
            <Grid item xs={3}>
            </Grid>
          </>
          ) : (
          <>  
            <Grid item xs>
              <Typography color="textPrimary" noWrap>
                Client Name
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography color="textPrimary" noWrap>
                Profile Name
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography color="textPrimary" noWrap>
                Status
              </Typography>
            </Grid>
          </>
          )}
          {groupBy === false && status === 'done' && (
            <Grid item xs>
              <Typography color="textPrimary" noWrap>
                Frozen by
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }

export default PlanCardHeader;
