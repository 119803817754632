import React from 'react';
import Button from '@material-ui/core/Button';

export const dismiss = callback => {
  const Dismiss = key => (
    <Button
      onClick={() => {
        callback(key);
      }}
    >
      {'Dismiss'}
    </Button>
  );
  return Dismiss;
};
