import React from 'react';
import { Button } from '@material-ui/core';
import { saveAs } from 'file-saver';
import { useAPI } from 'utils/customHooks';

export default function Sample({ url, name = 'sample', ...rest }) {
  const [fetch] = useAPI();

  const downloadAPI = {
    method: 'get',
    responseType: 'blob',
    url,
  };

  return (
    <Button
      color="primary"
      onClick={() => {
        fetch(downloadAPI, resp => saveAs(resp, name));
      }}
      {...rest}
    >
      Sample
    </Button>
  );
}
