import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import Select from 'components/ReactSelect';
import React, { useState } from 'react';
import { useGState } from 'state/store';
import { useAPI, useSnackbar } from 'utils/customHooks';
import { principalSelect } from 'utils/helperFunctions';

const createClientService = data => ({
  url: '/panzer/client',
  method: 'POST',
  data,
});

const emptyClientDetails = {
  code: '',
  name: '',
  loginId: '',
  password: 'stackbox123',
  principalId: null,
};

export default function ClientOnboarding() {
  const principals = useGState(s => s.principals);

  const [newClientDetail, setNewClientDetail] = useState(emptyClientDetails);
  const [fetch, , loading] = useAPI();
  const options = principalSelect(principals);
  const [notif] = useSnackbar();

  const handleChange = name => event => {
    setNewClientDetail({ ...newClientDetail, [name]: event.target.value });
  };

  function createClient() {
    fetch(createClientService(newClientDetail), () => {
      notif('Success: Client Created', { variant: 'success' });
      setNewClientDetail(emptyClientDetails);
    });
  }

  return (
    <Box
      width={1}
      p={2}
      borderColor="error.main"
      borderRadius={16}
      border={1}
      bgcolor="white"
      mt={3}
    >
      <Box pb={2}>
        <Typography variant="h5" align="left" color="textSecondary">
          Client Onboarding
        </Typography>
      </Box>

      <Grid container direction="row" justify="space-around" alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <TextField label="Name" value={newClientDetail.name} onChange={handleChange('name')} />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label="Login Id"
            value={newClientDetail.loginId}
            onChange={handleChange('loginId')}
          />
        </Grid>

        <Grid item xs={2}>
          <TextField
            label="Password"
            value={newClientDetail.password}
            onChange={handleChange('password')}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField label="Code" value={newClientDetail.code} onChange={handleChange('code')} />
        </Grid>

        <Grid item xs={4}>
          <Select
            options={options}
            // value={options.find(({ value }) => value === parseInt(storedClientId))}
            onChange={resp => setNewClientDetail({ ...newClientDetail, principalId: resp.value })}
            placeholder="Principal (optional)"
            menuPortalTarget={document.getElementById('portal-target')}
          ></Select>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Button variant="contained" disabled={loading} color="primary" onClick={createClient}>
          Create Client
        </Button>
      </Box>
    </Box>
  );
}
