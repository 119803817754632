import { Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import NodeSelector from 'components/NodeSelector';
import React from 'react';
import { useGState } from 'state/store';
import { useRemember } from 'utils/customHooks';
import { PlSupport } from './PlSupport';
import { useQueryParam, StringParam } from 'use-query-params';

const onDefault = storedValue => {
  navigate(`/passwordlessSupport/${storedValue}`);
};

export default function PlSupportMain({ nodeId }) {
  const storedNodeId = useRemember('plSupportNodeId', nodeId, [':nodeId'], onDefault);
  const [roleId] = useQueryParam('roleId', StringParam);
  const [accountId] = useQueryParam('accountId', StringParam);
  const submit = true;
  const user = useGState(state => state.user);

  if (!user.permissions.includes('PL Access')) {
    return <Typography>Access Denied! Please Contact Abhishek(abhishek@stackbox.xyz).</Typography>;
  }

  return (
    <>
      <NodeSelector
        initialValue={storedNodeId}
        onChange={nodeId =>
          navigate(`/passwordlessSupport/${nodeId}?roleId=${roleId}&accountId=${accountId}`)
        }
        autoSubmit={submit}
      />
      {storedNodeId && <PlSupport nodeId={storedNodeId} />}
    </>
  );
}
