import { IconButton, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { navigate } from '@reach/router';
import NodeSelector from 'components/NodeSelector';
import React from 'react';
import { useQuery } from 'react-query';
import { useGState } from 'state/store';
import { useRemember } from 'utils/customHooks';
import Config from './Config';

const onDefault = storedValue => {
  navigate(`/clientConfig/${storedValue}`);
};

// const permittedUsers = [
//   'abhishek',
//   'saby',
//   'vikas',
//   'chaitanya',
//   'shanmukha',
//   'parikshit',
//   'sourabh',
//   'rohit',
//   'rohit.kumar',
//   'prakhar',
//   'sangeet',
//   'praveen',
//   'abhijit',
//   'tantril',
//   'vamshikrishna',
//   'abhay',
//   'suraj',
//   'varadraj',
//   'akshay',
//   'jyothi',
//   'nahush',
//   'chaitanya.csv',
//   'ashwin',
//   'sabharish',
//   'vinaykumar.karne',
// ];

export default function ClientConfigMain({ clientId }) {
  const user = useGState(state => state.user);
  const storedClientId = useRemember('clientConfig', clientId, [':clientId'], onDefault);

  if (!user) {
    return <Typography>Please Relogin</Typography>;
  }

  if (
    !// permittedUsers.map(id => `${id}@stackbox.xyz`).includes(user.email) ||
    // permittedUsers.map(id => `${id}@stackbox.co.in`).includes(user.email)
    user.permissions.includes('Settings')
  ) {
    return (
      <Typography>
        Access Denied! Please Contact Abhishek(abhishek@stackbox.xyz) for clients/branch setting
        changes.
      </Typography>
    );
  }

  return (
    <>
      <NodeSelector
        initialValue={storedClientId}
        onChange={nodeId => navigate(`/clientConfig/${nodeId}`)}
      />
      <a
        href="https://docs.google.com/document/d/e/2PACX-1vTFZuBKphwOTwfuIIlceT-hi2Ot6J0RV-ThccoPqxirKM5s1rkJao0alvq_Zn2D1yqulI1vQfM38KBB/pub"
        target="_blank"
        rel="noreferrer noopener"
        style={{ position: 'absolute', right: '16px', bottom: '16px' }}
      >
        <Tooltip title="View Settings Document">
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </a>
      {storedClientId && <ClientConfigList nodeId={storedClientId} />}
    </>
  );
}

export function ClientConfigList({ nodeId }) {
  const { data: settings, refetch, isFetched } = useQuery(`/v2/admin/settings?nodeId=${nodeId}`, {
    cacheTime: 0,
  });

  return (
    isFetched && settings && <Config nodeId={nodeId} details={settings} refetch={refetch}></Config>
  );
}
