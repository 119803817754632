import React, { memo, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { api } from 'utils/fetch';

export const RolesSelector = memo(function RolesSelector({ value, parentId, onChange, setValue }) {
  const { data: roles = [] } = useQuery(['roles', parentId], () =>
    api({
      method: 'POST',
      url: '/core/role/query',
      data: {
        nodeId: parentId,
      },
    })
  );
  const rolesOptions = useRoleOptions(roles);
  const storedRole = rolesOptions.find(({ value: sValue }) => sValue === value);
  useEffect(() => {
    if (value && rolesOptions.find(({ value: sValue }) => sValue === value)) {
      return;
    }
    if (rolesOptions.length) {
      onChange(rolesOptions.find(({ label }) => label === 'SUPERUSER'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesOptions, value]);

  setValue(storedRole ? storedRole.label : undefined);

  return (
    rolesOptions.length > 0 && (
      <Select
        options={rolesOptions}
        value={rolesOptions.find(({ value: sValue }) => sValue === value)}
        placeholder="Select Role ..."
        onChange={onChange}
        menuPortalTarget={document.getElementById('portal-target')}
      />
    )
  );
});

function useRoleOptions(roles) {
  return useMemo(() => (roles ? roles.map(r => ({ value: r.id, label: r.code })) : []), [roles]);
}

RolesSelector.displayName = 'Role Selector';
