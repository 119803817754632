import React, { memo } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import { Router } from '@reach/router';
import { useGlobalStatev1 } from '../state/globalContextv1';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { defaults } from './PageLayout';

const useStyles = makeStyles(theme => ({
  drawer: {
    // width: props => props.ACTIVITYBAR_WIDTH,
    width: defaults.ACTIVITYBAR_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    // width: props => props.SIDEBAR_WIDTH,
    width: defaults.SIDEBAR_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: defaults.ACTIVITYBAR_WIDTH,
  },
  contentContainer: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(2) + defaults.ACTIVITYBAR_WIDTH,
  },
  topAppbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: defaults.SIDEBAR_WIDTH - defaults.ACTIVITYBAR_WIDTH - 1,
    marginLeft: defaults.ACTIVITYBAR_WIDTH,
    minHeight: defaults.ACTIVITYBAR_WIDTH,
  },
  toolbar: {
    height: '100%',
    width: '100%',
  },
}));

function SideBar({ open, routes, defaults }) {
  const classes = useStyles(defaults);
  const sideBarTitle = useGlobalStatev1('sideBarTitle');

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}
    >
      <AppBar elevation={0} position="sticky" color="default" className={classes.topAppbar}>
        <Toolbar className={classes.toolbar}>
          {sideBarTitle && typeof sideBarTitle === 'string' ? (
            <Typography variant="h5" style={{ width: '100%' }} color="primary" align="center">
              {sideBarTitle}
            </Typography>
          ) : (
            sideBarTitle.type === 'plan' && (
              <Grid container direction="column">
                <Typography
                  variant="subtitle1"
                  style={{ textTransform: 'uppercase' }}
                  noWrap
                  color="primary"
                  align="center"
                >
                  {sideBarTitle.branchName}
                </Typography>

                <Grid
                  container
                  spacing={1}
                  wrap="nowrap"
                  justify="space-around"
                  alignItems="center"
                >
                  <Grid item zeroMinWidth>
                    <Typography variant="body1" noWrap color="textSecondary" align="center">
                      {sideBarTitle.name}
                    </Typography>
                  </Grid>
                  <Grid item zeroMinWidth>
                    <Typography variant="body1" noWrap color="textSecondary" align="center">
                      {sideBarTitle.clientName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.contentContainer}>
        <Router>
          {routes.map(({ path, SideBar }) => (
            <SideBar.type {...SideBar.props} path={path} key={path} />
          ))}
        </Router>
      </div>
    </Drawer>
  );
}

export default memo(SideBar);
