import React, { useCallback, useRef } from 'react';
import ReactSelect from 'react-select';
import makeAnimated from 'react-select/animated';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import { a } from './planProfileReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { updateProfile, deleteProfile } from 'services/profileService';
import { byId } from 'utils/helperFunctions';
import { vehicleParamsLabel as vpl } from 'utils/constants';
import ConfirmationDialog from 'components/ConfirmationDialog';
import JsonEditor from 'components/JsonEditor';
import chroma from 'chroma-js';

const animatedComponent = makeAnimated();
const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(1),
  },
  copyProfileButton: {
    marginLeft: 'auto',
    position: 'absolute',
    right: theme.spacing(2),
  },
  cardActionPanel: {
    position: 'relative',
  },
  name: {
    // marginLeft: theme.spacing(2),
  },
  heading: {
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 0,
  },
  selectControl: {
    paddingBottom: theme.spacing(1),
    // margin: theme.spacing(1),
    minWidth: 200,
  },
  // headingC: {
  //   flex: '0 0 200px',
  // },
  default: {},
  sectionHeading: {
    paddingBottom: theme.spacing(1),
  },
  section: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  vehicleLabel: {
    flex: '0 0 100px',
  },
  vehicleHeader: {
    paddingBottom: theme.spacing(1),
  },
  controlC: {
    // padding: theme.spacing(1),
  },
}));

const salesmanSelectColourStyles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      ...(data.active
        ? {}
        : {
            backgroundColor: chroma('#f50057')
              .alpha(0.1)
              .css(),
          }),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    ...(data.active ? {} : { color: '#f50057' }),
  }),
};

export default function Profile({
  profile,
  ui,
  salesman,
  apiAction,
  dispatch,
  isNew,
  createDialog,
  openDialog,
  disableDefault,
  profilesName,
}) {
  const classes = useStyles();
  const {
    id = 'new',
    name,
    default: def,
    static: isStatic,
    autoFreeze,
    isDummy,
    allSalesmen,
    serviceTimeModel,
    exclusiveZones,
    routingProfile,
    salesmanIds,
    data,
    branchId,
    vehicleTypes,
    startTime,
    deliveryWeightConstraint,
    wave1Profile,
    allowIncremental,
    serviceTypes,
  } = profile;

  const handleChange = useCallback(
    key => value => {
      dispatch([a.EDIT, { id, key, value }]);
    },
    [dispatch, id]
  );

  const orderServiceTypeOptions = ['PICKUP', 'DROP'].map(x => ({ value: x, label: x }));
  const deleteConfirmDialog = useRef();

  return (
    <>
      <ConfirmationDialog
        ref={deleteConfirmDialog}
        title="Delete Plan Profile"
        onYes={() => apiAction(deleteProfile(id, branchId), () => dispatch([a.DELETE_PROFILE, id]))}
      />
      <Card className={classes.card} elevation={createDialog ? 0 : 1}>
        <CardContent>
          <Grid
            container
            className={classes.sectionHeading}
            justify="space-between"
            alignItems="center"
          >
            <Grid item xs={3}>
              <TextField
                className={classes.name}
                label={name && 'Name'}
                variant="outlined"
                onChange={e => handleChange('name')(e.target.value)}
                // defaultValue={name || 'Default'}
                value={name}
                helperText={!name && 'Empty name'}
                margin="dense"
              />
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allowIncremental}
                    onChange={() => handleChange('allowIncremental')(!allowIncremental)}
                    color="primary"
                  />
                }
                className={classes.default}
                label="Allow Incremental"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isDummy}
                    onChange={() => handleChange('isDummy')(!isDummy)}
                    color="primary"
                  />
                }
                className={classes.default}
                label="Dummy Profile"
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={def}
                        disabled={!def && disableDefault}
                        onChange={() => handleChange('default')(!def)}
                        color="primary"
                      />
                    }
                    className={classes.default}
                    label="Default"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isStatic}
                        onChange={() => handleChange('static')(!isStatic)}
                        color="primary"
                      />
                    }
                    className={classes.default}
                    label="Static"
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={autoFreeze}
                        onChange={() => handleChange('autoFreeze')(!autoFreeze)}
                        color="secondary"
                      />
                    }
                    className={classes.default}
                    label="Auto Freeze"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Start Time (HH:MM)"
                    variant="outlined"
                    onChange={e => handleChange('startTime')(e.target.value)}
                    value={startTime}
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Weight Limit"
                    variant="outlined"
                    type="number"
                    onChange={e =>
                      handleChange('deliveryWeightConstraint')(
                        e.target.value ? Number(e.target.value) : null
                      )
                    }
                    value={deliveryWeightConstraint}
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    options={profilesName && profilesName.filter(pName => pName !== name)}
                    onChange={(_, e) => handleChange('wave1Profile')(e)}
                    value={wave1Profile}
                    renderInput={params => (
                      <TextField
                        {...params}
                        style={{ width: 200 }}
                        margin="dense"
                        label="Wave 1 Profile"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          <Grid container className={classes.section} alignItems="flex-start" spacing={2}>
            <Grid item xs={6} className={classes.controlC}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allSalesmen}
                    onChange={() => handleChange('allSalesmen')(!allSalesmen)}
                    color="primary"
                  />
                }
                label="All Salesman"
              />
              <Collapse in={!allSalesmen} timeout="auto" unmountOnExit>
                <ReactSelect
                  placeholder="Select Salesmen"
                  isMulti
                  closeMenuOnSelect={false}
                  components={animatedComponent}
                  onChange={val => handleChange('salesmanIds')(val.map(e => e.value))}
                  styles={salesmanSelectColourStyles}
                  value={
                    salesman?.length &&
                    salesmanIds
                      ?.map(id => byId(salesman, id) || {})
                      .map(({ id, code, active }) => ({ label: code, value: id, active }))
                  }
                  options={
                    salesman &&
                    salesman
                      .filter(s => s.active)
                      .map(({ id, code }) => ({ label: code, value: id }))
                  }
                />
              </Collapse>
            </Grid>
            <Grid item xs={6}>
              <FormControl className={classes.selectControl}>
                <InputLabel htmlFor="routingProfile">Routing Profile</InputLabel>
                <Select
                  value={routingProfile}
                  defaultValue={'distance'}
                  inputProps={{
                    name: 'routingProfile',
                    id: 'routingProfile',
                  }}
                  onChange={e => handleChange('routingProfile')(e.target.value)}
                >
                  <MenuItem value={'distance'}>Distance</MenuItem>
                  <MenuItem value={'bike'}>Bike</MenuItem>
                  <MenuItem value={'van'}>Van</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.section} alignItems="flex-start" spacing={2}>
            <Grid item xs={6} className={classes.controlC}>
              <Grid container className={classes.section} alignItems="flex-start" spacing={2}>
                <Grid item xs={6} className={classes.controlC}>
                  <FormControl className={classes.selectControl}>
                    <InputLabel htmlFor="serviceTimeModel">Service Time Model</InputLabel>
                    <Select
                      value={serviceTimeModel}
                      inputProps={{
                        name: 'serviceTimeModel',
                        id: 'serviceTimeModel',
                      }}
                      onChange={e => handleChange('serviceTimeModel')(e.target.value)}
                    >
                      <MenuItem value={'linear'}>Linear</MenuItem>
                      <MenuItem value={'provided'}>Provided</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} className={classes.controlC}>
                  <div style={{ marginTop: -15 }}>
                    <p style={{ fontSize: 12, color: 'grey', fontWeight: 'lighter' }}>
                      Order Service Type
                    </p>
                    <ReactSelect
                      placeholder="Order Service Type"
                      options={orderServiceTypeOptions}
                      isMulti
                      value={serviceTypes?.map(st => byId(orderServiceTypeOptions, st, 'value'))}
                      onChange={val =>
                        handleChange('serviceTypes')(val ? val.map(e => e.value) : [])
                      }
                    />
                  </div>
                </Grid>
              </Grid>
              <Collapse in={serviceTimeModel === 'linear'} timeout="auto" unmountOnExit>
                <JsonEditor initialValue={data} onChange={val => handleChange('data')(val)} />
              </Collapse>
            </Grid>

            <Grid item xs={6}>
              <div style={{ fontSize: 18 }}>Exclusive Zones</div>
              <JsonEditor
                initialValue={exclusiveZones ?? {}}
                onChange={val => handleChange('exclusiveZones')(val)}
              />
            </Grid>
          </Grid>
          <div className={classes.section}>
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              className={classes.vehicleHeader}
            >
              <Grid item className={classes.vehicleLabel}>
                <Typography variant="h6" color="textSecondary">
                  Vehicles
                </Typography>
              </Grid>
              <Grid item xs>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    dispatch([a.ADD_VEHICLE, id]);
                  }}
                >
                  Add
                </Button>
              </Grid>
            </Grid>

            {vehicleTypes.map((vehicle, index) => (
              <Vehicle
                key={index}
                vehicle={vehicle}
                dispatch={dispatch}
                profileId={id}
                vehicleIndex={index}
              />
            ))}
          </div>
        </CardContent>
        {!isNew && (
          <CardActions className={classes.cardActionPanel}>
            <Button color="secondary" onClick={() => deleteConfirmDialog.current.openDialog()}>
              Delete
            </Button>
            <Button
              color="primary"
              disabled={!ui.dirty}
              onClick={() =>
                apiAction(updateProfile(id, branchId, profile), updatedProfile =>
                  dispatch([
                    a.REPLACE_PROFILE,
                    {
                      replaceProfile: id,
                      updatedProfile,
                    },
                  ])
                )
              }
            >
              Save
            </Button>
            <Button
              className={classes.copyProfileButton}
              onClick={() => {
                dispatch([
                  a.COPY_THIS_TO_NEW,
                  {
                    profileId: id,
                  },
                ]);
                openDialog();
              }}
            >
              NEW COPY
            </Button>
          </CardActions>
        )}
      </Card>
    </>
  );
}

const useStylesVehicles = makeStyles(theme => ({
  textField: {
    minWidth: 140,
  },
  vehicleRow: {
    padding: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
  },
  deleteIcon: {
    flex: '0 0',
  },
}));

//To convert speed and time unit(km/h and hours)
const unitConversion = (key, value) =>
  key === 'maxTripTime'
    ? Math.round((value / 3600) * 100) / 100
    : key === 'avgSpeed'
    ? Math.round((value / 5) * 18 * 10) / 10
    : key === 'volumeLimitRatio'
    ? Math.round(value * 100 * 10) / 10
    : key === 'loadingTime' || key === 'serviceTime'
    ? value / 60
    : key === 'maxDistance'
    ? value / 1000
    : value;

//To re-convert speed and time unit(from km/h and hours to m/s and s)
const reverseUnitConversion = (key, value) =>
  key === 'maxTripTime'
    ? value * 3600
    : key === 'avgSpeed'
    ? (value / 18) * 5
    : key === 'volumeLimitRatio'
    ? value / 100
    : key === 'loadingTime' || key === 'serviceTime'
    ? value * 60
    : key === 'maxDistance'
    ? value * 1000
    : value;

function Vehicle({ vehicle, dispatch, profileId, vehicleIndex }) {
  const classes = useStylesVehicles();
  const vehicleDataOrder = {
    name: null,
    type: null,
    fixedCost: null,
    fixedCostMaxDistance: null,
    variableCost: null,
    variableCostUnit: null,
    maxDropPoints: null,
    maxTrips: null,
    maxTripTime: null,
    minUtilization: null,
    volumeLimitRatio: null,
    maxVolume: null,
    maxWeight: null,
    vehicleCount: null,
    contractType: null,
    defaultSpeed: null,
    avgSpeed: null,
    notionalCostFactor: null,
    maxHUs: null,
    maxDistance: 0,
    loadingTime: 0,
    serviceTime: 0,
    isSplitCompatible: false,
  };
  const orederedVehicleData = Object.assign(vehicleDataOrder, vehicle);
  return (
    <Grid
      container
      justify="space-between"
      className={classes.vehicleRow}
      alignItems="center"
      spacing={2}
    >
      {Object.entries(orederedVehicleData)
        .filter(([key, value]) => !['id'].includes(key) && value !== null)
        .map(([key, value]) => (
          <Grid item p={2} xs key={key} className={classes.textField}>
            {key === 'isSplitCompatible' ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={e =>
                      dispatch([
                        a.EDIT_VEHICLE,
                        {
                          profileId,
                          vehicleIndex,
                          key,
                          value: e.target.checked,
                        },
                      ])
                    }
                  />
                }
                label={vpl[key]}
              />
            ) : (
              <TextField
                label={vpl[key]}
                value={unitConversion(key, value)}
                type={key === 'name' ? 'text' : 'number'}
                onChange={e =>
                  dispatch([
                    a.EDIT_VEHICLE,
                    {
                      profileId,
                      vehicleIndex,
                      key,
                      value: `${reverseUnitConversion(key, e.target.value)}`,
                    },
                  ])
                }
              />
            )}
          </Grid>
        ))}
      <Grid item xs className={classes.deleteIcon}>
        <IconButton
          color="secondary"
          className={classes.button}
          component="span"
          onClick={() =>
            dispatch([
              a.DELETE_VEHICLE,
              {
                profileId,
                vehicleIndex,
              },
            ])
          }
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
