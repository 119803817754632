import React, { Component } from 'react';
import Slide from '@material-ui/core/Slide';

class DialogTransition extends Component {
  render() {
    return <Slide direction="up" {...this.props} />;
  }
}

export { DialogTransition };
