import { remove, arrayToObject } from 'utils/helperFunctions';
let reverseEdits;

export const memoizedGenerateOriginalPlan = () => {
  let cache = {};
  return ({ retailers, picklists, edits }, planId) => {
    if (planId in cache) {
      console.log('Fetching from cache');
      return cache[planId];
    }

    reverseEdits = edits.reverse();

    const { retailers: originalRetailers, picklists: originalPicklists } = reverseEdits.reduce(
      (acc, edit, i) => {
        return removeEdits[edit.type](acc, edit.details, i);
      },
      { retailers, picklists }
    );

    const resp = {
      retailers: originalRetailers,
      picklists: originalPicklists,
    };
    cache[planId] = resp;
    return resp;
  };
};

const removeEdits = {
  CHANGE_PICKLIST({ retailers, picklists }, { fromIndex, toIndex, retailerId }) {
    picklists[toIndex].retailerIds = remove(picklists[toIndex].retailerIds, retailerId);
    picklists[fromIndex].retailerIds = [...picklists[fromIndex].retailerIds, retailerId];
    retailers[retailerId].index = picklists[fromIndex].index;
    return { retailers, picklists };
  },
  ASSIGN_RETAILER({ retailers, picklists }, { picklistIndex, retailerId }) {
    picklists[picklistIndex].retailerIds = remove(picklists[picklistIndex].retailerIds, retailerId);
    retailers[retailerId].index = null;
    return { retailers, picklists };
  },
  UNASSIGN_RETAILER({ retailers, picklists }, { picklistIndex, retailerId }) {
    picklists[picklistIndex].retailerIds = [...picklists[picklistIndex].retailerIds, retailerId];
    retailers[retailerId].index = picklistIndex;
    return { retailers, picklists };
  },
  ADD_PICKLIST({ retailers, picklists }, { _ }) {
    delete picklists[Object.values(picklists).length - 1];
    return { retailers, picklists };
  },
  REMOVE_PICKLISTS({ retailers, picklists }, { picklistIndexes }) {
    let picklistArr = Object.values(picklists).sort((p1, p2) => p1.index - p2.index);
    const maxLength = picklistArr.length + picklistIndexes.length;
    const vehicleTypeId = picklistArr[0].vehicleTypeId;

    for (let i = 0; i < maxLength; i++) {
      if (picklistIndexes.includes(i)) {
        picklistArr.splice(i, 0, { index: i, retailerIds: [], name: `V${i + 1}`, vehicleTypeId });
      } else {
        picklistArr[i].index = i;
      }
    }
    return { retailers, picklists: arrayToObject(picklistArr, 'index') };
  },
  REARRANGE_ROUTE_FULL({ retailers, picklists }, { _ }) {
    return { retailers, picklists };
  },
  REINDEX({ retailers, picklists }, { newIndices }) {
    const temp = {};
    newIndices.forEach((newIndex, oldIndex) => {
      temp[oldIndex] = JSON.parse(JSON.stringify(picklists[newIndex]));
      temp[oldIndex].index = oldIndex;

      temp[newIndex].retailerIds.forEach(r => {
        retailers[r].index = oldIndex;
      });
    });
    return { retailers, picklists: temp };
  },
  REARRANGE_ROUTE({ retailers, picklists }, { _ }) {
    return { retailers, picklists };
  },
};
