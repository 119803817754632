import { Typography } from '@material-ui/core';
import React from 'react';
import { useGState } from 'state/store';
import UserList from './UserList';

export default function PermManagement() {
  const user = useGState(state => state.user);

  if (!user.permissions.includes('Admin')) {
    return <Typography>Access Denied! Please Contact Abhishek(abhishek@stackbox.xyz).</Typography>;
  }

  return <UserList />;
}
