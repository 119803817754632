import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { askForPermissionToReceiveNotifications } from 'push-notification';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useQuery as useQueryRQ } from 'react-query';
import { useGetPlans, useGetPrincipals } from 'services/planService';
import { ga, useGDispatch, useGState } from 'state/store';
import { planStates as ps } from 'utils/constants';
import { useFetch, useInterval, useSnackbar } from 'utils/customHooks';
import { initialSetup as apiInitialSetup } from 'utils/fetch';

const GET_USER = gql`
  query {
    me {
      id
      email
      name
      permissions
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($email: String!, $name: String!) {
    register(name: $name, email: $email) {
      id
      email
      name
      permissions
    }
  }
`;

export default function InitialSetup() {
  const user = useGState(state => state.user);
  const gDispatch = useGDispatch();
  const { date } = useGState(state => state.date);
  const { ci, bi, disabled } = useGState(state => state.filters);
  const [notif] = useSnackbar();
  const filter = useMemo(() => ({ ci, bi }), [ci, bi]);

  // const [clients, , , refetchClients] = useFetch(useGetClients());
  // const [principals, , , refetchPrincipals] = useFetch(useGetPrincipals());
  useQueryRQ('principal_Nodes', useGetPrincipals, {
    onSuccess: principals => gDispatch([ga.PRINCIPALS, principals]),
  });

  const [pendingPlans, , , refetchPendingPlans] = useFetch(
    useGetPlans(
      useMemo(
        () => ({
          ...(disabled ? {} : filter),
          date,
          status: [ps.CREATED, ps.CANCELLED, ps.FAILED, ps.QUEUED, ps.PROCESSING, ps.COMPLETED],
        }),
        [date, filter, disabled]
      )
    )
  );

  const [donePlans, , , refetchDonePlans] = useFetch(
    useGetPlans(
      useMemo(
        () => ({
          ...(disabled ? {} : filter),
          date,
          status: [ps.FROZEN],
        }),
        [date, filter, disabled]
      )
    )
  );

  //background plans fetching for counts
  useInterval(() => {
    refetchPendingPlans();
    refetchDonePlans();
  }, 60 * 1000);

  // Fetching Clients and updating store and cache(local storage) in case needed
  // useEffect(() => {
  //   if (!principals) {
  //     refetchPrincipals();
  //     return;
  //   }
  //   gDispatch([ga.PRINCIPALS, principals]);

  //   // const clients = principals.flatMap(p => p.clients);
  //   // gDispatch({ type: ga.CLIENTS, clients });
  //   // setClientCache(clients);
  // }, [principals, gDispatch, refetchPrincipals]);

  //setting logout function
  useEffect(() => {
    const onAuthFailure = () => {
      gDispatch([ga.AUTH_FAILURE]);
    };
    apiInitialSetup(onAuthFailure, notif);
  }, [gDispatch, notif]);

  //setting up plan counts for activity bar
  useEffect(() => {
    const planCounts = {
      pending: pendingPlans ? pendingPlans.plans.length : 0,
      done: donePlans ? donePlans.plans.length : 0,
    };
    gDispatch({ type: ga.COUNTS, planCounts });
  }, [pendingPlans, donePlans, gDispatch]);

  return user ? <ManageUser /> : null;
}

let mutationRunFlag = 0;
const ManageUser = memo(function ManageUser() {
  const [pUser, setPUser] = useState();
  const globalUser = useGState(state => state.user);
  const gDispatch = useGDispatch();

  const { data } = useQuery(GET_USER);

  const fetchedPUser = data?.user;
  const [createUser] = useMutation(CREATE_USER, {
    variables: { email: globalUser.email, name: globalUser.name },
    update: (_, { data }) => {
      setPUser(data.createUser);
    },
  });
  useEffect(() => {
    if (fetchedPUser) {
      setPUser(fetchedPUser);
    } else if (fetchedPUser === null && !pUser && mutationRunFlag === 0) {
      createUser();
      mutationRunFlag = 1;
    }
  }, [fetchedPUser, createUser, pUser]);

  useEffect(() => {
    if (pUser) gDispatch([ga.PUSER, pUser]);
  }, [pUser, gDispatch]);

  return globalUser && globalUser.id && process.env.NODE_ENV === 'production' ? (
    <RegisterFCMToken userId={globalUser.id} />
  ) : null;
});

const UPDATE_USER_TOKEN = gql`
  mutation updateUserFCMToken($id: ID!, $fcmToken: String!) {
    updateUserFCMToken(id: $id, fcmToken: $fcmToken) {
      id
      fcmToken
    }
  }
`;

const RegisterFCMToken = memo(function RegisterFCMToken({ userId }) {
  const [updateUserToken] = useMutation(UPDATE_USER_TOKEN, {
    update: (_, { data }) => {
      console.log('TOKEN registered');
      console.log({ data });
    },
  });

  useEffect(() => {
    const registerToken = fcmToken => {
      console.log('TOKEN provided');
      updateUserToken({ variables: { id: userId, fcmToken } });
    };
    askForPermissionToReceiveNotifications(registerToken);
    // eslint-disable-next-line
  }, [userId]);
  return null;
});

RegisterFCMToken.displayName = 'RegisterFCMToken';
