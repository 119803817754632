import React, { useContext, useState, createContext, useMemo } from 'react';
import { getWorkingDate } from 'utils/helperFunctions';
let GlobalContext = createContext();

function GlobalContextProvider(props) {
  let [pending, setStatePending] = useState({
    ci: [],
    ce: [],
    bi: [],
    be: [],
  });
  let [done, setStateDone] = useState({
    ci: [],
    ce: [],
    bi: [],
    be: [],
  });
  let [clientList, setStateClientList] = useState([]);
  let [sideBarTitle, setSideBarTitle] = useState('');
  let [loading, setLoading] = useState(false);
  let [date, setDate] = useState(getWorkingDate());

  let value = useMemo(
    () => ({
      state: {
        pending,
        done,
        clientList,
        sideBarTitle,
        loading,
        date,
      },
      setState: {
        pending: setStatePending,
        done: setStateDone,
        clientList: setStateClientList,
        sideBarTitle: setSideBarTitle,
        loading: setLoading,
        date: setDate,
      },
    }),
    [sideBarTitle, pending, done, clientList, loading, date]
  );

  return <GlobalContext.Provider value={value}>{props.children}</GlobalContext.Provider>;
}

const useGlobalStatev1 = property => {
  const { state } = useContext(GlobalContext);
  return property ? state[property] : state;
};

const useGlobalSetStatev1 = property => {
  const { setState } = useContext(GlobalContext);
  return property ? setState[property] : setState;
};

export { GlobalContext, GlobalContextProvider, useGlobalStatev1, useGlobalSetStatev1 };
