import { Box, Button, Typography } from '@material-ui/core';
import Select from 'components/ReactSelect';
import Sample from 'components/Sample';
import Uploader, { DefaultUploaderRender } from 'components/Uploader';
import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useState } from 'react';
import { useGState } from 'state/store';
import { useAPI, useSnackbar } from 'utils/customHooks';
import { byId, principalSelect } from 'utils/helperFunctions';
import ClientOnboarding from './ClientOnboarding';

// const permittedUsers = [
//   'abhishek',
//   'saby',
//   'vikas',
//   'chaitanya',
//   'shanmukha',
//   'parikshit',
//   'sourabh',
//   'rohit',
//   'rohit.kumar',
//   'prakhar',
//   'sangeet',
//   'praveen',
//   'abhijit',
//   'tantril',
//   'abhay',
//   'suraj',
//   'varadraj',
//   'akshay',
//   'jyothi',
//   'nahush',
//   'chaitanya.csv',
//   'ashwin',
//   'sabharish',
//   'vinaykumar.karne',
// ];

export default function Operations() {
  const { user, principals } = useGState(s => ({ user: s.user, principals: s.principals }));
  const [principal, setPrincipal] = useState(null);
  const [notif] = useSnackbar();
  const [fetch] = useAPI();
  const [adminAccess, setAdminAccess] = useState(false);

  const downloadSKUMaster = useCallback(() => {
    fetch(
      {
        method: 'get',
        url: `/admin/delivery/sku?principalId=${principal}`,
        responseType: 'blob',
      },
      resp => {
        saveAs(resp, `SKU_Master_${byId(principals, principal).name}.xlsx`);
      }
    );
  }, [fetch, principal, principals]);

  useEffect(() => {
    if (!user) {
      return <Typography>Please Relogin</Typography>;
    }
    if (
      // permittedUsers.map(id => `${id}@stackbox.xyz`).includes(user.email) ||
      // permittedUsers.map(id => `${id}@stackbox.co.in`).includes(user.email)
      user.permissions.includes('Operations')
    ) {
      setAdminAccess(true);
    }
  }, [user]);

  if (!principals) return null;

  const options = principalSelect(principals);

  return (
    <>
      <Box width={1} p={2} borderColor="grey.400" borderRadius={16} border={1} bgcolor="white">
        <Box pb={2}>
          <Typography variant="h5" align="left" color="textSecondary">
            Upload SKU Master
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Box width={300}>
            <Select
              options={options}
              onChange={resp => setPrincipal(resp.value)}
              placeholder="Select Principal..."
              menuPortalTarget={document.getElementById('portal-target')}
            ></Select>
          </Box>
          <Box width="auto" px={3}>
            <Uploader
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              url={`/admin/delivery/sku?principalId=${principal}`}
              method="put"
              disabled={!principal}
              onSuccess={() => notif('Successfully updated', { variant: 'success' })}
              onError={() => notif('Oh Snap! Upload Failed', { variant: 'error' })}
              render={(progress, apiInProgress) => (
                <DefaultUploaderRender
                  tooltip="Upload SKU Master Plan"
                  disabled={!principal}
                  progress={progress}
                  apiInProgress={apiInProgress}
                />
              )}
            />
          </Box>
          <Box width="auto" pr={3}>
            <Sample url="/admin/delivery/sku/template" name="Sample_SKU_upload.xlsx"></Sample>
          </Box>
          <Box width="auto">
            <Button disabled={!principal} onClick={downloadSKUMaster}>
              Download Current SKU Master
            </Button>
          </Box>
        </Box>
      </Box>
      {adminAccess ? (
        <ClientOnboarding />
      ) : (
        <Box mt={3}>
          <Typography>
            Further Access Denied! Please Contact Abhishek(abhishek@stackbox.xyz) for clients/branch
            setting changes.
          </Typography>
        </Box>
      )}
    </>
  );
}
