import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import Link from 'components/Link';
import React, { memo } from 'react';
import { planStates as ps } from 'utils/constants';

const useStyles = makeStyles(theme => ({
  paper: {
    paddingLeft: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
  },
}));

function PlanCardLite({ id, profileName, branchName, clientName, status, frozenBy = ' -- ' }) {
  const classes = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      <Grid container justify="space-around" alignItems="center" wrap="nowrap">
        <Grid item xs>
          <Link to={`/plan/${id}`}>
            <Typography color="primary" variant="body2" noWrap>
              {id}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to={`/plan/${id}`}>
            <Typography color="textPrimary" noWrap>
              {branchName}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to={`/plan/${id}`}>
            <Typography color="textSecondary" variant="body2" noWrap>
              {clientName}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Link to={`/plan/${id}`}>
            <Typography color="primary" variant="body2" noWrap>
              {profileName}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs>
          <Typography color="textSecondary" variant="body2">
            {status === ps.FROZEN ? 'FROZEN' : status}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography color="textSecondary" variant="body2">
            {frozenBy}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(PlanCardLite);
