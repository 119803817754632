import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import VehicleIcon from '@material-ui/icons/LocalShippingRounded';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { actionTypes } from 'planning/planningReducer';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { planEdits as pe } from 'utils/constants';
import {
  byId,
  format1,
  formatCurrency,
  formatTimeMilitary,
  formatWeight,
  isVal,
} from 'utils/helperFunctions';
import { getColor, getIcon, getRectangle, getStar } from 'utils/mapsUtils';
import SimpleDialog from './SimpleDialog';
// import { useSpring, animated } from 'react-spring';
// import { useInterval } from 'utils/customHooks';

const useStylesInfo = makeStyles(() => ({
  tableCell: {
    paddingRight: '4px !important',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 4,
  },
  unassignButton: {
    marginTop: 4,
    borderColor: red[500],
    color: red[500],
  },
  assignButton: {
    marginTop: 4,
    borderColor: green[500],
    color: green[500],
  },
}));

const isHighlight = (retailer, highlightFilters) => {
  if (highlightFilters['Other Attribute'].some(attribute => retailer[attribute])) return true;
  if (highlightFilters['Channels'].includes(retailer.channel)) return true;

  if (
    retailer.salesmanIds.some(dse => {
      return highlightFilters['DSEs'].includes(dse);
    })
  )
    return true;

  return false;
};

function RetailerMarker({
  retailer,
  salesmen,
  retailerInfoWindow,
  salesView,
  visible,
  dispatch,
  onDragEnd,
  vehicleTypes,
  highlightFilters,
  picklist,
}) {
  const [size, setSize] = useState(4.2);
  // const highlightAnimation = useSpring({ opacity: 1, from: { opacity: 0 } });
  // const [highlightVisble, setHighlightVisible] = useState(true);
  useEffect(() => {
    if (!salesView) {
      setSize(4);
    } else {
      let s = retailer.sales;
      setSize(
        s < 7000 ? 3 : s < 25000 ? 4.5 : s < 100000 ? 6 : s < Number.MAX_SAFE_INTEGER ? 7.5 : 4
      );
    }
  }, [salesView, retailer.sales]);

  const center = { lat: retailer.latitude, lng: retailer.longitude };

  const google = useMemo(() => window.google, []);

  const onRightClick = useCallback(() => {
    dispatch({ type: actionTypes.RETAILER_RIGHTCLICKED, retailer });
  }, [dispatch, retailer]);

  const valid = useMemo(
    () =>
      isVal(retailer.index) && retailer.deliveryWindow.length
        ? retailer.deliveryWindow.reduce((acc, { start, end }) => {
            if (acc) return acc;
            return (
              retailer.deliveryStartTime >= start * 60 && retailer.deliveryStartTime <= end * 60
            );
          }, false)
        : true,
    [retailer.index, retailer.deliveryWindow, retailer.deliveryStartTime]
  );

  const incompatibleVehicle = useMemo(
    () =>
      retailer.incompatibleVehicleTypes?.includes(
        byId(vehicleTypes, picklist?.vehicleTypeId)?.name
      ),
    [retailer.incompatibleVehicleTypes, picklist, vehicleTypes]
  );

  const icon = useMemo(() => {
    const { fillColor, strokeColor } = getColor(retailer.index);
    // is assigned to some picklist
    if (isVal(retailer?.index)) {
      // is having time window
      if (retailer.deliveryWindow.length) {
        return valid
          ? getRectangle({
              height: size * 2.2,
              strokeWidth: 1,
              fillColor,
            })
          : {
              path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
              fillColor: red[500],
              fillOpacity: 1,
              scale: size,
              strokeColor,
              strokeWeight: 1,
            };
      } else {
        if (isHighlight(retailer, highlightFilters)) {
          return getStar({
            scale: size * 1.2,
            innerRadius: size * 0.6,
            strokeWidth: 0.3,
            spikes: 5,
            fillColor,
          });
        }
        if (incompatibleVehicle) {
          return {
            path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
            fillColor: red[500],
            fillOpacity: 1,
            scale: size,
            strokeColor,
            strokeWeight: 1,
          };
        }
        return getIcon({
          fillColor,
          scale: size,
          strokeWeight: 0.3,
        });
      }
    }
    return {
      path: google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
      fillColor,
      fillOpacity: 1,
      scale: size,
      strokeColor,
      strokeWeight: 1,
    };
  }, [
    google.maps.SymbolPath.FORWARD_OPEN_ARROW,
    valid,
    google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
    retailer,
    size,
    highlightFilters,
    incompatibleVehicle,
  ]);

  const toggleInfoOpen = useCallback(() => {
    dispatch({
      type: actionTypes.TOGGLE_RETAILER_INFO_WINDOW,
      retailer,
    });
  }, [dispatch, retailer]);

  // useInterval(() => setHighlightVisible(s => (valid ? s : !s)), 1000);

  return (
    <>
      {/* <animated.div style={highlightAnimation}> */}
      <Marker
        position={center}
        draggable
        visible={visible}
        // visible={visible && highlightVisble}
        onDragEnd={e => onDragEnd(retailer, e)}
        onRightClick={onRightClick}
        onMouseOver={() => {
          setSize(s => s + 2);
          // dispatch({
          //   type: actionTypes.OPEN_RETAILER_INFO_WINDOW,
          //   hover: true,
          //   retailer: retailer,
          // });
        }}
        clickable
        onClick={toggleInfoOpen}
        onMouseOut={() => {
          setSize(s => s - 2);
          // dispatch({
          //   type: actionTypes.CLOSE_RETAILER_INFO_WINDOW,
          //   hover: true,
          // });
        }}
        icon={icon}
      />
      {retailerInfoWindow && (
        <InfoWindow onCloseClick={toggleInfoOpen} position={center}>
          <RetailerInfoPopper
            retailer={retailer}
            retailerInfoWindow={retailerInfoWindow}
            dispatch={dispatch}
            vehicleTypes={vehicleTypes}
            salesmen={salesmen}
          />
        </InfoWindow>
      )}
      {/* </animated.div> */}
    </>
  );
}

function RetailerInfoPopper({
  retailer,
  retailerInfoWindow: { picklist: pl, hover },
  vehicleTypes,
  salesmen,
  dispatch,
}) {
  const classes = useStylesInfo();
  const [openVehicleDialog, setOpenVehicleDialog] = React.useState(false);
  // const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const [isNextPage, setIsNextPage] = useState(false);

  const { volume, weight, sales } = retailer;
  const smallText = (text, align = 'inherit', color = 'textPrimary') => (
    <Typography variant="caption" color={color} align={align}>
      {text}
    </Typography>
  );
  const tc = (text, align = 'inherit', color) => (
    <TableCell className={classes.tableCell} align={align}>
      {smallText(text, align, color)}
    </TableCell>
  );

  const onUnAssign = useCallback(() => {
    dispatch({
      type: pe.UNASSIGN_RETAILER,
      details: {
        retailerId: retailer.id,
        picklistIndex: retailer.index,
      },
    });
    dispatch({
      type: actionTypes.TOGGLE_RETAILER_INFO_WINDOW,
      retailer,
    });
  }, [dispatch, retailer]);

  const addPicklist = useCallback(
    vehicleTypeId => {
      setOpenVehicleDialog(false);
      if (!vehicleTypeId) return;
      dispatch({
        type: actionTypes.ADD_PICKLIST,
        retailer,
        vehicleTypeId,
      });
      dispatch({
        type: actionTypes.CLOSE_RETAILER_INFO_WINDOW,
      });
    },
    [dispatch, retailer]
  );

  const rowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
    gap: '16px',
  };

  return (
    <>
      <SimpleDialog
        options={vehicleTypes.map(({ name: label, id }) => ({ id, label }))}
        title="Select Vehicle Type"
        open={openVehicleDialog}
        onClose={addPicklist}
        icon={<VehicleIcon />}
      />
      {isNextPage ? (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              width: '32px',
              height: '32px',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <IconButton onClick={() => setIsNextPage(false)} edge="start" size="small">
              <ChevronLeftRounded />
            </IconButton>
          </div>
          <div>
            <Table size="small">
              <TableBody>
                <TableRow>
                  {tc('Customer Name', 'left', 'textSecondary')}
                  {tc(retailer?.name, 'right', 'primary')}
                </TableRow>
              </TableBody>
            </Table>
            {/* <Divider variant="middle" /> */}
            <Table size="small">
              <TableBody>
                <TableRow>
                  {tc('Address', 'left', 'textSecondary')}
                  {tc(
                    <div style={{ maxWidth: '300px' }}>
                      <div>{retailer.address?.line1}</div>
                      <div>{retailer.address?.line2}</div>
                      <div>{`${retailer.address?.city || ''} ${retailer.address?.state ||
                        ''} ${retailer.address?.country || ''}`}</div>
                    </div>,
                    'right',
                    'primary'
                  )}
                </TableRow>
              </TableBody>
            </Table>
            <Table size="small">
              <TableBody>
                <TableRow>
                  {tc('Pincode', 'left', 'textSecondary')}
                  {tc(retailer.address?.pincode, 'right', 'primary')}
                </TableRow>
              </TableBody>
            </Table>
            <Table size="small">
              <TableBody>
                <TableRow>
                  {tc('Customer Channel', 'left', 'textSecondary')}
                  {tc(retailer?.channel, 'right', 'primary')}
                </TableRow>
              </TableBody>
            </Table>
            <Table size="small">
              <TableBody>
                <TableRow>
                  {tc('Location Varified', 'left', 'textSecondary')}
                  {tc(retailer?.locationVerified ? 'True' : 'False', 'right', 'primary')}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div>
            <div style={rowStyle}>
              {smallText('ETA', 'left', 'textSecondary')}
              {smallText(formatTimeMilitary(retailer.eta), 'right', 'primary')}
            </div>
            {retailer.deliveryWindow && Boolean(retailer.deliveryWindow.length) && (
              <div style={rowStyle}>
                {smallText('Time Window', 'left', 'textSecondary')}
                {smallText(
                  `${formatTimeMilitary(retailer.deliveryWindow[0].start * 60) || '0'} - ${
                    retailer.deliveryWindow[0].end
                      ? formatTimeMilitary(retailer.deliveryWindow[0].end * 60)
                      : '∞'
                  }`,
                  'right',
                  'primary'
                )}
              </div>
            )}
            <div style={rowStyle}>
              {smallText('Incompatible VT', 'left', 'textSecondary')}
              {smallText(retailer.incompatibleVehicleTypes.join(', ') || '--', 'right', 'primary')}
            </div>
            <div style={rowStyle}>
              {smallText('Customer Sequence', 'left', 'textSecondary')}
              {smallText(pl?.retailerIds.indexOf(retailer.id) + 1 || '--', 'right', 'primary')}
            </div>
            <div style={rowStyle}>
              {smallText('Salesman Name', 'left', 'textSecondary')}
              {smallText(
                retailer.salesmanIds.map(s => byId(salesmen, s)?.name || '--'),
                'right',
                'primary'
              )}
            </div>
            <div style={rowStyle}>
              {smallText('Customer Code ', 'left', 'textSecondary')}
              {smallText(retailer.code, 'right', 'primary')}
            </div>
            <div style={rowStyle}>
              {smallText(' Trip Id', 'left', 'textSecondary')}
              {smallText(pl?.name, 'right', 'primary')}
            </div>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {tc('')}
                  {tc('CUSTOMER', 'right', 'primary')}
                  {tc('TRIP', 'right', 'primary')}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {tc('Vol', undefined, 'textSecondary')}
                  {tc(
                    `${
                      pl
                        ? format1((100 * volume) / byId(vehicleTypes, pl.vehicleTypeId).maxVolume)
                        : 0
                    }%`,
                    'right'
                  )}
                  {pl &&
                    tc(
                      `${format1(
                        (100 * pl.volume) / byId(vehicleTypes, pl.vehicleTypeId).maxVolume
                      )}%`,
                      'right'
                    )}
                </TableRow>
                <TableRow>
                  {tc('Weight', undefined, 'textSecondary')}
                  {tc(formatWeight(weight), 'right')}
                  {pl && tc(formatWeight(pl.weight), 'right')}
                </TableRow>
                <TableRow>
                  {tc('Sales', undefined, 'textSecondary')}
                  {tc(formatCurrency(sales), 'right')}
                  {pl && tc(formatCurrency(pl.sales), 'right')}
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div
            style={{
              width: '32px',
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={() => setIsNextPage(true)} edge="start" size="small">
              <ChevronRightRounded />
            </IconButton>
          </div>
        </div>
      )}

      {!hover &&
        (pl ? (
          <Button
            fullWidth
            variant="outlined"
            size="small"
            onClick={onUnAssign}
            className={classes.unassignButton}
          >
            Unassign
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={() => setOpenVehicleDialog(true)}
            className={classes.assignButton}
            variant="outlined"
            size="small"
          >
            Add Picklist
          </Button>
        ))}
    </>
  );
}

export default memo(RetailerMarker);
